import React from 'react';

const RiskCalculation = ({hideRiskCalculationInfo}) => {

  return (
    <div className="risk-classification-container hidePopup" onClick={hideRiskCalculationInfo} id="RiskCalculationPopup">
      <div className="risk-classification-content">
        <h5 className="mt-4">Calculation of Risk Classification</h5>
        <table id="RiskClassification" className="col-12">
          <thead>
            <tr>
              <th>Risk Classification</th>
              <th>Risk Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="risk-classification-wrapper">
                  <p className="risk-classification-low">Low</p>
                </div>
              </td>
              <td>Entities with Risk Score below 7</td>
            </tr>
            <tr>
              <td>
                <div className="risk-classification-wrapper">
                  <p className="risk-classification-medium">Medium</p>
                </div>
              </td>
              <td>Entities with Risk Score 7 to 12</td>
            </tr>
            <tr>
              <td>
                <div className="risk-classification-wrapper">
                  <p className="risk-classification-high">High</p>
                </div>
              </td>
              <td>Entities with Risk Score above 12</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RiskCalculation;