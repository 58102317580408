import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import RegulatoryDashboardSectionTitle from './RegulatoryDashboardSectionTitle';
import { Bar as BarChart2 } from "react-chartjs-2";
import { Chart as ChartJS, 
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend } from "chart.js";
import { DataTable } from 'primereact/datatable';
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { ProductServices } from './ProductServices';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { scrollIntoViewWithOffset } from '../../utils/scrollToViewWithOffset';
import Checkbox from '../../common/Checkbox';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipRecharts,
  Legend as LegendRecharts,
  BarChart,
  LabelList,
  ResponsiveContainer
} from 'recharts';
import RiskCalculation from './RiskCalculation';

const waterfallData = [
  {
    "feature": "Annual Client",
    "importance": [
      0,
      11
    ],
    "barLabel": 11
  },
  {
    "feature": "Infraction Type",
    "importance": [
      11,
      30
    ],
    "barLabel": 16
  },
  {
    "feature": "Infraction Timeline",
    "importance": [
      30,
      45
    ],
    "barLabel": 17
  },
  {
    "feature": "Public Complaints",
    "importance": [
      45,
      60
    ]
    ,
    "barLabel": 17
  },
  {
    "feature": "Sentiment Analysis",
    "importance": [
      60,
      90
    ],
    "barLabel": 26
  },
  {
    "feature": "Inspection Results",
    "importance": [
      90,
      100
    ],
    "barLabel": 13
  },
  {
    "feature": "Total",
    "importance": [
      0,
      100
    ],
    "barLabel": 100
  },
]

ChartJS.register(CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend);

const RegulatoryDashboard = ({
  setShowAddEntityForm,
  setShowAddReviewToEntityForm,
  setEntityName,
  formSubmittedSuccessfully,
  setFormSubmittedSuccessfully,
  addReviewToEntityFormSubmittedSuccessfully,
  setAddReviewToEntityFormSubmittedSuccessfully,
}) => {
  const [totalEntities, setTotalEntities] = useState();
  const [highRiskEntities, setHighRiskEntities] = useState();
  const [mediumRiskEntities, setMediumRiskEntities] = useState();
  const [lowRiskEntities, setLowRiskEntities] = useState();
  const [allEntities, setAllEntities] = useState([]);
  const [filteredEntities, setFilteredEntities] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [topHighRiskEntities, setTopHighRiskEntities] = useState([]);
  const [classificationSelectValue, setClassificationSelectValue] = useState();
  const [infractionSelectValue, setInfractionSelectValue] = useState();
  const [sentimentSelectValue, setSentimentSelectValue] = useState();
  const [inspectionSelectValue, setInspectionSelectValue] = useState();
  const [complaintSelectValue, setComplaintSelectValue] = useState();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    score: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [complianceRate, setComplianceRate] = useState();
  const [repeatOffenderCount, setRepeatOffenderCount] = useState();
  const [inspectionFailureRate, setInspectionFailureRate] = useState();
  const [stackedBarData, setStackedBarData] = useState([
    {
      name: 'Flagged',
      Fail: 1000,
      None: 200,
      Pass: 800,
    },
    {
      name: 'None',
      Fail: 500,
      None: 300,
      Pass: 3200,
    },
  ]);

  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

  // const [riskClassificationHigh, setRiskClassificationHigh] = useState(false);
  // const [riskClassificationMedium, setRiskClassificationMedium] = useState(false);
  // const [riskClassificationLow, setRiskClassificationLow] = useState(false);
  // const [infractionSeverityMajor, setInfractionSeverityMajor] = useState(false);
  // const [infractionSeverityMinor, setInfractionSeverityMinor] = useState(false);
  // const [infractionSeverityNone, setInfractionSeverityNone] = useState(false);
  // const [complaintSeverityMajor, setComplaintSeverityMajor] = useState(false);
  // const [complaintSeverityMinor, setComplaintSeverityMinor] = useState(false);
  // const [complaintSeverityNone, setComplaintSeverityNone] = useState(false);
  // const [sentimentAnalysisNone, setSentimentAnalysisNone] = useState(false);
  // const [sentimentAnalysisFlagged, setSentimentAnalysisFlagged] = useState(false);
  // const [inspectionResultFail, setInspectionResultFail] = useState(false);
  // const [inspectionResultPass, setInspectionResultPass] = useState(false);
  // const [inspectionResultNone, setInspectionResultNone] = useState(false);
  const [applyFilters, setApplyFilters] = useState({
    riskClassification: new Set(),
    infractionSeverity: new Set(),
    complaintSeverity: new Set(),
    sentimentAnalysis: new Set(),
    inspectionResult: new Set(),
  })

  const attributes = {
    riskClassification: ["High", "Medium", "Low"],
    infractionSeverity: ["major", "minor", "none"],
    complaintSeverity: ["major", "minor", "none"],
    sentimentAnalysis: ["flagged", "none"],
    inspectionResult: ["fail", "pass", "none"]
  }

  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const getEntitiesAPI = process.env.REACT_APP_GET_ENTITIES;
  const getEntityAPI = process.env.REACT_APP_GET_ENTITY;
  const getFeatureImportance = process.env.REACT_APP_GET_FEATURE_IMPORTANCE;

  const [classifications] = useState(['High', 'Medium', 'Low']);
  const classificationOptions = [
    { value: "High", label: "High"},
    { value: "Medium", label: "Medium"},
    { value: "Low", label: "Low"},
  ];
  const infractionOptions = [
    { value: "none", label: "None"},
    { value: "minor", label: "Minor"},
    { value: "major", label: "Major"},
  ]
  const quarterComplaintSeverityOptions = [
    { value: "none", label: "None"},
    { value: "minor", label: "Minor"},
    { value: "major", label: "Major"},
  ]
  const quarterSentimentAnalysisOptions = [
    { value: "none", label: "None"},
    { value: "flagged", label: "Flagged"},
  ]
  const inspectionResultOptions = [
    { value: "none", label: "None"},
    { value: "pass", label: "Pass"},
    { value: "fail", label: "Fail"},
  ]

  const handleChangeClassification = (selected) => {
    if(selected) {
      setClassificationSelectValue(selected);
      setInfractionSelectValue(null);
      setSentimentSelectValue(null);
      setInspectionSelectValue(null);
      setComplaintSelectValue(null);
      let temp = allEntities;
      let filtered;
      if(selected.value === "High") {
        filtered = temp.filter((entity) => entity.risk.score > 12);
      } else if(selected.value === "Medium") {
        filtered = temp.filter((entity) => entity.risk.score >= 7 && entity.risk.score <= 12);
      } else if(selected.value === "Low") {
        filtered = temp.filter((entity) => entity.risk.score < 7 && entity.risk.score !== null);
      }
      setFilterApplied(true);
      setFilteredEntities(filtered);
    } else {
      setFilterApplied(false);
      setFilteredEntities([]);
      setClassificationSelectValue();
    }
  }

  const handleChangeInfraction = (selected) => {
    if(selected) {
      setInfractionSelectValue(selected);
      setClassificationSelectValue(null);
      setSentimentSelectValue(null);
      setInspectionSelectValue(null);
      setComplaintSelectValue(null);
      let temp = allEntities;
      let filtered = temp.filter((entity) => entity.risk.recent_review?.infraction_severity === selected.value);
      setFilterApplied(true);
      setFilteredEntities(filtered);
    } else {
      setFilterApplied(false);
      setFilteredEntities([]);
      setInfractionSelectValue();
    }
  }
  
  const handleChangeComplaint = (selected) => {
    if(selected) {
      setComplaintSelectValue(selected);
      setClassificationSelectValue(null);
      setSentimentSelectValue(null);
      setInspectionSelectValue(null);
      setInfractionSelectValue(null);
      let temp = allEntities;
      let filtered = temp.filter((entity) => entity.risk.recent_review?.quarter_complaint_severity === selected.value);
      setFilterApplied(true);
      setFilteredEntities(filtered);
    } else {
      setFilterApplied(false);
      setFilteredEntities([]);
      setComplaintSelectValue();
    }
  }
  
  const handleChangeSentiment = (selected) => {
    if(selected) {
      setSentimentSelectValue(selected);
      setClassificationSelectValue(null);
      setInspectionSelectValue(null);
      setInfractionSelectValue(null);
      setComplaintSelectValue(null);
      let temp = allEntities;
      let filtered = temp.filter((entity) => entity.risk.recent_review?.quarter_sentiment_analysis === selected.value);
      setFilterApplied(true);
      setFilteredEntities(filtered);
    } else {
      setFilterApplied(false);
      setFilteredEntities([]);
      setSentimentSelectValue();
    }
  }

  const handleChangeInspection = (selected) => {
    if(selected) {
      setInspectionSelectValue(selected);
      setClassificationSelectValue(null);
      setInfractionSelectValue(null);
      setComplaintSelectValue(null);
      setSentimentSelectValue(null);
      let temp = allEntities;
      let filtered = temp.filter((entity) => entity.risk.recent_review?.inspection_result === selected.value);
      setFilterApplied(true);
      setFilteredEntities(filtered);
    } else {
      setFilterApplied(false);
      setFilteredEntities([]);
      setInspectionSelectValue();
    }
  }
  
  // const handleChangeRiskClassificationHigh = () => {
  //   setRiskClassificationHigh(!riskClassificationHigh);
  // }

  // const handleChangeRiskClassificationMedium = () => {
  //   setRiskClassificationMedium(!riskClassificationMedium);
  // }

  // const handleChangeRiskClassificationLow = () => {
  //   setRiskClassificationLow(!riskClassificationLow);
  // }

  // const handleChangeInfractionSeverityMajor = () => {
  //   setInfractionSeverityMajor(!infractionSeverityMajor);
  // }

  // const handleChangeInfractionSeverityMinor = () => {
  //   setInfractionSeverityMinor(!infractionSeverityMinor);
  // }

  // const handleChangeInfractionSeverityNone = () => {
  //   setInfractionSeverityNone(!infractionSeverityNone);
  // }

  // const handleChangeComplaintSeverityMajor = () => {
  //   setComplaintSeverityMajor(!complaintSeverityMajor);
  // }

  // const handleChangeComplaintSeverityMinor = () => {
  //   setComplaintSeverityMinor(!complaintSeverityMinor);
  // }

  // const handleChangeComplaintSeverityNone = () => {
  //   setComplaintSeverityNone(!complaintSeverityNone);
  // }

  // const handleChangeSentimentAnalysisFlagged = () => {
  //   setSentimentAnalysisFlagged(!sentimentAnalysisFlagged);
  // }
  
  // const handleChangeSentimentAnalysisNone = () => {
  //   setSentimentAnalysisNone(!sentimentAnalysisNone);
  // }

  // const handleChangeInspectionResultFail = () => {
  //   setInspectionResultFail(!inspectionResultFail);
  // }

  // const handleChangeInspectionResultPass = () => {
  //   setInspectionResultPass(!inspectionResultPass);
  // }

  // const handleChangeInspectionResultNone = () => {
  //   setInspectionResultNone(!inspectionResultNone);
  // }

  const handleCheckboxChange = (attribute, value) => {
    if(value !== "Total") {
      setApplyFilters(prevFilters => {
        const updatedCategory = new Set(prevFilters[attribute]);
        if (updatedCategory.has(value)) {
          updatedCategory.delete(value);
        } else {
          updatedCategory.add(value);
        }
        return {
          ...prevFilters,
          [attribute]: updatedCategory
        };
      });
    }
    
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false
          }
        }
      }
    };
    
    let data;
    if(value === "High") {
      document.getElementById("highRiskEntitiesDistribution").classList.add("distribution-entities-active");
      document.getElementById("mediumRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("lowRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      data = {
        labels: [''],
        datasets: [
          {
            label: 'High',
            backgroundColor: "#FF0003",
            borderColor: "#FF0003",
            data: [parseInt(highRiskEntities)]
          },
          {
            label: 'Medium',
            backgroundColor: "#FFDDC5",
            borderColor: "#FFDDC5",
            data: [parseInt(mediumRiskEntities)]
          },
          {
            label: 'Low',
            backgroundColor: "#C5E9CF",
            borderColor: "#C5E9CF",
            data: [parseInt(lowRiskEntities)]
          }
        ]
      };
    } else if(value === "Medium") {
      document.getElementById("highRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("mediumRiskEntitiesDistribution").classList.add("distribution-entities-active");
      document.getElementById("lowRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      data = {
        labels: [''],
        datasets: [
          {
            label: 'High',
            backgroundColor: "#FFB3B3",
            borderColor: "#FFB3B3",
            data: [parseInt(highRiskEntities)]
          },
          {
            label: 'Medium',
            backgroundColor: "#FF8F3C",
            borderColor: "#FF8F3C",
            data: [parseInt(mediumRiskEntities)]
          },
          {
            label: 'Low',
            backgroundColor: "#C5E9CF",
            borderColor: "#C5E9CF",
            data: [parseInt(lowRiskEntities)]
          }
        ]
      };
    } else if(value === "Low") {
      document.getElementById("highRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("mediumRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("lowRiskEntitiesDistribution").classList.add("distribution-entities-active");
      data = {
        labels: [''],
        datasets: [
          {
            label: 'High',
            backgroundColor: "#FFB3B3",
            borderColor: "#FFB3B3",
            data: [parseInt(highRiskEntities)]
          },
          {
            label: 'Medium',
            backgroundColor: "#FFDDC5",
            borderColor: "#FFDDC5",
            data: [parseInt(mediumRiskEntities)]
          },
          {
            label: 'Low',
            backgroundColor: "#3EB75E",
            borderColor: "#3EB75E",
            data: [parseInt(lowRiskEntities)]
          }
        ]
      };
    } else {      
      document.getElementById("highRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("mediumRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("lowRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      data = {
        labels: [''],
        datasets: [
          {
            label: 'High',
            backgroundColor: "#FF0003",
            borderColor: "#FF0003",
            data: [parseInt(highRiskEntities)]
          },
          {
            label: 'Medium',
            backgroundColor: "#FF8F3C",
            borderColor: "#FF8F3C",
            data: [parseInt(mediumRiskEntities)]
          },
          {
            label: 'Low',
            backgroundColor: "#3EB75E",
            borderColor: "#3EB75E",
            data: [parseInt(lowRiskEntities)]
          }
        ]
      };
    }
    setChartData(data);
    setChartOptions(options);
  }

  const resetFilters = () => {
    setApplyFilters({
      riskClassification: new Set(),
      infractionSeverity: new Set(),
      complaintSeverity: new Set(),
      sentimentAnalysis: new Set(),
      inspectionResult: new Set(),
    });
  }

  const filterAppliedEntities = allEntities.filter(item => {
    const review = item.risk.recent_review;
    return (
      (applyFilters.riskClassification.size === 0 || applyFilters.riskClassification.has(item.risk.riskClassification)) &&
      (applyFilters.infractionSeverity.size === 0 || applyFilters.infractionSeverity.has(review?.infraction_severity)) &&
      (applyFilters.complaintSeverity.size === 0 || applyFilters.complaintSeverity.has(review?.quarter_complaint_severity)) &&
      (applyFilters.sentimentAnalysis.size === 0 || applyFilters.sentimentAnalysis.has(review?.quarter_sentiment_analysis)) &&
      (applyFilters.inspectionResult.size === 0 || applyFilters.inspectionResult.has(review?.inspection_result))
    );
  });

  const handleFilterEntityFormSubmit = (e) => {
    e.preventDefault();
    // let temp = allEntities;
    // let filtered;
    // console.log("here", filtered);
    // if(riskClassificationHigh) {
    //   filtered = temp.filter((entity) => entity.risk.score > 12);
    //   if(riskClassificationMedium) {
    //     filtered = temp.filter((entity) => entity.risk.score > 6);
    //     if(riskClassificationLow) {
    //       filtered = temp.filter((entity) => entity.risk.score >= 0);
    //       if(infractionSeverityMajor) {
    //         filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //           entity.risk.recent_review?.infraction_severity === "major");
    //         if(infractionSeverityMinor) {
    //           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //             entity.risk.recent_review?.infraction_severity === "major" ||
    //             entity.risk.recent_review?.infraction_severity === "minor");
    //           if(infractionSeverityNone) {
    //             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //               entity.risk.recent_review?.infraction_severity === "major" ||
    //               entity.risk.recent_review?.infraction_severity === "minor" ||
    //               entity.risk.recent_review?.infraction_severity === "none");
    //             if(inspectionResultFail) {
    //               filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                 entity.risk.recent_review?.infraction_severity === "major" ||
    //                 entity.risk.recent_review?.infraction_severity === "minor" ||
    //                 entity.risk.recent_review?.infraction_severity === "none" ||
    //                 entity.risk.recent_review?.inspection_result === "fail");
    //               if(inspectionResultPass) {
    //                 filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                   entity.risk.recent_review?.infraction_severity === "major" ||
    //                   entity.risk.recent_review?.infraction_severity === "minor" ||
    //                   entity.risk.recent_review?.infraction_severity === "none" ||
    //                   entity.risk.recent_review?.inspection_result === "fail" ||
    //                   entity.risk.recent_review?.inspection_result === "pass");
    //                 if(inspectionResultNone) {
    //                   filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                     entity.risk.recent_review?.infraction_severity === "major" ||
    //                     entity.risk.recent_review?.infraction_severity === "minor" ||
    //                     entity.risk.recent_review?.infraction_severity === "none" ||
    //                     entity.risk.recent_review?.inspection_result === "fail" ||
    //                     entity.risk.recent_review?.inspection_result === "pass" ||
    //                     entity.risk.recent_review?.inspection_result === "none");
    //                   if(complaintSeverityMajor) {
    //                     filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                       entity.risk.recent_review?.infraction_severity === "major" ||
    //                       entity.risk.recent_review?.infraction_severity === "minor" ||
    //                       entity.risk.recent_review?.infraction_severity === "none" ||
    //                       entity.risk.recent_review?.inspection_result === "fail" ||
    //                       entity.risk.recent_review?.inspection_result === "pass" ||
    //                       entity.risk.recent_review?.inspection_result === "none" ||
    //                       entity.risk.recent_review?.quarter_complaint_severity === "major");
    //                     if(complaintSeverityMinor) {
    //                       filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                         entity.risk.recent_review?.infraction_severity === "major" ||
    //                         entity.risk.recent_review?.infraction_severity === "minor" ||
    //                         entity.risk.recent_review?.infraction_severity === "none" ||
    //                         entity.risk.recent_review?.inspection_result === "fail" ||
    //                         entity.risk.recent_review?.inspection_result === "pass" ||
    //                         entity.risk.recent_review?.inspection_result === "none" ||
    //                         entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                         entity.risk.recent_review?.quarter_complaint_severity === "minor");
    //                       if(complaintSeverityNone) {
    //                         filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                           entity.risk.recent_review?.infraction_severity === "major" ||
    //                           entity.risk.recent_review?.infraction_severity === "minor" ||
    //                           entity.risk.recent_review?.infraction_severity === "none" ||
    //                           entity.risk.recent_review?.inspection_result === "fail" ||
    //                           entity.risk.recent_review?.inspection_result === "pass" ||
    //                           entity.risk.recent_review?.inspection_result === "none" ||
    //                           entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                           entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                           entity.risk.recent_review?.quarter_complaint_severity === "none");
    //                         if(sentimentAnalysisFlagged) {
    //                           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                             entity.risk.recent_review?.infraction_severity === "major" ||
    //                             entity.risk.recent_review?.infraction_severity === "minor" ||
    //                             entity.risk.recent_review?.infraction_severity === "none" ||
    //                             entity.risk.recent_review?.inspection_result === "fail" ||
    //                             entity.risk.recent_review?.inspection_result === "pass" ||
    //                             entity.risk.recent_review?.inspection_result === "none" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                             entity.risk.recent_review?.quarter_sentiment_analysis === "flagged");
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "flagged" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         } else {
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         }
    //                       } else {
    //                         if(sentimentAnalysisFlagged) {
    //                           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                             entity.risk.recent_review?.infraction_severity === "major" ||
    //                             entity.risk.recent_review?.infraction_severity === "minor" ||
    //                             entity.risk.recent_review?.infraction_severity === "none" ||
    //                             entity.risk.recent_review?.inspection_result === "fail" ||
    //                             entity.risk.recent_review?.inspection_result === "pass" ||
    //                             entity.risk.recent_review?.inspection_result === "none" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                             entity.risk.recent_review?.quarter_sentiment_analysis === "flagged");
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "flagged" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         } else {
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         }
    //                       }
    //                     } else {
    //                       if(complaintSeverityNone) {
    //                         filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                           entity.risk.recent_review?.infraction_severity === "major" ||
    //                           entity.risk.recent_review?.infraction_severity === "minor" ||
    //                           entity.risk.recent_review?.infraction_severity === "none" ||
    //                           entity.risk.recent_review?.inspection_result === "fail" ||
    //                           entity.risk.recent_review?.inspection_result === "pass" ||
    //                           entity.risk.recent_review?.inspection_result === "none" ||
    //                           entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                           entity.risk.recent_review?.quarter_complaint_severity === "none");
    //                         if(sentimentAnalysisFlagged) {
    //                           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                             entity.risk.recent_review?.infraction_severity === "major" ||
    //                             entity.risk.recent_review?.infraction_severity === "minor" ||
    //                             entity.risk.recent_review?.infraction_severity === "none" ||
    //                             entity.risk.recent_review?.inspection_result === "fail" ||
    //                             entity.risk.recent_review?.inspection_result === "pass" ||
    //                             entity.risk.recent_review?.inspection_result === "none" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                             entity.risk.recent_review?.quarter_sentiment_analysis === "flagged");
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "flagged" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         } else {
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         }
    //                       } else {
    //                         if(sentimentAnalysisFlagged) {
    //                           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                             entity.risk.recent_review?.infraction_severity === "major" ||
    //                             entity.risk.recent_review?.infraction_severity === "minor" ||
    //                             entity.risk.recent_review?.infraction_severity === "none" ||
    //                             entity.risk.recent_review?.inspection_result === "fail" ||
    //                             entity.risk.recent_review?.inspection_result === "pass" ||
    //                             entity.risk.recent_review?.inspection_result === "none" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                             entity.risk.recent_review?.quarter_sentiment_analysis === "flagged");
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "flagged" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         } else {
    //                           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                             entity.risk.recent_review?.infraction_severity === "major" ||
    //                             entity.risk.recent_review?.infraction_severity === "minor" ||
    //                             entity.risk.recent_review?.infraction_severity === "none" ||
    //                             entity.risk.recent_review?.inspection_result === "fail" ||
    //                             entity.risk.recent_review?.inspection_result === "pass" ||
    //                             entity.risk.recent_review?.inspection_result === "none" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                             entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                         }
    //                       }
    //                     }
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // console.log("sizes: " + filterAppliedEntities.length + " and : " + allEntities.length)
    if(filterAppliedEntities.length === allEntities.length) {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
      setFilteredEntities(filterAppliedEntities);
    }
  }

  const labels = ['High', 'Medium', 'Low'];

  const options = {
    indexAxis: 'x',
    scales: {
      y: {
        beginAtZero: true
      }
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Risk Distribution'
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "High Risk",
        barPercentage: 3,
        barThickenss: 90,
        backgroundColor: ["#FF0003"],
        borderColor: ["#FF0003"],
        data: [highRiskEntities, 0, 0],
      },
      {
        label: "Medium Risk",
        barPercentage: 2.5,
        barThickenss: 30,
        backgroundColor: ["#FF8F3C"],
        borderColor: ["#FF8F3C"],
        data: [0, mediumRiskEntities, 0],
      },
      {
        label: "Low Risk",
        barPercentage: 3,
        barThickenss: 30,
        backgroundColor: ["#3EB75E"],
        borderColor: ["#3EB75E"],
        data: [0, 0, lowRiskEntities],
      }
    ]
  };

  const handleRefreshRiskScoreClick = (entityName, entityID) => {
    const requestOptions = {
      method: "GET"
    }
    setRefresh(true);
    fetch(`${getEntityAPI}/${entityName}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (!filterApplied) {
          const updatedEntities = allEntities.map(entity => {
            if (entity.id === entityID) {
              return result;
            } else {
              return entity;
            }
          });
          // console.log("updated Entities", updatedEntities);
          setAllEntities([...updatedEntities]);
        } else {
          const updatedEntities = allEntities.map(entity => {
            if (entity.id === entityID) {
              return result;
            } else {
              return entity;
            }
          });
          // console.log("updated Entities", updatedEntities);
          setAllEntities([...updatedEntities]);
          const updatedFilteredEntities = filteredEntities.map(entity => {
            if (entity.id === entityID) {
              return result;
            } else {
              return entity;
            }
          });
          // console.log("updated Entities", updatedEntities);
          setFilteredEntities([...updatedFilteredEntities]);
        }
        // setTimeout(() => {
          setRefresh(false);
        // }, 3000);
        toast.success("Risk score refreshed!");
      })
      .catch((error) => {
        // setTimeout(() => {
          setRefresh(false);
        // }, 3000);
        toast.error("Failed to refresh Risk score. Try again!");
        return console.error(error);
      });
    
  }

  const allowExpansion = (rowData) => {
    return 1;
  };

  const rowExpansionTemplate = (data) => {
    // console.log(data);
    if(!refresh) {
      return (
        <div className="entity-row-expansion row g-5 p-3">
          <div className="col-lg-12">
            <h5>Details</h5>
          </div>
          <div className="col-lg-6 mt-1">
            <p><strong>Name:</strong>&nbsp;&nbsp;{data.name}</p>
            <p><strong>Entity ID:</strong>&nbsp;&nbsp;{data.id}</p>
            <p><strong>Infraction History:</strong>&nbsp;&nbsp;{data.risk.infraction_history.replace("to", " to ")}</p>
          </div>
          <div className="col-lg-6 mt-1">
            <p><strong>Risk Score:</strong>&nbsp;&nbsp;{data.risk.score}</p>
            <div>
              <p className="d-inline-block"><strong>Risk Category:</strong> </p>&nbsp;&nbsp;&nbsp;
              {(data.risk.score >= 13 && data.risk.score <= 18) ?
                <div className="risk-classification-wrapper d-inline-block" value="High" severity="High">
                  <p className="risk-classification-high">High</p>
                </div>
              : (data.risk.score >= 7 && data.risk.score <= 12) ?
                <div className="risk-classification-wrapper d-inline-block" value="Medium" severity="Medium">
                  <p className="risk-classification-medium">Medium</p>
                </div>
              : (data.risk.score <= 6 && data.risk.score >= 1) ?
                <div className="risk-classification-wrapper d-inline-block" value="Low" severity="Low">
                  <p className="risk-classification-low">Low</p>
                </div>
              : <div className="risk-classification-wrapper d-inline-block" value="NA" severity="NA">
                  <p className="risk-classification-NA">N/A</p>
                </div>
              }
            </div>
          </div>
          <div className="col-lg-12 mt--20">
            <h5>Recent Review</h5>
          </div>
          {data.risk.recent_review ? <>
            <div className="col-lg-6 mt-1">
              <p><strong>Review Date:</strong>&nbsp;&nbsp;{new Date(data.risk.recent_review.date).toLocaleDateString('en-us', {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric"})}</p>
              <p><strong>Inspection Result:</strong>&nbsp;&nbsp;{data.risk.recent_review.inspection_result.charAt(0).toUpperCase() + data.risk.recent_review.inspection_result.slice(1)}</p>
              <p><strong>Infraction Severity:</strong>&nbsp;&nbsp;{data.risk.recent_review.infraction_severity.charAt(0).toUpperCase() + data.risk.recent_review.infraction_severity.slice(1)}</p>
            </div>
            <div className="col-lg-6 mt-1">
              <p><strong>Quarter Complaint Severity:</strong>&nbsp;&nbsp;{data.risk.recent_review.quarter_complaint_severity.charAt(0).toUpperCase() + data.risk.recent_review.quarter_complaint_severity.slice(1)}</p>
              <p><strong>Quarter Sentiment Analysis:</strong>&nbsp;&nbsp;{data.risk.recent_review.quarter_sentiment_analysis.charAt(0).toUpperCase() + data.risk.recent_review.quarter_sentiment_analysis.slice(1)}</p>
              <p><strong>Annual Clients:</strong>&nbsp;&nbsp;{data.risk.recent_review.annual_clients}</p>
            </div>
          </> : <p className="mt-0">No recent review.</p>}
          <div className="col-lg-12 mt--20">
            <h5>Review History</h5>
          </div>
          <div className="col-lg-6 mt-1">
            {data.risk.reviews.length > 0 ? <ol className="mb-0">
              {data.risk.reviews.map((review, idx) => <li key={idx}><strong>{new Date(review.date).toLocaleDateString('en-us', {
              year: "numeric",
              month: "short",
              day: "numeric"})}</strong></li>)}
            </ol> : <p className="mt-0">No review.</p>}
          </div>
          <div className="col-lg-6 mt-1">
            <button style={{fontSize: "14px"}} className="edu-btn btn-small btn-bg-alt lh-1" onClick={() => handleRefreshRiskScoreClick(data.name, data.id)}>🔄 Refresh Risk Score</button><br></br>
            <button style={{fontSize: "14px"}} className="edu-btn btn-small btn-secondary lh-1 mt-3" onClick={() => {setShowAddReviewToEntityForm(true); setEntityName(data.name);}}>+ Add New Report</button>
          </div>
        </div>
      )
    } else {
      return <div className="mt-4">Refreshing data...<br></br><img src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="illustration" /></div>;
    }
  };
  
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" style={{fontFamily: "primeicons"}} />
          <InputText style={{paddingLeft: "2.5rem"}} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString('en-us', {
      year: "numeric",
      month: "short",
      day: "numeric"});
  };

  const riskClassificationTemplate = (rowData) => {
    if(rowData.risk.score >= 13 && rowData.risk.score <= 18) {
      return (
        <div className="risk-classification-wrapper" value="High" severity="High">
          <p className="risk-classification-high">High</p>
        </div>
      )
    } else if(rowData.risk.score >= 7 && rowData.risk.score <= 12) {
      return (
        <div className="risk-classification-wrapper" value="Medium" severity="Medium">
          <p className="risk-classification-medium">Medium</p>
        </div>
      )
    } else if(rowData.risk.score <= 6 && rowData.risk.score >= 1) {
      return (
        <div className="risk-classification-wrapper" value="Low" severity="Low">
          <p className="risk-classification-low">Low</p>
        </div>
      )
    } else {
      return (
        <div className="risk-classification-wrapper" value="NA" severity="NA">
          <p className="risk-classification-NA">N/A</p>
        </div>
      )
    };
  };
  
  const dateBodyTemplate = (rowData) => {
    return rowData.risk.recent_review ? formatDate(rowData.risk.recent_review.date) : "";
  };

  const riskClassificationItemTemplate = (option) => {
    if(option === "High") {
      return (
        <div className="risk-classification-wrapper" value="High" severity="high">
          <p className="risk-classification-high">High</p>
        </div>
      )
    } else if(option === "Medium") {
      return (
        <div className="risk-classification-wrapper" value="Medium" severity="medium">
          <p className="risk-classification-medium">Medium</p>
        </div>
      )
    } else if(option === "Low") {
      return (
        <div className="risk-classification-wrapper" value="low" severity="low">
          <p className="risk-classification-low">Low</p>
        </div>
      )
    } else {
      return (
        <div className="risk-classification-wrapper" value="NA" severity="NA">
          <p className="risk-classification-NA">N/A</p>
        </div>
      )
    };
  };

  const infractionHistoryTemplate = (rowData) => {
    // console.log(rowData);
    return (
      <p className="mb-0">{rowData.risk.infraction_history.replace("to", " to ").toString()}</p>
    )
  }

  const riskClassificationFilterTemplate = (options) => {
    // console.log(options);
    return (
      <Dropdown value={options.value} options={classifications} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={riskClassificationItemTemplate} placeholder="Select One" className="p-column-filter" showClear />
    );
  };

  const handleRiskClassification = (obj) => {
    // handleChangeClassification(obj);
    scrollIntoViewWithOffset("RegulatoryComplianceTable");
  };

  const onRowSelect = (e) => {
    // console.log("Selected: ", e.data);
    setFilteredEntities(filteredEntities => [e.data]);
    setFilterApplied(filterApplied => true);
    const anchorEl = document.getElementById("RegulatedEntities");
    anchorEl.scrollIntoView({behavior: 'smooth'});
  }

  const showRiskCalculationPopup = () => {
    document.getElementById("RiskCalculationPopup").classList.remove("hidePopup");
  }

  const hideRiskCalculationInfo = () => {
    document.getElementById("RiskCalculationPopup").classList.add("hidePopup");
  }

  const showTop5HighPopup = () => {
    document.getElementById("RiskCalculationPopup").classList.add("hidePopup");
  }
  
  const hideTop5HighPopup = () => {
    document.getElementById("RiskCalculationPopup").classList.add("hidePopup");
  }

  const showRiskTrendPopup = () => {
    document.getElementById("RiskCalculationPopup").classList.add("hidePopup");
  }
  
  const hideRiskTrendPopup = () => {
    document.getElementById("RiskCalculationPopup").classList.add("hidePopup");
  }

  useEffect(() => {
    ProductServices.getProducts().then(data => {
      const temp = data.entities.map((entity) => {
        if(entity.risk.score <= 6) {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "Low" }});
        } else if(entity.risk.score >= 7 && entity.risk.score <= 12) {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "Medium" }});
        } else if(entity.risk.score >= 13) {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "High" }});
        } else {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "N/A" }});
        }
      });
      setAllEntities(temp);
      setTotalEntities(data.size);
      let highRiskEntities = temp;
      highRiskEntities.sort((a, b) => a.risk.score - b.risk.score);
      setTopHighRiskEntities(highRiskEntities.slice(Math.max(highRiskEntities.length - 5, 0)).reverse());

      let inspectionFailedFlagged = 0;
      let inspectionNoneFlagged = 0;
      let inspectionPassFlagged = 0;
      let inspectionFailedNone = 0;
      let inspectionNoneNone = 0;
      let inspectionPassNone = 0;

      // round((df_selection[df_selection["Inspection Results"] == 'Pass'].shape[0] / df_selection["Entity ID"].count() * 100), 2)
      let inspectionPassEntities = 0;
      temp.forEach((entity) => {if(entity.risk.recent_review?.inspection_result === "pass") inspectionPassEntities += 1});
      let compliance_Rate = ((inspectionPassEntities / data.size) * 100).toFixed(2);
      setComplianceRate(compliance_Rate);

      // round(((df_selection["Infraction Type"] != 'None').sum() / total_entities) * 100, 2)
      let repeatOffenderCount = 0;
      temp.forEach((entity) => {if(entity.risk.recent_review?.infraction_severity !== "none") repeatOffenderCount += 1});
      let repeatOffender_rate = ((repeatOffenderCount / data.size) * 100).toFixed(2);
      setRepeatOffenderCount(repeatOffender_rate);

      // round(((df_selection['Inspection Results'] == 'Fail').sum() / total_count) * 100, 2)
      let failureCount = 0;
      temp.forEach((entity) => {if(entity.risk.recent_review?.inspection_result === "fail") failureCount += 1});
      let inspectionFailure_rate = ((failureCount / data.size) * 100).toFixed(2);
      setInspectionFailureRate(inspectionFailure_rate);

      temp.forEach((entity) => {
        if(entity.risk.recent_review?.quarter_sentiment_analysis === "flagged") {
          inspectionFailedFlagged = entity.risk.recent_review?.inspection_result === "fail" && inspectionFailedFlagged + 1;
          inspectionNoneFlagged = entity.risk.recent_review?.inspection_result === "none" && inspectionNoneFlagged + 1;
          inspectionPassFlagged = entity.risk.recent_review?.inspection_result === "pass" && inspectionPassFlagged + 1;
        } else if(entity.risk.recent_review?.quarter_sentiment_analysis === "none") {
          inspectionFailedNone = entity.risk.recent_review?.inspection_result === "fail" && inspectionFailedNone + 1;
          inspectionNoneNone = entity.risk.recent_review?.inspection_result === "none" && inspectionNoneNone + 1;
          inspectionPassNone = entity.risk.recent_review?.inspection_result === "pass" && inspectionPassNone + 1;
        }
      });
      setStackedBarData([
        {
          name: 'Flagged',
          Fail: inspectionFailedFlagged,
          None: inspectionNoneFlagged,
          Pass: inspectionPassFlagged,
        },
        {
          name: 'None',
          Fail: inspectionFailedNone,
          None: inspectionNoneNone,
          Pass: inspectionPassNone,
        },
      ]);
    });
    // setLoading(true);
    // fetch(getEntitiesAPI, {
    //   method: "GET",      
    // }).then((res) => {
    //   return res.json();
    // }).then((data) => {
    //   const temp = data.entities.map((entity) => {
    //     if(entity.risk.score <= 6) {
    //       return ({ ...entity, risk: { ...entity.risk, riskClassification : "Low" }});
    //     } else if(entity.risk.score >= 7 && entity.risk.score <= 12) {
    //       return ({ ...entity, risk: { ...entity.risk, riskClassification : "Medium" }});
    //     } else if(entity.risk.score >= 13) {
    //       return ({ ...entity, risk: { ...entity.risk, riskClassification : "High" }});
    //     } else {
    //       return ({ ...entity, risk: { ...entity.risk, riskClassification : "N/A" }});
    //     }
    //   })
    //   console.log(temp);
    //   setAllEntities(temp);
    //   setTotalEntities(data.size);
    //   setLoading(false);
    //   let highRiskEntities = temp;
    //   highRiskEntities.sort((a, b) => a.risk.score - b.risk.score);
    //   setTopHighRiskEntities(highRiskEntities.slice(Math.max(highRiskEntities.length - 5, 0)).reverse());

    //   // (df_selection[df_selection["Inspection Results"] == 'Pass'].shape[0] / df_selection["Entity ID"].count() * 100)
    //   let inspectionPassEntities = 0;
    //   temp.forEach((entity) => {if(entity.risk.recent_review?.inspection_result === "pass") inspectionPassEntities += 1});
    //   let compliance_Rate = ((inspectionPassEntities / data.size) * 100).toFixed(2);
    //   setComplianceRate(compliance_Rate);

    //   let inspectionFailedFlagged = 0;
    //   let inspectionNoneFlagged = 0;
    //   let inspectionPassFlagged = 0;
    //   let inspectionFailedNone = 0;
    //   let inspectionNoneNone = 0;
    //   let inspectionPassNone = 0;
    //   temp.forEach((entity) => {
    //     if(entity.risk.recent_review?.quarter_sentiment_analysis === "flagged") {
    //       inspectionFailedFlagged = entity.risk.recent_review?.inspection_result === "fail" && inspectionFailedFlagged + 1;
    //       inspectionNoneFlagged = entity.risk.recent_review?.inspection_result === "none" && inspectionNoneFlagged + 1;
    //       inspectionPassFlagged = entity.risk.recent_review?.inspection_result === "pass" && inspectionPassFlagged + 1;
    //     } else if(entity.risk.recent_review?.quarter_sentiment_analysis === "none") {
    //       inspectionFailedNone = entity.risk.recent_review?.inspection_result === "fail" && inspectionFailedNone + 1;
    //       inspectionNoneNone = entity.risk.recent_review?.inspection_result === "none" && inspectionNoneNone + 1;
    //       inspectionPassNone = entity.risk.recent_review?.inspection_result === "pass" && inspectionPassNone + 1;
    //     }
    //   });
    //   setStackedBarData([
    //     {
    //       name: 'Flagged',
    //       Fail: inspectionFailedFlagged,
    //       None: inspectionNoneFlagged,
    //       Pass: inspectionPassFlagged,
    //     },
    //     {
    //       name: 'None',
    //       Fail: inspectionFailedNone,
    //       None: inspectionNoneNone,
    //       Pass: inspectionPassNone,
    //     },
    //   ]);
    // })
  }, [getEntitiesAPI]);

  useEffect(() => {
    if(allEntities) {
      let high = 0;
      let medium = 0;
      let low = 0;
      allEntities.forEach((entity) => {
        if(entity.risk.score >= 13) {
          high += 1;
        } else if(entity.risk.score >= 7 && entity.risk.score <= 12) {
          medium += 1;
        } else {
          low += 1;
        }
        setHighRiskEntities(high);
        setMediumRiskEntities(medium);
        setLowRiskEntities(low);
      });
      const data = {
        labels: [''],
        datasets: [
          {
            label: 'High',
            backgroundColor: "#FF0003",
            borderColor: "#FF0003",
            data: [parseInt(high)]
          },
          {
            label: 'Medium',
            backgroundColor: "#FF8F3C",
            borderColor: "#FF8F3C",
            data: [parseInt(medium)]
          },
          {
            label: 'Low',
            backgroundColor: "#3EB75E",
            borderColor: "#3EB75E",
            data: [parseInt(low)]
          }
        ]
      };
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false
            }
          }
        }
      };
  
      setChartData(data);
      setChartOptions(options);
    }
  }, [allEntities])

  useEffect(() => {
    if(formSubmittedSuccessfully) {
      setLoading(true);
      fetch(getEntitiesAPI, {
        method: "GET",      
      }).then((res) => {
        return res.json();
      }).then((data) => {
        setAllEntities(data.entities);
        setTotalEntities(data.size);
        setLoading(false);
      })
      setFormSubmittedSuccessfully(false);
    }
  }, [getEntitiesAPI, formSubmittedSuccessfully, setFormSubmittedSuccessfully])

  useEffect(() => {
    if(addReviewToEntityFormSubmittedSuccessfully) {
      // setLoading(true);
      fetch(getEntitiesAPI, {
        method: "GET",      
      }).then((res) => {
        return res.json();
      }).then((data) => {
        setAllEntities(data.entities);
        setTotalEntities(data.size);
        // setLoading(false);
      })
      setAddReviewToEntityFormSubmittedSuccessfully(false);
    }
  }, [getEntitiesAPI, addReviewToEntityFormSubmittedSuccessfully, setAddReviewToEntityFormSubmittedSuccessfully]);

  return (
    <>
      <Element
        name="healthcareInsights" 
        className="edu-about-area about-style-1 edu-section-gap"
      >
        <div className="container inner about-feature-list">
          <div className="row g-5 justify-content-center align-items-center">
            <div className="col-lg-12">
              <RegulatoryDashboardSectionTitle
                classes = "text-left"
                // slogan = "Eligibility"
                title = "Regulatory Compliance Dashboard 2"
              />
            </div>
            {loading ? <div className="mt-4">Loading data...<br></br><img src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="illustration" /></div> : <>
              <h3>Month of {month[new Date().getMonth()]}</h3>
              <div className="col-lg-9 border-8 py-4 position-relative">
                <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showRiskCalculationPopup()}></img>
                <div className="row">
                  <div className="col-6 d-flex flex-column gap-4">
                    <h5 className="mb-0">Risk Category Distribution</h5>
                    <p
                      className="distribution-entities cursor-pointer mb-0"
                      onClick={() => {
                        // scrollIntoViewWithOffset("RegulatoryComplianceTable");
                        resetFilters();
                        handleCheckboxChange("riskClassification", "Total");
                        setShowFilters(true);
                        setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)
                      }}
                    >
                      Total Entities: {totalEntities}
                    </p>
                    <div className="entities-button-container d-flex flex-column align-items-center">
                      <p
                        className="distribution-entities"
                        id="highRiskEntitiesDistribution"
                        onClick={() => {
                          // scrollIntoViewWithOffset("RegulatoryComplianceTable");
                          resetFilters();
                          handleCheckboxChange("riskClassification", "High");
                          setShowFilters(true);
                          setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)
                        }}
                      >
                        <span>{highRiskEntities}</span>&nbsp;&nbsp; High Risk Entities
                      </p>
                      <p
                        className="distribution-entities"
                        id="mediumRiskEntitiesDistribution"
                        onClick={() => {
                          // scrollIntoViewWithOffset("RegulatoryComplianceTable");
                          resetFilters();
                          handleCheckboxChange("riskClassification", "Medium");
                          setShowFilters(true);
                          setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)
                        }}
                      >
                        <span>{mediumRiskEntities}</span>&nbsp;&nbsp; Medium Risk Entities
                      </p>
                      <p
                        className="distribution-entities"
                        id="lowRiskEntitiesDistribution"
                        onClick={() => {
                          // scrollIntoViewWithOffset("RegulatoryComplianceTable");
                          resetFilters();
                          handleCheckboxChange("riskClassification", "Low");
                          setShowFilters(true);
                          setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)
                        }}
                      >
                        <span>{lowRiskEntities}</span>&nbsp;&nbsp; Low Risk Entities
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mt--40">
                      <Chart type="bar" data={chartData} options={chartOptions} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pe-0">
                <div className="d-flex flex-column gap-4">
                  <div className="dashboard-change-card border-8 p-4">
                    <p className="mb-3">Compliance Rate</p>
                    <div className="percentage-rate-and-change d-flex justify-content-between mb-3">
                      <h5 className="mb-0">{complianceRate} %</h5>
                      <p className="percentage-change mb-0">
                        <img src={`${process.env.PUBLIC_URL}/images/icons/green-arrow-up.svg`} alt="arrow" />
                        &nbsp;&nbsp;10 %
                      </p>
                    </div>
                    <p className="dashboard-change mb-1">from {(complianceRate - 10).toFixed(2)}% last month</p>
                  </div>
                  <div className="dashboard-change-card border-8 p-4">
                    <p className="mb-3">Repeat Offender Rate</p>
                    <div className="percentage-rate-and-change d-flex justify-content-between mb-3">
                      <h5 className="mb-0">{repeatOffenderCount} %</h5>
                      <p className="percentage-change mb-0">
                        <img src={`${process.env.PUBLIC_URL}/images/icons/red-arrow-down.svg`} alt="arrow" />
                        &nbsp;&nbsp;-8 %
                      </p>
                    </div>
                    <p className="dashboard-change mb-1">from {(parseFloat(repeatOffenderCount) + 8).toFixed(2)}% last month</p>
                  </div>
                  <div className="dashboard-change-card border-8 p-4">
                    <p className="mb-3">Inspection Failure Rate</p>
                    <div className="percentage-rate-and-change d-flex justify-content-between mb-3">
                      <h5 className="mb-0">{inspectionFailureRate} %</h5>
                      <p className="percentage-change mb-0">
                        <img src={`${process.env.PUBLIC_URL}/images/icons/red-arrow-down.svg`} alt="arrow" />
                        &nbsp;&nbsp;-9 %
                      </p>
                    </div>
                    <p className="dashboard-change mb-1">from {(parseFloat(inspectionFailureRate) + 9).toFixed(2)}% last month</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 border-8 p-4 position-relative">
                <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showTop5HighPopup()}></img>
                <h5 className="mb-0">Top 5 High Risk Entities</h5>
                <DataTable
                  className="mt-1"
                  value={topHighRiskEntities}
                  // paginator
                  sortMode="multiple"
                  removableSort
                  rows={5}
                  // rowsPerPageOptions={[5, 10, 25, 50]}
                  // tableStyle={{ minWidth: '50rem' }}
                  dataKey="id"
                  // header={header}
                  filters={filters}
                  // filterDisplay="row"
                  // loading={loading}
                  globalFilterFields={['name', 'risk.score', 'risk.recent_review.annual_clients', 'risk.recent_review.date', 'risk.infraction_history']}
                  emptyMessage="No entities found."
                  // expandedRows={expandedRows}
                  // onRowToggle={(e) => setExpandedRows(e.data)}
                  // rowExpansionTemplate={rowExpansionTemplate}
                  selectionMode="single"
                  onRowSelect={onRowSelect}
                  style={{fontSize: "16px"}}
                >
                  <Column field="name" sortable header="Entity Name"></Column>
                  <Column field="risk.score" sortable header="Risk Score"></Column>
                  <Column field="risk.recent_review.infraction_severity" header="Infraction Severity"></Column>
                  {/* <Column field="goDetails"></Column> */}
                </DataTable>
              </div>
              <div className="col-lg-5 pe-0">
                <div className="risk-trend-container border-8 p-4 position-relative">
                  <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showRiskTrendPopup()}></img>
                  <h5 className="mb-0">Risk Trend</h5>
                  <div className="risk-trend-img-container">
                    <img src={`${process.env.PUBLIC_URL}/images/Frame 5244.PNG`} alt="risk trend" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 border-8 p-4 position-relative">
                <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon"></img>
                <h5 className="mb-0">Feature Importance</h5>
                <ResponsiveContainer width="100%" height={400} className="mt-4">
                  <BarChart width={700} height={250} data={waterfallData} margin={{top: 20, right: 20, bottom: 20, left: 20}} >
                    <XAxis dataKey="feature" />
                    <YAxis />
                    <TooltipRecharts />
                    <Bar dataKey="importance" fill="#7152E9" >
                      <LabelList dataKey="barLabel" position="top" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="col-lg-6 border-8 p-4 position-relative">
                <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon"></img>
                <h5 className="mb-0">Distribution of Sentiment Analysis with Inspection Results</h5>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart width={600} height={400} data={stackedBarData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <TooltipRecharts />
                    <LegendRecharts />
                    <Bar dataKey="Fail" stackId="a" fill="#ff6384" />
                    <Bar dataKey="None" stackId="a" fill="#36a2eb" />
                    <Bar dataKey="Pass" stackId="a" fill="#4bc0c0" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="col-lg-12" id="RegulatedEntities">
                <div className="table-header d-flex flex-row justify-content-between align-items-center">
                  <h6 className="m-0">Regulated Entities</h6>
                  <div className="entities-table-buttons d-flex flex-row align-items-center gap-4">
                    <button className="edu-btn btn-secondary lh-1 btn-small" onClick={() => setShowAddEntityForm(true)}>+ Add Entity</button>
                    <button className="edu-btn btn-bg-alt lh-1 btn-small" onClick={() => setShowFilters(!showFilters)}><img src={`${process.env.PUBLIC_URL}/images/icons/filter-solid.svg`} className="cursor-pointer" alt="filters" /></button>
                  </div>
                </div>
                {showFilters && <form className="filters-container mt-4" onSubmit={handleFilterEntityFormSubmit}>
                  <div className="entity-filters-body row justify-content-center">
                    {Object.keys(attributes).map(category => (
                      <div key={category} className="row">
                        <p className="mt-4 mb-0"><strong>{(category.charAt(0).toUpperCase() + category.slice(1)).replace(/([A-Z])/g, ' $1')}</strong></p>
                        {attributes[category].map(value => (
                          <div key={value} className="col-12 col-lg-4 entity-filter-label">
                            <Checkbox
                              label={value.charAt(0).toUpperCase() + value.slice(1)}
                              value={applyFilters[category].has(value)}
                              onChange={() => handleCheckboxChange(category, value)}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <div className="entity-filters-footer d-flex flex-row justify-content-center mt-4">
                    <button className="edu-btn btn-primary btn-medium" type="submit" id="ApplyFiltersBtn">Apply Filters</button>
                  </div>
                  <p className="mt-4 mb-0">Note: If no filter is selected it will reset the table and show all rows.</p>
                </form>}
                <div className="card mt-4" id="RegulatoryComplianceTable">
                  <DataTable
                    value={filterApplied ? filteredEntities : allEntities}
                    paginator
                    sortMode="multiple"
                    removableSort
                    rows={25}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    dataKey="id"
                    header={header}
                    filters={filters}
                    // filterDisplay="row"
                    // loading={loading}
                    globalFilterFields={['name', 'risk.score', 'risk.recent_review.annual_clients', 'risk.recent_review.date', 'risk.infraction_history']}
                    emptyMessage="No entities found."                  
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    style={{fontSize: "16px"}}
                  >
                    <Column field="name" sortable header="Entity Name"></Column>
                    <Column field="risk.score" body={riskClassificationTemplate} showFilterMenu={false} header="Risk Classification" filter filterElement={riskClassificationFilterTemplate}></Column>
                    <Column field="risk.score" sortable header="Risk Score"></Column>
                    <Column field="risk.recent_review.annual_clients" sortable header="Annual Clients"></Column>
                    <Column field="risk.recent_review.date" body={dateBodyTemplate} header="Recent Review"></Column>
                    <Column field="risk.infraction_history" header="Infraction History" body={infractionHistoryTemplate}></Column>
                    <Column expander={allowExpansion} style={{ width: '5rem' }} />
                  </DataTable>
                </div>
              </div>
            </>}
          </div>
        </div>
        <RiskCalculation hideRiskCalculationInfo={hideRiskCalculationInfo} />
      </Element>
    </>
  )
}

export default RegulatoryDashboard;