export const ProductServices = {
  getProductsData() {
    return {
			"entities": [
        {
            "id": "66d4b4df185cf25309412b7e",
            "name": "Tech Innovators Inc.",
            "risk": {
                "entity": "66d4b4df185cf25309412b7e",
                "entity_name": "Tech Innovators Inc.",
                "id": "66d4b4df185cf25309412b7f",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 5200,
                    "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b4df185cf25309412b7e",
                    "entity_name": "Tech Innovators Inc.",
                    "id": "66d4b4e0185cf25309412b81",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4df185cf25309412b7f"
                },
                "reviews": [
                    {
                        "annual_clients": 5000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b4df185cf25309412b7e",
                        "entity_name": "Tech Innovators Inc.",
                        "id": "66d4b4e0185cf25309412b80",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4df185cf25309412b7f"
                    },
                    {
                        "annual_clients": 5200,
                        "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b4df185cf25309412b7e",
                        "entity_name": "Tech Innovators Inc.",
                        "id": "66d4b4e0185cf25309412b81",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4df185cf25309412b7f"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b4e1185cf25309412b82",
            "name": "Green Energy Solutions",
            "risk": {
                "entity": "66d4b4e1185cf25309412b82",
                "entity_name": "Green Energy Solutions",
                "id": "66d4b4e1185cf25309412b83",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 3000,
                    "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b4e1185cf25309412b82",
                    "entity_name": "Green Energy Solutions",
                    "id": "66d4b4e1a8e598dffe4d8216",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b4e1185cf25309412b83"
                },
                "reviews": [
                    {
                        "annual_clients": 3000,
                        "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b4e1185cf25309412b82",
                        "entity_name": "Green Energy Solutions",
                        "id": "66d4b4e1a8e598dffe4d8216",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b4e1185cf25309412b83"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b4e2a8e598dffe4d8217",
            "name": "Urban Mobility Corp.",
            "risk": {
                "entity": "66d4b4e2a8e598dffe4d8217",
                "entity_name": "Urban Mobility Corp.",
                "id": "66d4b4e2a8e598dffe4d8218",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 15500,
                    "date": "Sun, 18 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b4e2a8e598dffe4d8217",
                    "entity_name": "Urban Mobility Corp.",
                    "id": "66d4b4e3185cf25309412b84",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4e2a8e598dffe4d8218"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b4e2a8e598dffe4d8217",
                        "entity_name": "Urban Mobility Corp.",
                        "id": "66d4b4e29c12f1000aa6ab51",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4e2a8e598dffe4d8218"
                    },
                    {
                        "annual_clients": 15500,
                        "date": "Sun, 18 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b4e2a8e598dffe4d8217",
                        "entity_name": "Urban Mobility Corp.",
                        "id": "66d4b4e3185cf25309412b84",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4e2a8e598dffe4d8218"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b4e308d6264adba03cee",
            "name": "HealthFirst Medical",
            "risk": {
                "entity": "66d4b4e308d6264adba03cee",
                "entity_name": "HealthFirst Medical",
                "id": "66d4b4e308d6264adba03cef",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 8000,
                    "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b4e308d6264adba03cee",
                    "entity_name": "HealthFirst Medical",
                    "id": "66d4b4e308d6264adba03cf0",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4e308d6264adba03cef"
                },
                "reviews": [
                    {
                        "annual_clients": 8000,
                        "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b4e308d6264adba03cee",
                        "entity_name": "HealthFirst Medical",
                        "id": "66d4b4e308d6264adba03cf0",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4e308d6264adba03cef"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b4e49c12f1000aa6ab52",
            "name": "EcoFriendly Packaging",
            "risk": {
                "entity": "66d4b4e49c12f1000aa6ab52",
                "entity_name": "EcoFriendly Packaging",
                "id": "66d4b4e49c12f1000aa6ab53",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 2100,
                    "date": "Wed, 10 May 2023 00:00:00 GMT",
                    "entity": "66d4b4e49c12f1000aa6ab52",
                    "entity_name": "EcoFriendly Packaging",
                    "id": "66d4b4e508d6264adba03cf1",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4e49c12f1000aa6ab53"
                },
                "reviews": [
                    {
                        "annual_clients": 2000,
                        "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b4e49c12f1000aa6ab52",
                        "entity_name": "EcoFriendly Packaging",
                        "id": "66d4b4e4185cf25309412b85",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4e49c12f1000aa6ab53"
                    },
                    {
                        "annual_clients": 2100,
                        "date": "Wed, 10 May 2023 00:00:00 GMT",
                        "entity": "66d4b4e49c12f1000aa6ab52",
                        "entity_name": "EcoFriendly Packaging",
                        "id": "66d4b4e508d6264adba03cf1",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4e49c12f1000aa6ab53"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b4e6185cf25309412b86",
            "name": "Smart Home Systems",
            "risk": {
                "entity": "66d4b4e6185cf25309412b86",
                "entity_name": "Smart Home Systems",
                "id": "66d4b4e6185cf25309412b87",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 12000,
                    "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b4e6185cf25309412b86",
                    "entity_name": "Smart Home Systems",
                    "id": "66d4b4e6185cf25309412b88",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b4e6185cf25309412b87"
                },
                "reviews": [
                    {
                        "annual_clients": 12000,
                        "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b4e6185cf25309412b86",
                        "entity_name": "Smart Home Systems",
                        "id": "66d4b4e6185cf25309412b88",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b4e6185cf25309412b87"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b4e608d6264adba03cf2",
            "name": "NextGen Pharmaceuticals",
            "risk": {
                "entity": "66d4b4e608d6264adba03cf2",
                "entity_name": "NextGen Pharmaceuticals",
                "id": "66d4b4e608d6264adba03cf3",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 10200,
                    "date": "Tue, 25 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b4e608d6264adba03cf2",
                    "entity_name": "NextGen Pharmaceuticals",
                    "id": "66d4b4e7185cf25309412b8a",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4e608d6264adba03cf3"
                },
                "reviews": [
                    {
                        "annual_clients": 10000,
                        "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b4e608d6264adba03cf2",
                        "entity_name": "NextGen Pharmaceuticals",
                        "id": "66d4b4e7185cf25309412b89",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4e608d6264adba03cf3"
                    },
                    {
                        "annual_clients": 10200,
                        "date": "Tue, 25 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b4e608d6264adba03cf2",
                        "entity_name": "NextGen Pharmaceuticals",
                        "id": "66d4b4e7185cf25309412b8a",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4e608d6264adba03cf3"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b4e8a8e598dffe4d8219",
            "name": "FinTech Pioneers",
            "risk": {
                "entity": "66d4b4e8a8e598dffe4d8219",
                "entity_name": "FinTech Pioneers",
                "id": "66d4b4e8a8e598dffe4d821a",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 7000,
                    "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b4e8a8e598dffe4d8219",
                    "entity_name": "FinTech Pioneers",
                    "id": "66d4b4e8185cf25309412b8b",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4e8a8e598dffe4d821a"
                },
                "reviews": [
                    {
                        "annual_clients": 7000,
                        "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b4e8a8e598dffe4d8219",
                        "entity_name": "FinTech Pioneers",
                        "id": "66d4b4e8185cf25309412b8b",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4e8a8e598dffe4d821a"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b4e9185cf25309412b8c",
            "name": "Clean Water Initiative",
            "risk": {
                "entity": "66d4b4e9185cf25309412b8c",
                "entity_name": "Clean Water Initiative",
                "id": "66d4b4e9185cf25309412b8d",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 4000,
                    "date": "Wed, 22 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b4e9185cf25309412b8c",
                    "entity_name": "Clean Water Initiative",
                    "id": "66d4b4e908d6264adba03cf4",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4e9185cf25309412b8d"
                },
                "reviews": [
                    {
                        "annual_clients": 4000,
                        "date": "Wed, 22 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b4e9185cf25309412b8c",
                        "entity_name": "Clean Water Initiative",
                        "id": "66d4b4e908d6264adba03cf4",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4e9185cf25309412b8d"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b4eaa8e598dffe4d821b",
            "name": "CyberSecure Solutions",
            "risk": {
                "entity": "66d4b4eaa8e598dffe4d821b",
                "entity_name": "CyberSecure Solutions",
                "id": "66d4b4eaa8e598dffe4d821c",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 6200,
                    "date": "Wed, 12 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b4eaa8e598dffe4d821b",
                    "entity_name": "CyberSecure Solutions",
                    "id": "66d4b4ea185cf25309412b8e",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4eaa8e598dffe4d821c"
                },
                "reviews": [
                    {
                        "annual_clients": 6000,
                        "date": "Wed, 18 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b4eaa8e598dffe4d821b",
                        "entity_name": "CyberSecure Solutions",
                        "id": "66d4b4ea08d6264adba03cf5",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b4eaa8e598dffe4d821c"
                    },
                    {
                        "annual_clients": 6200,
                        "date": "Wed, 12 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b4eaa8e598dffe4d821b",
                        "entity_name": "CyberSecure Solutions",
                        "id": "66d4b4ea185cf25309412b8e",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4eaa8e598dffe4d821c"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b4eb9c12f1000aa6ab54",
            "name": "Renewable Energy Corp.",
            "risk": {
                "entity": "66d4b4eb9c12f1000aa6ab54",
                "entity_name": "Renewable Energy Corp.",
                "id": "66d4b4eb9c12f1000aa6ab55",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 3500,
                    "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b4eb9c12f1000aa6ab54",
                    "entity_name": "Renewable Energy Corp.",
                    "id": "66d4b4eb9c12f1000aa6ab56",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4eb9c12f1000aa6ab55"
                },
                "reviews": [
                    {
                        "annual_clients": 3500,
                        "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b4eb9c12f1000aa6ab54",
                        "entity_name": "Renewable Energy Corp.",
                        "id": "66d4b4eb9c12f1000aa6ab56",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4eb9c12f1000aa6ab55"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b4eca8e598dffe4d821d",
            "name": "Urban Agriculture Ltd.",
            "risk": {
                "entity": "66d4b4eca8e598dffe4d821d",
                "entity_name": "Urban Agriculture Ltd.",
                "id": "66d4b4eca8e598dffe4d821e",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 2500,
                    "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b4eca8e598dffe4d821d",
                    "entity_name": "Urban Agriculture Ltd.",
                    "id": "66d4b4eca8e598dffe4d821f",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4eca8e598dffe4d821e"
                },
                "reviews": [
                    {
                        "annual_clients": 2500,
                        "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b4eca8e598dffe4d821d",
                        "entity_name": "Urban Agriculture Ltd.",
                        "id": "66d4b4eca8e598dffe4d821f",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4eca8e598dffe4d821e"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b4eda8e598dffe4d8220",
            "name": "AI Innovations",
            "risk": {
                "entity": "66d4b4eda8e598dffe4d8220",
                "entity_name": "AI Innovations",
                "id": "66d4b4eda8e598dffe4d8221",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 9200,
                    "date": "Tue, 18 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b4eda8e598dffe4d8220",
                    "entity_name": "AI Innovations",
                    "id": "66d4b4ee185cf25309412b90",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4eda8e598dffe4d8221"
                },
                "reviews": [
                    {
                        "annual_clients": 9000,
                        "date": "Sun, 22 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b4eda8e598dffe4d8220",
                        "entity_name": "AI Innovations",
                        "id": "66d4b4ed185cf25309412b8f",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4eda8e598dffe4d8221"
                    },
                    {
                        "annual_clients": 9200,
                        "date": "Tue, 18 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b4eda8e598dffe4d8220",
                        "entity_name": "AI Innovations",
                        "id": "66d4b4ee185cf25309412b90",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4eda8e598dffe4d8221"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b4ee08d6264adba03cf6",
            "name": "BioTech Enterprises",
            "risk": {
                "entity": "66d4b4ee08d6264adba03cf6",
                "entity_name": "BioTech Enterprises",
                "id": "66d4b4ee08d6264adba03cf7",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 11000,
                    "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b4ee08d6264adba03cf6",
                    "entity_name": "BioTech Enterprises",
                    "id": "66d4b4ee08d6264adba03cf8",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b4ee08d6264adba03cf7"
                },
                "reviews": [
                    {
                        "annual_clients": 11000,
                        "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b4ee08d6264adba03cf6",
                        "entity_name": "BioTech Enterprises",
                        "id": "66d4b4ee08d6264adba03cf8",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b4ee08d6264adba03cf7"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b4efa8e598dffe4d8222",
            "name": "Smart City Solutions",
            "risk": {
                "entity": "66d4b4efa8e598dffe4d8222",
                "entity_name": "Smart City Solutions",
                "id": "66d4b4efa8e598dffe4d8223",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 13000,
                    "date": "Fri, 10 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b4efa8e598dffe4d8222",
                    "entity_name": "Smart City Solutions",
                    "id": "66d4b4ef08d6264adba03cf9",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4efa8e598dffe4d8223"
                },
                "reviews": [
                    {
                        "annual_clients": 13000,
                        "date": "Fri, 10 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b4efa8e598dffe4d8222",
                        "entity_name": "Smart City Solutions",
                        "id": "66d4b4ef08d6264adba03cf9",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4efa8e598dffe4d8223"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b4f0185cf25309412b91",
            "name": "Digital Health Corp.",
            "risk": {
                "entity": "66d4b4f0185cf25309412b91",
                "entity_name": "Digital Health Corp.",
                "id": "66d4b4f0185cf25309412b92",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 7700,
                    "date": "Sat, 22 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b4f0185cf25309412b91",
                    "entity_name": "Digital Health Corp.",
                    "id": "66d4b4f108d6264adba03cfa",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4f0185cf25309412b92"
                },
                "reviews": [
                    {
                        "annual_clients": 7500,
                        "date": "Sat, 28 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b4f0185cf25309412b91",
                        "entity_name": "Digital Health Corp.",
                        "id": "66d4b4f0a8e598dffe4d8224",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4f0185cf25309412b92"
                    },
                    {
                        "annual_clients": 7700,
                        "date": "Sat, 22 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b4f0185cf25309412b91",
                        "entity_name": "Digital Health Corp.",
                        "id": "66d4b4f108d6264adba03cfa",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4f0185cf25309412b92"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b4f2a8e598dffe4d8225",
            "name": "GreenTech Innovations",
            "risk": {
                "entity": "66d4b4f2a8e598dffe4d8225",
                "entity_name": "GreenTech Innovations",
                "id": "66d4b4f2a8e598dffe4d8226",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 5000,
                    "date": "Sun, 12 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b4f2a8e598dffe4d8225",
                    "entity_name": "GreenTech Innovations",
                    "id": "66d4b4f2185cf25309412b93",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4f2a8e598dffe4d8226"
                },
                "reviews": [
                    {
                        "annual_clients": 5000,
                        "date": "Sun, 12 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b4f2a8e598dffe4d8225",
                        "entity_name": "GreenTech Innovations",
                        "id": "66d4b4f2185cf25309412b93",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4f2a8e598dffe4d8226"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b4f3a8e598dffe4d8227",
            "name": "Clean Energy Solutions",
            "risk": {
                "entity": "66d4b4f3a8e598dffe4d8227",
                "entity_name": "Clean Energy Solutions",
                "id": "66d4b4f3a8e598dffe4d8228",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 4500,
                    "date": "Sat, 18 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b4f3a8e598dffe4d8227",
                    "entity_name": "Clean Energy Solutions",
                    "id": "66d4b4f3a8e598dffe4d8229",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4f3a8e598dffe4d8228"
                },
                "reviews": [
                    {
                        "annual_clients": 4500,
                        "date": "Sat, 18 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b4f3a8e598dffe4d8227",
                        "entity_name": "Clean Energy Solutions",
                        "id": "66d4b4f3a8e598dffe4d8229",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4f3a8e598dffe4d8228"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b4f4a8e598dffe4d822a",
            "name": "TechHealth Innovations",
            "risk": {
                "entity": "66d4b4f4a8e598dffe4d822a",
                "entity_name": "TechHealth Innovations",
                "id": "66d4b4f4a8e598dffe4d822b",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 8500,
                    "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b4f4a8e598dffe4d822a",
                    "entity_name": "TechHealth Innovations",
                    "id": "66d4b4f4185cf25309412b94",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b4f4a8e598dffe4d822b"
                },
                "reviews": [
                    {
                        "annual_clients": 8500,
                        "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b4f4a8e598dffe4d822a",
                        "entity_name": "TechHealth Innovations",
                        "id": "66d4b4f4185cf25309412b94",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b4f4a8e598dffe4d822b"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b4f59c12f1000aa6ab57",
            "name": "Urban Green Spaces",
            "risk": {
                "entity": "66d4b4f59c12f1000aa6ab57",
                "entity_name": "Urban Green Spaces",
                "id": "66d4b4f59c12f1000aa6ab58",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 3000,
                    "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b4f59c12f1000aa6ab57",
                    "entity_name": "Urban Green Spaces",
                    "id": "66d4b4f59c12f1000aa6ab59",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b4f59c12f1000aa6ab58"
                },
                "reviews": [
                    {
                        "annual_clients": 3000,
                        "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b4f59c12f1000aa6ab57",
                        "entity_name": "Urban Green Spaces",
                        "id": "66d4b4f59c12f1000aa6ab59",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b4f59c12f1000aa6ab58"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b50fa8e598dffe4d822c",
            "name": "TechCorp",
            "risk": {
                "entity": "66d4b50fa8e598dffe4d822c",
                "entity_name": "TechCorp",
                "id": "66d4b50fa8e598dffe4d822d",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 52000,
                    "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b50fa8e598dffe4d822c",
                    "entity_name": "TechCorp",
                    "id": "66d4b510a8e598dffe4d822e",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b50fa8e598dffe4d822d"
                },
                "reviews": [
                    {
                        "annual_clients": 50000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b50fa8e598dffe4d822c",
                        "entity_name": "TechCorp",
                        "id": "66d4b50f08d6264adba03cfb",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b50fa8e598dffe4d822d"
                    },
                    {
                        "annual_clients": 52000,
                        "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b50fa8e598dffe4d822c",
                        "entity_name": "TechCorp",
                        "id": "66d4b510a8e598dffe4d822e",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b50fa8e598dffe4d822d"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5119c12f1000aa6ab5a",
            "name": "HealthPlus",
            "risk": {
                "entity": "66d4b5119c12f1000aa6ab5a",
                "entity_name": "HealthPlus",
                "id": "66d4b5119c12f1000aa6ab5b",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 30000,
                    "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5119c12f1000aa6ab5a",
                    "entity_name": "HealthPlus",
                    "id": "66d4b511185cf25309412b95",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b5119c12f1000aa6ab5b"
                },
                "reviews": [
                    {
                        "annual_clients": 30000,
                        "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5119c12f1000aa6ab5a",
                        "entity_name": "HealthPlus",
                        "id": "66d4b511185cf25309412b95",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5119c12f1000aa6ab5b"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b511185cf25309412b96",
            "name": "EcoEnergy",
            "risk": {
                "entity": "66d4b511185cf25309412b96",
                "entity_name": "EcoEnergy",
                "id": "66d4b511185cf25309412b97",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 16000,
                    "date": "Thu, 15 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b511185cf25309412b96",
                    "entity_name": "EcoEnergy",
                    "id": "66d4b512185cf25309412b99",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b511185cf25309412b97"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b511185cf25309412b96",
                        "entity_name": "EcoEnergy",
                        "id": "66d4b512185cf25309412b98",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b511185cf25309412b97"
                    },
                    {
                        "annual_clients": 16000,
                        "date": "Thu, 15 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b511185cf25309412b96",
                        "entity_name": "EcoEnergy",
                        "id": "66d4b512185cf25309412b99",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b511185cf25309412b97"
                    }
                ],
                "score": 10
            }
        },
        {
            "id": "66d4b513185cf25309412b9a",
            "name": "FinServe",
            "risk": {
                "entity": "66d4b513185cf25309412b9a",
                "entity_name": "FinServe",
                "id": "66d4b513185cf25309412b9b",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 45000,
                    "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b513185cf25309412b9a",
                    "entity_name": "FinServe",
                    "id": "66d4b513185cf25309412b9c",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b513185cf25309412b9b"
                },
                "reviews": [
                    {
                        "annual_clients": 45000,
                        "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b513185cf25309412b9a",
                        "entity_name": "FinServe",
                        "id": "66d4b513185cf25309412b9c",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b513185cf25309412b9b"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b514a8e598dffe4d822f",
            "name": "GreenGrocers",
            "risk": {
                "entity": "66d4b514a8e598dffe4d822f",
                "entity_name": "GreenGrocers",
                "id": "66d4b514a8e598dffe4d8230",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 21000,
                    "date": "Mon, 22 May 2023 00:00:00 GMT",
                    "entity": "66d4b514a8e598dffe4d822f",
                    "entity_name": "GreenGrocers",
                    "id": "66d4b515185cf25309412b9d",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b514a8e598dffe4d8230"
                },
                "reviews": [
                    {
                        "annual_clients": 20000,
                        "date": "Sat, 18 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b514a8e598dffe4d822f",
                        "entity_name": "GreenGrocers",
                        "id": "66d4b51408d6264adba03cfc",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b514a8e598dffe4d8230"
                    },
                    {
                        "annual_clients": 21000,
                        "date": "Mon, 22 May 2023 00:00:00 GMT",
                        "entity": "66d4b514a8e598dffe4d822f",
                        "entity_name": "GreenGrocers",
                        "id": "66d4b515185cf25309412b9d",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b514a8e598dffe4d8230"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b51508d6264adba03cfd",
            "name": "AutoMotive",
            "risk": {
                "entity": "66d4b51508d6264adba03cfd",
                "entity_name": "AutoMotive",
                "id": "66d4b51508d6264adba03cfe",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 35000,
                    "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b51508d6264adba03cfd",
                    "entity_name": "AutoMotive",
                    "id": "66d4b51508d6264adba03cff",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b51508d6264adba03cfe"
                },
                "reviews": [
                    {
                        "annual_clients": 35000,
                        "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b51508d6264adba03cfd",
                        "entity_name": "AutoMotive",
                        "id": "66d4b51508d6264adba03cff",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b51508d6264adba03cfe"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b51608d6264adba03d00",
            "name": "EduLearn",
            "risk": {
                "entity": "66d4b51608d6264adba03d00",
                "entity_name": "EduLearn",
                "id": "66d4b51608d6264adba03d01",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 26000,
                    "date": "Tue, 25 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b51608d6264adba03d00",
                    "entity_name": "EduLearn",
                    "id": "66d4b517185cf25309412b9e",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b51608d6264adba03d01"
                },
                "reviews": [
                    {
                        "annual_clients": 25000,
                        "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b51608d6264adba03d00",
                        "entity_name": "EduLearn",
                        "id": "66d4b51608d6264adba03d02",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b51608d6264adba03d01"
                    },
                    {
                        "annual_clients": 26000,
                        "date": "Tue, 25 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b51608d6264adba03d00",
                        "entity_name": "EduLearn",
                        "id": "66d4b517185cf25309412b9e",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b51608d6264adba03d01"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5189c12f1000aa6ab5c",
            "name": "Foodies",
            "risk": {
                "entity": "66d4b5189c12f1000aa6ab5c",
                "entity_name": "Foodies",
                "id": "66d4b5189c12f1000aa6ab5d",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 18000,
                    "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5189c12f1000aa6ab5c",
                    "entity_name": "Foodies",
                    "id": "66d4b5189c12f1000aa6ab5e",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5189c12f1000aa6ab5d"
                },
                "reviews": [
                    {
                        "annual_clients": 18000,
                        "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5189c12f1000aa6ab5c",
                        "entity_name": "Foodies",
                        "id": "66d4b5189c12f1000aa6ab5e",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5189c12f1000aa6ab5d"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b519185cf25309412b9f",
            "name": "TravelEase",
            "risk": {
                "entity": "66d4b519185cf25309412b9f",
                "entity_name": "TravelEase",
                "id": "66d4b519185cf25309412ba0",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 42000,
                    "date": "Sat, 10 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b519185cf25309412b9f",
                    "entity_name": "TravelEase",
                    "id": "66d4b51aa8e598dffe4d8231",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b519185cf25309412ba0"
                },
                "reviews": [
                    {
                        "annual_clients": 40000,
                        "date": "Mon, 20 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b519185cf25309412b9f",
                        "entity_name": "TravelEase",
                        "id": "66d4b51908d6264adba03d03",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b519185cf25309412ba0"
                    },
                    {
                        "annual_clients": 42000,
                        "date": "Sat, 10 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b519185cf25309412b9f",
                        "entity_name": "TravelEase",
                        "id": "66d4b51aa8e598dffe4d8231",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b519185cf25309412ba0"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b51c9c12f1000aa6ab5f",
            "name": "MediCare",
            "risk": {
                "entity": "66d4b51c9c12f1000aa6ab5f",
                "entity_name": "MediCare",
                "id": "66d4b51c9c12f1000aa6ab60",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 32000,
                    "date": "Thu, 12 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b51c9c12f1000aa6ab5f",
                    "entity_name": "MediCare",
                    "id": "66d4b51ca8e598dffe4d8232",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b51c9c12f1000aa6ab60"
                },
                "reviews": [
                    {
                        "annual_clients": 32000,
                        "date": "Thu, 12 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b51c9c12f1000aa6ab5f",
                        "entity_name": "MediCare",
                        "id": "66d4b51ca8e598dffe4d8232",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b51c9c12f1000aa6ab60"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b51c185cf25309412ba1",
            "name": "RetailHub",
            "risk": {
                "entity": "66d4b51c185cf25309412ba1",
                "entity_name": "RetailHub",
                "id": "66d4b51c185cf25309412ba2",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 28000,
                    "date": "Thu, 18 May 2023 00:00:00 GMT",
                    "entity": "66d4b51c185cf25309412ba1",
                    "entity_name": "RetailHub",
                    "id": "66d4b51d185cf25309412ba3",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b51c185cf25309412ba2"
                },
                "reviews": [
                    {
                        "annual_clients": 27000,
                        "date": "Wed, 22 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b51c185cf25309412ba1",
                        "entity_name": "RetailHub",
                        "id": "66d4b51d08d6264adba03d04",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b51c185cf25309412ba2"
                    },
                    {
                        "annual_clients": 28000,
                        "date": "Thu, 18 May 2023 00:00:00 GMT",
                        "entity": "66d4b51c185cf25309412ba1",
                        "entity_name": "RetailHub",
                        "id": "66d4b51d185cf25309412ba3",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b51c185cf25309412ba2"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b51e185cf25309412ba4",
            "name": "BuildIt",
            "risk": {
                "entity": "66d4b51e185cf25309412ba4",
                "entity_name": "BuildIt",
                "id": "66d4b51e185cf25309412ba5",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 22000,
                    "date": "Wed, 08 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b51e185cf25309412ba4",
                    "entity_name": "BuildIt",
                    "id": "66d4b51ea8e598dffe4d8233",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b51e185cf25309412ba5"
                },
                "reviews": [
                    {
                        "annual_clients": 22000,
                        "date": "Wed, 08 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b51e185cf25309412ba4",
                        "entity_name": "BuildIt",
                        "id": "66d4b51ea8e598dffe4d8233",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b51e185cf25309412ba5"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b51f185cf25309412ba6",
            "name": "CleanTech",
            "risk": {
                "entity": "66d4b51f185cf25309412ba6",
                "entity_name": "CleanTech",
                "id": "66d4b51f185cf25309412ba7",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 15000,
                    "date": "Wed, 12 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b51f185cf25309412ba6",
                    "entity_name": "CleanTech",
                    "id": "66d4b51fa8e598dffe4d8235",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b51f185cf25309412ba7"
                },
                "reviews": [
                    {
                        "annual_clients": 14000,
                        "date": "Wed, 18 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b51f185cf25309412ba6",
                        "entity_name": "CleanTech",
                        "id": "66d4b51fa8e598dffe4d8234",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b51f185cf25309412ba7"
                    },
                    {
                        "annual_clients": 15000,
                        "date": "Wed, 12 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b51f185cf25309412ba6",
                        "entity_name": "CleanTech",
                        "id": "66d4b51fa8e598dffe4d8235",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b51f185cf25309412ba7"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b520185cf25309412ba8",
            "name": "LogiTrans",
            "risk": {
                "entity": "66d4b520185cf25309412ba8",
                "entity_name": "LogiTrans",
                "id": "66d4b520185cf25309412ba9",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 37000,
                    "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b520185cf25309412ba8",
                    "entity_name": "LogiTrans",
                    "id": "66d4b520185cf25309412baa",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b520185cf25309412ba9"
                },
                "reviews": [
                    {
                        "annual_clients": 37000,
                        "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b520185cf25309412ba8",
                        "entity_name": "LogiTrans",
                        "id": "66d4b520185cf25309412baa",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b520185cf25309412ba9"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b521185cf25309412bab",
            "name": "HomeStyle",
            "risk": {
                "entity": "66d4b521185cf25309412bab",
                "entity_name": "HomeStyle",
                "id": "66d4b521185cf25309412bac",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 20000,
                    "date": "Mon, 05 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b521185cf25309412bab",
                    "entity_name": "HomeStyle",
                    "id": "66d4b522185cf25309412bae",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b521185cf25309412bac"
                },
                "reviews": [
                    {
                        "annual_clients": 19000,
                        "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b521185cf25309412bab",
                        "entity_name": "HomeStyle",
                        "id": "66d4b521185cf25309412bad",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b521185cf25309412bac"
                    },
                    {
                        "annual_clients": 20000,
                        "date": "Mon, 05 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b521185cf25309412bab",
                        "entity_name": "HomeStyle",
                        "id": "66d4b522185cf25309412bae",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b521185cf25309412bac"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5229c12f1000aa6ab61",
            "name": "TechSolutions",
            "risk": {
                "entity": "66d4b5229c12f1000aa6ab61",
                "entity_name": "TechSolutions",
                "id": "66d4b5229c12f1000aa6ab62",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 33000,
                    "date": "Sun, 22 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b5229c12f1000aa6ab61",
                    "entity_name": "TechSolutions",
                    "id": "66d4b523a8e598dffe4d8236",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5229c12f1000aa6ab62"
                },
                "reviews": [
                    {
                        "annual_clients": 33000,
                        "date": "Sun, 22 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5229c12f1000aa6ab61",
                        "entity_name": "TechSolutions",
                        "id": "66d4b523a8e598dffe4d8236",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5229c12f1000aa6ab62"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b523a8e598dffe4d8237",
            "name": "BioLife",
            "risk": {
                "entity": "66d4b523a8e598dffe4d8237",
                "entity_name": "BioLife",
                "id": "66d4b523a8e598dffe4d8238",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 29000,
                    "date": "Wed, 10 May 2023 00:00:00 GMT",
                    "entity": "66d4b523a8e598dffe4d8237",
                    "entity_name": "BioLife",
                    "id": "66d4b524a8e598dffe4d823a",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b523a8e598dffe4d8238"
                },
                "reviews": [
                    {
                        "annual_clients": 28000,
                        "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b523a8e598dffe4d8237",
                        "entity_name": "BioLife",
                        "id": "66d4b523a8e598dffe4d8239",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b523a8e598dffe4d8238"
                    },
                    {
                        "annual_clients": 29000,
                        "date": "Wed, 10 May 2023 00:00:00 GMT",
                        "entity": "66d4b523a8e598dffe4d8237",
                        "entity_name": "BioLife",
                        "id": "66d4b524a8e598dffe4d823a",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b523a8e598dffe4d8238"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b525a8e598dffe4d823b",
            "name": "UrbanLiving",
            "risk": {
                "entity": "66d4b525a8e598dffe4d823b",
                "entity_name": "UrbanLiving",
                "id": "66d4b525a8e598dffe4d823c",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 24000,
                    "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b525a8e598dffe4d823b",
                    "entity_name": "UrbanLiving",
                    "id": "66d4b525185cf25309412baf",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b525a8e598dffe4d823c"
                },
                "reviews": [
                    {
                        "annual_clients": 24000,
                        "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b525a8e598dffe4d823b",
                        "entity_name": "UrbanLiving",
                        "id": "66d4b525185cf25309412baf",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b525a8e598dffe4d823c"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b526a8e598dffe4d823d",
            "name": "SmartHome",
            "risk": {
                "entity": "66d4b526a8e598dffe4d823d",
                "entity_name": "SmartHome",
                "id": "66d4b526a8e598dffe4d823e",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 22000,
                    "date": "Tue, 18 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b526a8e598dffe4d823d",
                    "entity_name": "SmartHome",
                    "id": "66d4b526185cf25309412bb1",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b526a8e598dffe4d823e"
                },
                "reviews": [
                    {
                        "annual_clients": 21000,
                        "date": "Sat, 28 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b526a8e598dffe4d823d",
                        "entity_name": "SmartHome",
                        "id": "66d4b526185cf25309412bb0",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b526a8e598dffe4d823e"
                    },
                    {
                        "annual_clients": 22000,
                        "date": "Tue, 18 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b526a8e598dffe4d823d",
                        "entity_name": "SmartHome",
                        "id": "66d4b526185cf25309412bb1",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b526a8e598dffe4d823e"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b52708d6264adba03d05",
            "name": "FitLife",
            "risk": {
                "entity": "66d4b52708d6264adba03d05",
                "entity_name": "FitLife",
                "id": "66d4b52708d6264adba03d06",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 26000,
                    "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b52708d6264adba03d05",
                    "entity_name": "FitLife",
                    "id": "66d4b52708d6264adba03d07",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b52708d6264adba03d06"
                },
                "reviews": [
                    {
                        "annual_clients": 26000,
                        "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b52708d6264adba03d05",
                        "entity_name": "FitLife",
                        "id": "66d4b52708d6264adba03d07",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b52708d6264adba03d06"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b53a08d6264adba03d08",
            "name": "Tech Innovators Inc.-158",
            "risk": {
                "entity": "66d4b53a08d6264adba03d08",
                "entity_name": "Tech Innovators Inc.-158",
                "id": "66d4b53a08d6264adba03d09",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 5200,
                    "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b53a08d6264adba03d08",
                    "entity_name": "Tech Innovators Inc.-158",
                    "id": "66d4b53b9c12f1000aa6ab63",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b53a08d6264adba03d09"
                },
                "reviews": [
                    {
                        "annual_clients": 5000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b53a08d6264adba03d08",
                        "entity_name": "Tech Innovators Inc.-158",
                        "id": "66d4b53a185cf25309412bb2",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b53a08d6264adba03d09"
                    },
                    {
                        "annual_clients": 5200,
                        "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b53a08d6264adba03d08",
                        "entity_name": "Tech Innovators Inc.-158",
                        "id": "66d4b53b9c12f1000aa6ab63",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b53a08d6264adba03d09"
                    }
                ],
                "score": 10
            }
        },
        {
            "id": "66d4b53b9c12f1000aa6ab64",
            "name": "Green Energy Solutions-49",
            "risk": {
                "entity": "66d4b53b9c12f1000aa6ab64",
                "entity_name": "Green Energy Solutions-49",
                "id": "66d4b53b9c12f1000aa6ab65",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 3000,
                    "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b53b9c12f1000aa6ab64",
                    "entity_name": "Green Energy Solutions-49",
                    "id": "66d4b53c185cf25309412bb3",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b53b9c12f1000aa6ab65"
                },
                "reviews": [
                    {
                        "annual_clients": 3000,
                        "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b53b9c12f1000aa6ab64",
                        "entity_name": "Green Energy Solutions-49",
                        "id": "66d4b53c185cf25309412bb3",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b53b9c12f1000aa6ab65"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b53c9c12f1000aa6ab66",
            "name": "Urban Mobility Corp.-415",
            "risk": {
                "entity": "66d4b53c9c12f1000aa6ab66",
                "entity_name": "Urban Mobility Corp.-415",
                "id": "66d4b53c9c12f1000aa6ab67",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 15500,
                    "date": "Sun, 18 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b53c9c12f1000aa6ab66",
                    "entity_name": "Urban Mobility Corp.-415",
                    "id": "66d4b53d185cf25309412bb4",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b53c9c12f1000aa6ab67"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b53c9c12f1000aa6ab66",
                        "entity_name": "Urban Mobility Corp.-415",
                        "id": "66d4b53d9c12f1000aa6ab68",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b53c9c12f1000aa6ab67"
                    },
                    {
                        "annual_clients": 15500,
                        "date": "Sun, 18 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b53c9c12f1000aa6ab66",
                        "entity_name": "Urban Mobility Corp.-415",
                        "id": "66d4b53d185cf25309412bb4",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b53c9c12f1000aa6ab67"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b53e185cf25309412bb5",
            "name": "HealthFirst Medical-47",
            "risk": {
                "entity": "66d4b53e185cf25309412bb5",
                "entity_name": "HealthFirst Medical-47",
                "id": "66d4b53e185cf25309412bb6",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 8000,
                    "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b53e185cf25309412bb5",
                    "entity_name": "HealthFirst Medical-47",
                    "id": "66d4b53e08d6264adba03d0a",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b53e185cf25309412bb6"
                },
                "reviews": [
                    {
                        "annual_clients": 8000,
                        "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b53e185cf25309412bb5",
                        "entity_name": "HealthFirst Medical-47",
                        "id": "66d4b53e08d6264adba03d0a",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b53e185cf25309412bb6"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b53f08d6264adba03d0b",
            "name": "EcoFriendly Products",
            "risk": {
                "entity": "66d4b53f08d6264adba03d0b",
                "entity_name": "EcoFriendly Products",
                "id": "66d4b53f08d6264adba03d0c",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 2100,
                    "date": "Wed, 10 May 2023 00:00:00 GMT",
                    "entity": "66d4b53f08d6264adba03d0b",
                    "entity_name": "EcoFriendly Products",
                    "id": "66d4b54008d6264adba03d0e",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b53f08d6264adba03d0c"
                },
                "reviews": [
                    {
                        "annual_clients": 2000,
                        "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b53f08d6264adba03d0b",
                        "entity_name": "EcoFriendly Products",
                        "id": "66d4b53f08d6264adba03d0d",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b53f08d6264adba03d0c"
                    },
                    {
                        "annual_clients": 2100,
                        "date": "Wed, 10 May 2023 00:00:00 GMT",
                        "entity": "66d4b53f08d6264adba03d0b",
                        "entity_name": "EcoFriendly Products",
                        "id": "66d4b54008d6264adba03d0e",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b53f08d6264adba03d0c"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b541185cf25309412bb7",
            "name": "NextGen Robotics",
            "risk": {
                "entity": "66d4b541185cf25309412bb7",
                "entity_name": "NextGen Robotics",
                "id": "66d4b541185cf25309412bb8",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 12000,
                    "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b541185cf25309412bb7",
                    "entity_name": "NextGen Robotics",
                    "id": "66d4b54108d6264adba03d0f",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b541185cf25309412bb8"
                },
                "reviews": [
                    {
                        "annual_clients": 12000,
                        "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b541185cf25309412bb7",
                        "entity_name": "NextGen Robotics",
                        "id": "66d4b54108d6264adba03d0f",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b541185cf25309412bb8"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b5429c12f1000aa6ab69",
            "name": "Smart Home Solutions",
            "risk": {
                "entity": "66d4b5429c12f1000aa6ab69",
                "entity_name": "Smart Home Solutions",
                "id": "66d4b5429c12f1000aa6ab6a",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 7200,
                    "date": "Sat, 15 Jul 2023 00:00:00 GMT",
                    "entity": "66d4b5429c12f1000aa6ab69",
                    "entity_name": "Smart Home Solutions",
                    "id": "66d4b542185cf25309412bb9",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5429c12f1000aa6ab6a"
                },
                "reviews": [
                    {
                        "annual_clients": 7000,
                        "date": "Sat, 01 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b5429c12f1000aa6ab69",
                        "entity_name": "Smart Home Solutions",
                        "id": "66d4b54208d6264adba03d10",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5429c12f1000aa6ab6a"
                    },
                    {
                        "annual_clients": 7200,
                        "date": "Sat, 15 Jul 2023 00:00:00 GMT",
                        "entity": "66d4b5429c12f1000aa6ab69",
                        "entity_name": "Smart Home Solutions",
                        "id": "66d4b542185cf25309412bb9",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5429c12f1000aa6ab6a"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b543a8e598dffe4d823f",
            "name": "FinTech Innovations",
            "risk": {
                "entity": "66d4b543a8e598dffe4d823f",
                "entity_name": "FinTech Innovations",
                "id": "66d4b543a8e598dffe4d8240",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 10000,
                    "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b543a8e598dffe4d823f",
                    "entity_name": "FinTech Innovations",
                    "id": "66d4b543185cf25309412bba",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b543a8e598dffe4d8240"
                },
                "reviews": [
                    {
                        "annual_clients": 10000,
                        "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b543a8e598dffe4d823f",
                        "entity_name": "FinTech Innovations",
                        "id": "66d4b543185cf25309412bba",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b543a8e598dffe4d8240"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b544a8e598dffe4d8241",
            "name": "Clean Water Initiative-4",
            "risk": {
                "entity": "66d4b544a8e598dffe4d8241",
                "entity_name": "Clean Water Initiative-4",
                "id": "66d4b544a8e598dffe4d8242",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 4000,
                    "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b544a8e598dffe4d8241",
                    "entity_name": "Clean Water Initiative-4",
                    "id": "66d4b54408d6264adba03d11",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b544a8e598dffe4d8242"
                },
                "reviews": [
                    {
                        "annual_clients": 4000,
                        "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b544a8e598dffe4d8241",
                        "entity_name": "Clean Water Initiative-4",
                        "id": "66d4b54408d6264adba03d11",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b544a8e598dffe4d8242"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b545185cf25309412bbb",
            "name": "CyberSecure Ltd.",
            "risk": {
                "entity": "66d4b545185cf25309412bbb",
                "entity_name": "CyberSecure Ltd.",
                "id": "66d4b545185cf25309412bbc",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 9000,
                    "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b545185cf25309412bbb",
                    "entity_name": "CyberSecure Ltd.",
                    "id": "66d4b545a8e598dffe4d8243",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b545185cf25309412bbc"
                },
                "reviews": [
                    {
                        "annual_clients": 9000,
                        "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b545185cf25309412bbb",
                        "entity_name": "CyberSecure Ltd.",
                        "id": "66d4b545a8e598dffe4d8243",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b545185cf25309412bbc"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b546a8e598dffe4d8244",
            "name": "BioTech Advances",
            "risk": {
                "entity": "66d4b546a8e598dffe4d8244",
                "entity_name": "BioTech Advances",
                "id": "66d4b546a8e598dffe4d8245",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 6000,
                    "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b546a8e598dffe4d8244",
                    "entity_name": "BioTech Advances",
                    "id": "66d4b546a8e598dffe4d8246",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b546a8e598dffe4d8245"
                },
                "reviews": [
                    {
                        "annual_clients": 6000,
                        "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b546a8e598dffe4d8244",
                        "entity_name": "BioTech Advances",
                        "id": "66d4b546a8e598dffe4d8246",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b546a8e598dffe4d8245"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b54708d6264adba03d12",
            "name": "Renewable Energy Corp.-480",
            "risk": {
                "entity": "66d4b54708d6264adba03d12",
                "entity_name": "Renewable Energy Corp.-480",
                "id": "66d4b54708d6264adba03d13",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 11000,
                    "date": "Fri, 05 May 2023 00:00:00 GMT",
                    "entity": "66d4b54708d6264adba03d12",
                    "entity_name": "Renewable Energy Corp.-480",
                    "id": "66d4b547185cf25309412bbd",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b54708d6264adba03d13"
                },
                "reviews": [
                    {
                        "annual_clients": 11000,
                        "date": "Fri, 05 May 2023 00:00:00 GMT",
                        "entity": "66d4b54708d6264adba03d12",
                        "entity_name": "Renewable Energy Corp.-480",
                        "id": "66d4b547185cf25309412bbd",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b54708d6264adba03d13"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b548a8e598dffe4d8247",
            "name": "Digital Health Solutions",
            "risk": {
                "entity": "66d4b548a8e598dffe4d8247",
                "entity_name": "Digital Health Solutions",
                "id": "66d4b548a8e598dffe4d8248",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 7500,
                    "date": "Thu, 01 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b548a8e598dffe4d8247",
                    "entity_name": "Digital Health Solutions",
                    "id": "66d4b548a8e598dffe4d8249",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b548a8e598dffe4d8248"
                },
                "reviews": [
                    {
                        "annual_clients": 7500,
                        "date": "Thu, 01 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b548a8e598dffe4d8247",
                        "entity_name": "Digital Health Solutions",
                        "id": "66d4b548a8e598dffe4d8249",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b548a8e598dffe4d8248"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b54908d6264adba03d14",
            "name": "AgriTech Innovations",
            "risk": {
                "entity": "66d4b54908d6264adba03d14",
                "entity_name": "AgriTech Innovations",
                "id": "66d4b54908d6264adba03d15",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 5000,
                    "date": "Mon, 10 Jul 2023 00:00:00 GMT",
                    "entity": "66d4b54908d6264adba03d14",
                    "entity_name": "AgriTech Innovations",
                    "id": "66d4b549185cf25309412bbe",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b54908d6264adba03d15"
                },
                "reviews": [
                    {
                        "annual_clients": 5000,
                        "date": "Mon, 10 Jul 2023 00:00:00 GMT",
                        "entity": "66d4b54908d6264adba03d14",
                        "entity_name": "AgriTech Innovations",
                        "id": "66d4b549185cf25309412bbe",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b54908d6264adba03d15"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b54a9c12f1000aa6ab6b",
            "name": "Urban Development Corp.",
            "risk": {
                "entity": "66d4b54a9c12f1000aa6ab6b",
                "entity_name": "Urban Development Corp.",
                "id": "66d4b54a9c12f1000aa6ab6c",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 13000,
                    "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b54a9c12f1000aa6ab6b",
                    "entity_name": "Urban Development Corp.",
                    "id": "66d4b54a9c12f1000aa6ab6d",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b54a9c12f1000aa6ab6c"
                },
                "reviews": [
                    {
                        "annual_clients": 13000,
                        "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b54a9c12f1000aa6ab6b",
                        "entity_name": "Urban Development Corp.",
                        "id": "66d4b54a9c12f1000aa6ab6d",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b54a9c12f1000aa6ab6c"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b54b08d6264adba03d16",
            "name": "Smart City Solutions-77",
            "risk": {
                "entity": "66d4b54b08d6264adba03d16",
                "entity_name": "Smart City Solutions-77",
                "id": "66d4b54b08d6264adba03d17",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 14000,
                    "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b54b08d6264adba03d16",
                    "entity_name": "Smart City Solutions-77",
                    "id": "66d4b54c185cf25309412bbf",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b54b08d6264adba03d17"
                },
                "reviews": [
                    {
                        "annual_clients": 14000,
                        "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b54b08d6264adba03d16",
                        "entity_name": "Smart City Solutions-77",
                        "id": "66d4b54c185cf25309412bbf",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b54b08d6264adba03d17"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b54d08d6264adba03d18",
            "name": "CleanTech Innovations",
            "risk": {
                "entity": "66d4b54d08d6264adba03d18",
                "entity_name": "CleanTech Innovations",
                "id": "66d4b54d08d6264adba03d19",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 6000,
                    "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b54d08d6264adba03d18",
                    "entity_name": "CleanTech Innovations",
                    "id": "66d4b54d9c12f1000aa6ab6e",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b54d08d6264adba03d19"
                },
                "reviews": [
                    {
                        "annual_clients": 6000,
                        "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b54d08d6264adba03d18",
                        "entity_name": "CleanTech Innovations",
                        "id": "66d4b54d9c12f1000aa6ab6e",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b54d08d6264adba03d19"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b54d185cf25309412bc0",
            "name": "AI Solutions Ltd.",
            "risk": {
                "entity": "66d4b54d185cf25309412bc0",
                "entity_name": "AI Solutions Ltd.",
                "id": "66d4b54d185cf25309412bc1",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 8000,
                    "date": "Tue, 25 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b54d185cf25309412bc0",
                    "entity_name": "AI Solutions Ltd.",
                    "id": "66d4b54ea8e598dffe4d824a",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b54d185cf25309412bc1"
                },
                "reviews": [
                    {
                        "annual_clients": 8000,
                        "date": "Tue, 25 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b54d185cf25309412bc0",
                        "entity_name": "AI Solutions Ltd.",
                        "id": "66d4b54ea8e598dffe4d824a",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b54d185cf25309412bc1"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b54e9c12f1000aa6ab6f",
            "name": "GreenTech Enterprises",
            "risk": {
                "entity": "66d4b54e9c12f1000aa6ab6f",
                "entity_name": "GreenTech Enterprises",
                "id": "66d4b54e9c12f1000aa6ab70",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 10000,
                    "date": "Mon, 15 May 2023 00:00:00 GMT",
                    "entity": "66d4b54e9c12f1000aa6ab6f",
                    "entity_name": "GreenTech Enterprises",
                    "id": "66d4b54e185cf25309412bc2",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b54e9c12f1000aa6ab70"
                },
                "reviews": [
                    {
                        "annual_clients": 10000,
                        "date": "Mon, 15 May 2023 00:00:00 GMT",
                        "entity": "66d4b54e9c12f1000aa6ab6f",
                        "entity_name": "GreenTech Enterprises",
                        "id": "66d4b54e185cf25309412bc2",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b54e9c12f1000aa6ab70"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b565a8e598dffe4d824b",
            "name": "TechCorp-961",
            "risk": {
                "entity": "66d4b565a8e598dffe4d824b",
                "entity_name": "TechCorp-961",
                "id": "66d4b565a8e598dffe4d824c",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 52000,
                    "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b565a8e598dffe4d824b",
                    "entity_name": "TechCorp-961",
                    "id": "66d4b566185cf25309412bc4",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b565a8e598dffe4d824c"
                },
                "reviews": [
                    {
                        "annual_clients": 50000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b565a8e598dffe4d824b",
                        "entity_name": "TechCorp-961",
                        "id": "66d4b565185cf25309412bc3",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b565a8e598dffe4d824c"
                    },
                    {
                        "annual_clients": 52000,
                        "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b565a8e598dffe4d824b",
                        "entity_name": "TechCorp-961",
                        "id": "66d4b566185cf25309412bc4",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b565a8e598dffe4d824c"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b567a8e598dffe4d824d",
            "name": "HealthPlus-371",
            "risk": {
                "entity": "66d4b567a8e598dffe4d824d",
                "entity_name": "HealthPlus-371",
                "id": "66d4b567a8e598dffe4d824e",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 30000,
                    "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b567a8e598dffe4d824d",
                    "entity_name": "HealthPlus-371",
                    "id": "66d4b567185cf25309412bc5",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b567a8e598dffe4d824e"
                },
                "reviews": [
                    {
                        "annual_clients": 30000,
                        "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b567a8e598dffe4d824d",
                        "entity_name": "HealthPlus-371",
                        "id": "66d4b567185cf25309412bc5",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b567a8e598dffe4d824e"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b56808d6264adba03d1a",
            "name": "EcoEnergy-175",
            "risk": {
                "entity": "66d4b56808d6264adba03d1a",
                "entity_name": "EcoEnergy-175",
                "id": "66d4b56808d6264adba03d1b",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 16000,
                    "date": "Thu, 15 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b56808d6264adba03d1a",
                    "entity_name": "EcoEnergy-175",
                    "id": "66d4b569185cf25309412bc6",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b56808d6264adba03d1b"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b56808d6264adba03d1a",
                        "entity_name": "EcoEnergy-175",
                        "id": "66d4b56808d6264adba03d1c",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b56808d6264adba03d1b"
                    },
                    {
                        "annual_clients": 16000,
                        "date": "Thu, 15 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b56808d6264adba03d1a",
                        "entity_name": "EcoEnergy-175",
                        "id": "66d4b569185cf25309412bc6",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b56808d6264adba03d1b"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b56a185cf25309412bc7",
            "name": "Foodies-184",
            "risk": {
                "entity": "66d4b56a185cf25309412bc7",
                "entity_name": "Foodies-184",
                "id": "66d4b56a185cf25309412bc8",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 20000,
                    "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b56a185cf25309412bc7",
                    "entity_name": "Foodies-184",
                    "id": "66d4b56a185cf25309412bc9",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b56a185cf25309412bc8"
                },
                "reviews": [
                    {
                        "annual_clients": 20000,
                        "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b56a185cf25309412bc7",
                        "entity_name": "Foodies-184",
                        "id": "66d4b56a185cf25309412bc9",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b56a185cf25309412bc8"
                    }
                ],
                "score": 10
            }
        },
        {
            "id": "66d4b56a9c12f1000aa6ab71",
            "name": "EduTech",
            "risk": {
                "entity": "66d4b56a9c12f1000aa6ab71",
                "entity_name": "EduTech",
                "id": "66d4b56a9c12f1000aa6ab72",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 10500,
                    "date": "Mon, 22 May 2023 00:00:00 GMT",
                    "entity": "66d4b56a9c12f1000aa6ab71",
                    "entity_name": "EduTech",
                    "id": "66d4b56b08d6264adba03d1d",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b56a9c12f1000aa6ab72"
                },
                "reviews": [
                    {
                        "annual_clients": 10000,
                        "date": "Sat, 18 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b56a9c12f1000aa6ab71",
                        "entity_name": "EduTech",
                        "id": "66d4b56b185cf25309412bca",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b56a9c12f1000aa6ab72"
                    },
                    {
                        "annual_clients": 10500,
                        "date": "Mon, 22 May 2023 00:00:00 GMT",
                        "entity": "66d4b56a9c12f1000aa6ab71",
                        "entity_name": "EduTech",
                        "id": "66d4b56b08d6264adba03d1d",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b56a9c12f1000aa6ab72"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b56c185cf25309412bcb",
            "name": "AutoDrive",
            "risk": {
                "entity": "66d4b56c185cf25309412bcb",
                "entity_name": "AutoDrive",
                "id": "66d4b56c185cf25309412bcc",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 25000,
                    "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b56c185cf25309412bcb",
                    "entity_name": "AutoDrive",
                    "id": "66d4b56c08d6264adba03d1e",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b56c185cf25309412bcc"
                },
                "reviews": [
                    {
                        "annual_clients": 25000,
                        "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b56c185cf25309412bcb",
                        "entity_name": "AutoDrive",
                        "id": "66d4b56c08d6264adba03d1e",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b56c185cf25309412bcc"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b56d185cf25309412bcd",
            "name": "MediCare-599",
            "risk": {
                "entity": "66d4b56d185cf25309412bcd",
                "entity_name": "MediCare-599",
                "id": "66d4b56d185cf25309412bce",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 40000,
                    "date": "Sat, 01 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b56d185cf25309412bcd",
                    "entity_name": "MediCare-599",
                    "id": "66d4b56d185cf25309412bcf",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b56d185cf25309412bce"
                },
                "reviews": [
                    {
                        "annual_clients": 40000,
                        "date": "Sat, 01 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b56d185cf25309412bcd",
                        "entity_name": "MediCare-599",
                        "id": "66d4b56d185cf25309412bcf",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b56d185cf25309412bce"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b56ea8e598dffe4d824f",
            "name": "GreenTech",
            "risk": {
                "entity": "66d4b56ea8e598dffe4d824f",
                "entity_name": "GreenTech",
                "id": "66d4b56ea8e598dffe4d8250",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 18500,
                    "date": "Tue, 25 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b56ea8e598dffe4d824f",
                    "entity_name": "GreenTech",
                    "id": "66d4b56e185cf25309412bd0",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b56ea8e598dffe4d8250"
                },
                "reviews": [
                    {
                        "annual_clients": 18000,
                        "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b56ea8e598dffe4d824f",
                        "entity_name": "GreenTech",
                        "id": "66d4b56e9c12f1000aa6ab73",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b56ea8e598dffe4d8250"
                    },
                    {
                        "annual_clients": 18500,
                        "date": "Tue, 25 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b56ea8e598dffe4d824f",
                        "entity_name": "GreenTech",
                        "id": "66d4b56e185cf25309412bd0",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b56ea8e598dffe4d8250"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b56f185cf25309412bd1",
            "name": "RetailHub-573",
            "risk": {
                "entity": "66d4b56f185cf25309412bd1",
                "entity_name": "RetailHub-573",
                "id": "66d4b56f185cf25309412bd2",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 35000,
                    "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b56f185cf25309412bd1",
                    "entity_name": "RetailHub-573",
                    "id": "66d4b570185cf25309412bd3",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b56f185cf25309412bd2"
                },
                "reviews": [
                    {
                        "annual_clients": 35000,
                        "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b56f185cf25309412bd1",
                        "entity_name": "RetailHub-573",
                        "id": "66d4b570185cf25309412bd3",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b56f185cf25309412bd2"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b570a8e598dffe4d8251",
            "name": "TravelEase-508",
            "risk": {
                "entity": "66d4b570a8e598dffe4d8251",
                "entity_name": "TravelEase-508",
                "id": "66d4b570a8e598dffe4d8252",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 22000,
                    "date": "Mon, 20 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b570a8e598dffe4d8251",
                    "entity_name": "TravelEase-508",
                    "id": "66d4b571a8e598dffe4d8253",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b570a8e598dffe4d8252"
                },
                "reviews": [
                    {
                        "annual_clients": 22000,
                        "date": "Mon, 20 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b570a8e598dffe4d8251",
                        "entity_name": "TravelEase-508",
                        "id": "66d4b571a8e598dffe4d8253",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b570a8e598dffe4d8252"
                    }
                ],
                "score": 8
            }
        },
        {
            "id": "66d4b571185cf25309412bd4",
            "name": "FinServe-693",
            "risk": {
                "entity": "66d4b571185cf25309412bd4",
                "entity_name": "FinServe-693",
                "id": "66d4b571185cf25309412bd5",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 46000,
                    "date": "Sat, 15 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b571185cf25309412bd4",
                    "entity_name": "FinServe-693",
                    "id": "66d4b572185cf25309412bd7",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b571185cf25309412bd5"
                },
                "reviews": [
                    {
                        "annual_clients": 45000,
                        "date": "Tue, 10 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b571185cf25309412bd4",
                        "entity_name": "FinServe-693",
                        "id": "66d4b572185cf25309412bd6",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b571185cf25309412bd5"
                    },
                    {
                        "annual_clients": 46000,
                        "date": "Sat, 15 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b571185cf25309412bd4",
                        "entity_name": "FinServe-693",
                        "id": "66d4b572185cf25309412bd7",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b571185cf25309412bd5"
                    }
                ],
                "score": 8
            }
        },
        {
            "id": "66d4b5739c12f1000aa6ab74",
            "name": "HomeComfort",
            "risk": {
                "entity": "66d4b5739c12f1000aa6ab74",
                "entity_name": "HomeComfort",
                "id": "66d4b5739c12f1000aa6ab75",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 12000,
                    "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5739c12f1000aa6ab74",
                    "entity_name": "HomeComfort",
                    "id": "66d4b5739c12f1000aa6ab76",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5739c12f1000aa6ab75"
                },
                "reviews": [
                    {
                        "annual_clients": 12000,
                        "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5739c12f1000aa6ab74",
                        "entity_name": "HomeComfort",
                        "id": "66d4b5739c12f1000aa6ab76",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5739c12f1000aa6ab75"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b574a8e598dffe4d8254",
            "name": "LogiTrans-970",
            "risk": {
                "entity": "66d4b574a8e598dffe4d8254",
                "entity_name": "LogiTrans-970",
                "id": "66d4b574a8e598dffe4d8255",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 27000,
                    "date": "Sat, 18 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b574a8e598dffe4d8254",
                    "entity_name": "LogiTrans-970",
                    "id": "66d4b574185cf25309412bd8",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b574a8e598dffe4d8255"
                },
                "reviews": [
                    {
                        "annual_clients": 27000,
                        "date": "Sat, 18 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b574a8e598dffe4d8254",
                        "entity_name": "LogiTrans-970",
                        "id": "66d4b574185cf25309412bd8",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b574a8e598dffe4d8255"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b575185cf25309412bd9",
            "name": "BioLife-32",
            "risk": {
                "entity": "66d4b575185cf25309412bd9",
                "entity_name": "BioLife-32",
                "id": "66d4b575185cf25309412bda",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 14500,
                    "date": "Tue, 18 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b575185cf25309412bd9",
                    "entity_name": "BioLife-32",
                    "id": "66d4b5769c12f1000aa6ab77",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b575185cf25309412bda"
                },
                "reviews": [
                    {
                        "annual_clients": 14000,
                        "date": "Sun, 22 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b575185cf25309412bd9",
                        "entity_name": "BioLife-32",
                        "id": "66d4b57508d6264adba03d1f",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b575185cf25309412bda"
                    },
                    {
                        "annual_clients": 14500,
                        "date": "Tue, 18 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b575185cf25309412bd9",
                        "entity_name": "BioLife-32",
                        "id": "66d4b5769c12f1000aa6ab77",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b575185cf25309412bda"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5769c12f1000aa6ab78",
            "name": "SafeGuard",
            "risk": {
                "entity": "66d4b5769c12f1000aa6ab78",
                "entity_name": "SafeGuard",
                "id": "66d4b5769c12f1000aa6ab79",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 32000,
                    "date": "Sun, 12 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5769c12f1000aa6ab78",
                    "entity_name": "SafeGuard",
                    "id": "66d4b576185cf25309412bdb",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b5769c12f1000aa6ab79"
                },
                "reviews": [
                    {
                        "annual_clients": 32000,
                        "date": "Sun, 12 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5769c12f1000aa6ab78",
                        "entity_name": "SafeGuard",
                        "id": "66d4b576185cf25309412bdb",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5769c12f1000aa6ab79"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b57708d6264adba03d20",
            "name": "CleanWave",
            "risk": {
                "entity": "66d4b57708d6264adba03d20",
                "entity_name": "CleanWave",
                "id": "66d4b57708d6264adba03d21",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 17000,
                    "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b57708d6264adba03d20",
                    "entity_name": "CleanWave",
                    "id": "66d4b577185cf25309412bdc",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b57708d6264adba03d21"
                },
                "reviews": [
                    {
                        "annual_clients": 17000,
                        "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b57708d6264adba03d20",
                        "entity_name": "CleanWave",
                        "id": "66d4b577185cf25309412bdc",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b57708d6264adba03d21"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b578185cf25309412bdd",
            "name": "BrightFuture",
            "risk": {
                "entity": "66d4b578185cf25309412bdd",
                "entity_name": "BrightFuture",
                "id": "66d4b578185cf25309412bde",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 11500,
                    "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b578185cf25309412bdd",
                    "entity_name": "BrightFuture",
                    "id": "66d4b579185cf25309412bdf",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b578185cf25309412bde"
                },
                "reviews": [
                    {
                        "annual_clients": 11000,
                        "date": "Thu, 05 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b578185cf25309412bdd",
                        "entity_name": "BrightFuture",
                        "id": "66d4b578a8e598dffe4d8256",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b578185cf25309412bde"
                    },
                    {
                        "annual_clients": 11500,
                        "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b578185cf25309412bdd",
                        "entity_name": "BrightFuture",
                        "id": "66d4b579185cf25309412bdf",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b578185cf25309412bde"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b57a185cf25309412be0",
            "name": "UrbanLiving-250",
            "risk": {
                "entity": "66d4b57a185cf25309412be0",
                "entity_name": "UrbanLiving-250",
                "id": "66d4b57a185cf25309412be1",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 13000,
                    "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b57a185cf25309412be0",
                    "entity_name": "UrbanLiving-250",
                    "id": "66d4b57a185cf25309412be2",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b57a185cf25309412be1"
                },
                "reviews": [
                    {
                        "annual_clients": 13000,
                        "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b57a185cf25309412be0",
                        "entity_name": "UrbanLiving-250",
                        "id": "66d4b57a185cf25309412be2",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b57a185cf25309412be1"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b57b185cf25309412be3",
            "name": "AquaPure",
            "risk": {
                "entity": "66d4b57b185cf25309412be3",
                "entity_name": "AquaPure",
                "id": "66d4b57b185cf25309412be4",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 19000,
                    "date": "Thu, 30 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b57b185cf25309412be3",
                    "entity_name": "AquaPure",
                    "id": "66d4b57b185cf25309412be5",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b57b185cf25309412be4"
                },
                "reviews": [
                    {
                        "annual_clients": 19000,
                        "date": "Thu, 30 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b57b185cf25309412be3",
                        "entity_name": "AquaPure",
                        "id": "66d4b57b185cf25309412be5",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b57b185cf25309412be4"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b57c9c12f1000aa6ab7a",
            "name": "SmartHome-107",
            "risk": {
                "entity": "66d4b57c9c12f1000aa6ab7a",
                "entity_name": "SmartHome-107",
                "id": "66d4b57c9c12f1000aa6ab7b",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 26000,
                    "date": "Wed, 05 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b57c9c12f1000aa6ab7a",
                    "entity_name": "SmartHome-107",
                    "id": "66d4b57d185cf25309412be6",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b57c9c12f1000aa6ab7b"
                },
                "reviews": [
                    {
                        "annual_clients": 25000,
                        "date": "Thu, 12 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b57c9c12f1000aa6ab7a",
                        "entity_name": "SmartHome-107",
                        "id": "66d4b57ca8e598dffe4d8257",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b57c9c12f1000aa6ab7b"
                    },
                    {
                        "annual_clients": 26000,
                        "date": "Wed, 05 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b57c9c12f1000aa6ab7a",
                        "entity_name": "SmartHome-107",
                        "id": "66d4b57d185cf25309412be6",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b57c9c12f1000aa6ab7b"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b594185cf25309412be7",
            "name": "TechCorp-996",
            "risk": {
                "entity": "66d4b594185cf25309412be7",
                "entity_name": "TechCorp-996",
                "id": "66d4b594185cf25309412be8",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 52000,
                    "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b594185cf25309412be7",
                    "entity_name": "TechCorp-996",
                    "id": "66d4b594185cf25309412bea",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b594185cf25309412be8"
                },
                "reviews": [
                    {
                        "annual_clients": 50000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b594185cf25309412be7",
                        "entity_name": "TechCorp-996",
                        "id": "66d4b594185cf25309412be9",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b594185cf25309412be8"
                    },
                    {
                        "annual_clients": 52000,
                        "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b594185cf25309412be7",
                        "entity_name": "TechCorp-996",
                        "id": "66d4b594185cf25309412bea",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b594185cf25309412be8"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5959c12f1000aa6ab7c",
            "name": "HealthPlus-126",
            "risk": {
                "entity": "66d4b5959c12f1000aa6ab7c",
                "entity_name": "HealthPlus-126",
                "id": "66d4b5959c12f1000aa6ab7d",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 30000,
                    "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5959c12f1000aa6ab7c",
                    "entity_name": "HealthPlus-126",
                    "id": "66d4b59508d6264adba03d22",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b5959c12f1000aa6ab7d"
                },
                "reviews": [
                    {
                        "annual_clients": 30000,
                        "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5959c12f1000aa6ab7c",
                        "entity_name": "HealthPlus-126",
                        "id": "66d4b59508d6264adba03d22",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5959c12f1000aa6ab7d"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b5969c12f1000aa6ab7e",
            "name": "EcoEnergy-866",
            "risk": {
                "entity": "66d4b5969c12f1000aa6ab7e",
                "entity_name": "EcoEnergy-866",
                "id": "66d4b5969c12f1000aa6ab7f",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 16000,
                    "date": "Thu, 15 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b5969c12f1000aa6ab7e",
                    "entity_name": "EcoEnergy-866",
                    "id": "66d4b59708d6264adba03d24",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5969c12f1000aa6ab7f"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5969c12f1000aa6ab7e",
                        "entity_name": "EcoEnergy-866",
                        "id": "66d4b59608d6264adba03d23",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5969c12f1000aa6ab7f"
                    },
                    {
                        "annual_clients": 16000,
                        "date": "Thu, 15 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b5969c12f1000aa6ab7e",
                        "entity_name": "EcoEnergy-866",
                        "id": "66d4b59708d6264adba03d24",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5969c12f1000aa6ab7f"
                    }
                ],
                "score": 10
            }
        },
        {
            "id": "66d4b5989c12f1000aa6ab80",
            "name": "FinServe-380",
            "risk": {
                "entity": "66d4b5989c12f1000aa6ab80",
                "entity_name": "FinServe-380",
                "id": "66d4b5989c12f1000aa6ab81",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 45000,
                    "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b5989c12f1000aa6ab80",
                    "entity_name": "FinServe-380",
                    "id": "66d4b598185cf25309412beb",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5989c12f1000aa6ab81"
                },
                "reviews": [
                    {
                        "annual_clients": 45000,
                        "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5989c12f1000aa6ab80",
                        "entity_name": "FinServe-380",
                        "id": "66d4b598185cf25309412beb",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5989c12f1000aa6ab81"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5999c12f1000aa6ab82",
            "name": "GreenGrocers-426",
            "risk": {
                "entity": "66d4b5999c12f1000aa6ab82",
                "entity_name": "GreenGrocers-426",
                "id": "66d4b5999c12f1000aa6ab83",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 21000,
                    "date": "Mon, 22 May 2023 00:00:00 GMT",
                    "entity": "66d4b5999c12f1000aa6ab82",
                    "entity_name": "GreenGrocers-426",
                    "id": "66d4b59aa8e598dffe4d8258",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5999c12f1000aa6ab83"
                },
                "reviews": [
                    {
                        "annual_clients": 20000,
                        "date": "Sat, 18 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5999c12f1000aa6ab82",
                        "entity_name": "GreenGrocers-426",
                        "id": "66d4b59908d6264adba03d25",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5999c12f1000aa6ab83"
                    },
                    {
                        "annual_clients": 21000,
                        "date": "Mon, 22 May 2023 00:00:00 GMT",
                        "entity": "66d4b5999c12f1000aa6ab82",
                        "entity_name": "GreenGrocers-426",
                        "id": "66d4b59aa8e598dffe4d8258",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5999c12f1000aa6ab83"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b59b9c12f1000aa6ab84",
            "name": "AutoMotive-905",
            "risk": {
                "entity": "66d4b59b9c12f1000aa6ab84",
                "entity_name": "AutoMotive-905",
                "id": "66d4b59b9c12f1000aa6ab85",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 35000,
                    "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b59b9c12f1000aa6ab84",
                    "entity_name": "AutoMotive-905",
                    "id": "66d4b59b9c12f1000aa6ab86",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b59b9c12f1000aa6ab85"
                },
                "reviews": [
                    {
                        "annual_clients": 35000,
                        "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b59b9c12f1000aa6ab84",
                        "entity_name": "AutoMotive-905",
                        "id": "66d4b59b9c12f1000aa6ab86",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b59b9c12f1000aa6ab85"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b59c185cf25309412bec",
            "name": "EduLearn-60",
            "risk": {
                "entity": "66d4b59c185cf25309412bec",
                "entity_name": "EduLearn-60",
                "id": "66d4b59c185cf25309412bed",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 26000,
                    "date": "Tue, 25 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b59c185cf25309412bec",
                    "entity_name": "EduLearn-60",
                    "id": "66d4b59d185cf25309412bef",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b59c185cf25309412bed"
                },
                "reviews": [
                    {
                        "annual_clients": 25000,
                        "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b59c185cf25309412bec",
                        "entity_name": "EduLearn-60",
                        "id": "66d4b59c185cf25309412bee",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b59c185cf25309412bed"
                    },
                    {
                        "annual_clients": 26000,
                        "date": "Tue, 25 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b59c185cf25309412bec",
                        "entity_name": "EduLearn-60",
                        "id": "66d4b59d185cf25309412bef",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b59c185cf25309412bed"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b59e185cf25309412bf0",
            "name": "Foodies-292",
            "risk": {
                "entity": "66d4b59e185cf25309412bf0",
                "entity_name": "Foodies-292",
                "id": "66d4b59e185cf25309412bf1",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 18000,
                    "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b59e185cf25309412bf0",
                    "entity_name": "Foodies-292",
                    "id": "66d4b59e185cf25309412bf2",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b59e185cf25309412bf1"
                },
                "reviews": [
                    {
                        "annual_clients": 18000,
                        "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b59e185cf25309412bf0",
                        "entity_name": "Foodies-292",
                        "id": "66d4b59e185cf25309412bf2",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b59e185cf25309412bf1"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b59ea8e598dffe4d8259",
            "name": "TravelEase-687",
            "risk": {
                "entity": "66d4b59ea8e598dffe4d8259",
                "entity_name": "TravelEase-687",
                "id": "66d4b59ea8e598dffe4d825a",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 42000,
                    "date": "Sat, 10 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b59ea8e598dffe4d8259",
                    "entity_name": "TravelEase-687",
                    "id": "66d4b59f185cf25309412bf3",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b59ea8e598dffe4d825a"
                },
                "reviews": [
                    {
                        "annual_clients": 40000,
                        "date": "Mon, 20 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b59ea8e598dffe4d8259",
                        "entity_name": "TravelEase-687",
                        "id": "66d4b59f08d6264adba03d26",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b59ea8e598dffe4d825a"
                    },
                    {
                        "annual_clients": 42000,
                        "date": "Sat, 10 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b59ea8e598dffe4d8259",
                        "entity_name": "TravelEase-687",
                        "id": "66d4b59f185cf25309412bf3",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b59ea8e598dffe4d825a"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5a0a8e598dffe4d825b",
            "name": "MediCare-726",
            "risk": {
                "entity": "66d4b5a0a8e598dffe4d825b",
                "entity_name": "MediCare-726",
                "id": "66d4b5a0a8e598dffe4d825c",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 32000,
                    "date": "Thu, 12 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b5a0a8e598dffe4d825b",
                    "entity_name": "MediCare-726",
                    "id": "66d4b5a008d6264adba03d27",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b5a0a8e598dffe4d825c"
                },
                "reviews": [
                    {
                        "annual_clients": 32000,
                        "date": "Thu, 12 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5a0a8e598dffe4d825b",
                        "entity_name": "MediCare-726",
                        "id": "66d4b5a008d6264adba03d27",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5a0a8e598dffe4d825c"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b5a19c12f1000aa6ab87",
            "name": "RetailHub-278",
            "risk": {
                "entity": "66d4b5a19c12f1000aa6ab87",
                "entity_name": "RetailHub-278",
                "id": "66d4b5a19c12f1000aa6ab88",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 28000,
                    "date": "Thu, 18 May 2023 00:00:00 GMT",
                    "entity": "66d4b5a19c12f1000aa6ab87",
                    "entity_name": "RetailHub-278",
                    "id": "66d4b5a2185cf25309412bf4",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5a19c12f1000aa6ab88"
                },
                "reviews": [
                    {
                        "annual_clients": 27000,
                        "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5a19c12f1000aa6ab87",
                        "entity_name": "RetailHub-278",
                        "id": "66d4b5a108d6264adba03d28",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5a19c12f1000aa6ab88"
                    },
                    {
                        "annual_clients": 28000,
                        "date": "Thu, 18 May 2023 00:00:00 GMT",
                        "entity": "66d4b5a19c12f1000aa6ab87",
                        "entity_name": "RetailHub-278",
                        "id": "66d4b5a2185cf25309412bf4",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5a19c12f1000aa6ab88"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5a3185cf25309412bf5",
            "name": "BuildIt-88",
            "risk": {
                "entity": "66d4b5a3185cf25309412bf5",
                "entity_name": "BuildIt-88",
                "id": "66d4b5a3185cf25309412bf6",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 22000,
                    "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b5a3185cf25309412bf5",
                    "entity_name": "BuildIt-88",
                    "id": "66d4b5a3185cf25309412bf7",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5a3185cf25309412bf6"
                },
                "reviews": [
                    {
                        "annual_clients": 22000,
                        "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5a3185cf25309412bf5",
                        "entity_name": "BuildIt-88",
                        "id": "66d4b5a3185cf25309412bf7",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5a3185cf25309412bf6"
                    }
                ],
                "score": 8
            }
        },
        {
            "id": "66d4b5a4185cf25309412bf8",
            "name": "LogiTrans-14",
            "risk": {
                "entity": "66d4b5a4185cf25309412bf8",
                "entity_name": "LogiTrans-14",
                "id": "66d4b5a4185cf25309412bf9",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 38000,
                    "date": "Sat, 15 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b5a4185cf25309412bf8",
                    "entity_name": "LogiTrans-14",
                    "id": "66d4b5a5185cf25309412bfb",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5a4185cf25309412bf9"
                },
                "reviews": [
                    {
                        "annual_clients": 37000,
                        "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5a4185cf25309412bf8",
                        "entity_name": "LogiTrans-14",
                        "id": "66d4b5a4185cf25309412bfa",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5a4185cf25309412bf9"
                    },
                    {
                        "annual_clients": 38000,
                        "date": "Sat, 15 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b5a4185cf25309412bf8",
                        "entity_name": "LogiTrans-14",
                        "id": "66d4b5a5185cf25309412bfb",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5a4185cf25309412bf9"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5a508d6264adba03d29",
            "name": "CleanWater",
            "risk": {
                "entity": "66d4b5a508d6264adba03d29",
                "entity_name": "CleanWater",
                "id": "66d4b5a508d6264adba03d2a",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 14000,
                    "date": "Sun, 12 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5a508d6264adba03d29",
                    "entity_name": "CleanWater",
                    "id": "66d4b5a508d6264adba03d2b",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5a508d6264adba03d2a"
                },
                "reviews": [
                    {
                        "annual_clients": 14000,
                        "date": "Sun, 12 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5a508d6264adba03d29",
                        "entity_name": "CleanWater",
                        "id": "66d4b5a508d6264adba03d2b",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5a508d6264adba03d2a"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b5a608d6264adba03d2c",
            "name": "SafeHome",
            "risk": {
                "entity": "66d4b5a608d6264adba03d2c",
                "entity_name": "SafeHome",
                "id": "66d4b5a608d6264adba03d2d",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 29000,
                    "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b5a608d6264adba03d2c",
                    "entity_name": "SafeHome",
                    "id": "66d4b5a608d6264adba03d2e",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5a608d6264adba03d2d"
                },
                "reviews": [
                    {
                        "annual_clients": 29000,
                        "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5a608d6264adba03d2c",
                        "entity_name": "SafeHome",
                        "id": "66d4b5a608d6264adba03d2e",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5a608d6264adba03d2d"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5a708d6264adba03d2f",
            "name": "BrightFuture-546",
            "risk": {
                "entity": "66d4b5a708d6264adba03d2f",
                "entity_name": "BrightFuture-546",
                "id": "66d4b5a708d6264adba03d30",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 32000,
                    "date": "Sat, 22 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b5a708d6264adba03d2f",
                    "entity_name": "BrightFuture-546",
                    "id": "66d4b5a8185cf25309412bfd",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5a708d6264adba03d30"
                },
                "reviews": [
                    {
                        "annual_clients": 31000,
                        "date": "Wed, 18 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5a708d6264adba03d2f",
                        "entity_name": "BrightFuture-546",
                        "id": "66d4b5a7185cf25309412bfc",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5a708d6264adba03d30"
                    },
                    {
                        "annual_clients": 32000,
                        "date": "Sat, 22 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b5a708d6264adba03d2f",
                        "entity_name": "BrightFuture-546",
                        "id": "66d4b5a8185cf25309412bfd",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5a708d6264adba03d30"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5a99c12f1000aa6ab89",
            "name": "UrbanLiving-898",
            "risk": {
                "entity": "66d4b5a99c12f1000aa6ab89",
                "entity_name": "UrbanLiving-898",
                "id": "66d4b5a99c12f1000aa6ab8a",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 23000,
                    "date": "Wed, 22 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5a99c12f1000aa6ab89",
                    "entity_name": "UrbanLiving-898",
                    "id": "66d4b5a99c12f1000aa6ab8b",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5a99c12f1000aa6ab8a"
                },
                "reviews": [
                    {
                        "annual_clients": 23000,
                        "date": "Wed, 22 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5a99c12f1000aa6ab89",
                        "entity_name": "UrbanLiving-898",
                        "id": "66d4b5a99c12f1000aa6ab8b",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5a99c12f1000aa6ab8a"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5aa9c12f1000aa6ab8c",
            "name": "TechSolutions-207",
            "risk": {
                "entity": "66d4b5aa9c12f1000aa6ab8c",
                "entity_name": "TechSolutions-207",
                "id": "66d4b5aa9c12f1000aa6ab8d",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 34000,
                    "date": "Sat, 18 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b5aa9c12f1000aa6ab8c",
                    "entity_name": "TechSolutions-207",
                    "id": "66d4b5aa9c12f1000aa6ab8e",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5aa9c12f1000aa6ab8d"
                },
                "reviews": [
                    {
                        "annual_clients": 34000,
                        "date": "Sat, 18 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5aa9c12f1000aa6ab8c",
                        "entity_name": "TechSolutions-207",
                        "id": "66d4b5aa9c12f1000aa6ab8e",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5aa9c12f1000aa6ab8d"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5ab9c12f1000aa6ab8f",
            "name": "WellnessWorks",
            "risk": {
                "entity": "66d4b5ab9c12f1000aa6ab8f",
                "entity_name": "WellnessWorks",
                "id": "66d4b5ab9c12f1000aa6ab90",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 29000,
                    "date": "Fri, 28 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b5ab9c12f1000aa6ab8f",
                    "entity_name": "WellnessWorks",
                    "id": "66d4b5ac08d6264adba03d31",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5ab9c12f1000aa6ab90"
                },
                "reviews": [
                    {
                        "annual_clients": 28000,
                        "date": "Sun, 22 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5ab9c12f1000aa6ab8f",
                        "entity_name": "WellnessWorks",
                        "id": "66d4b5ab9c12f1000aa6ab91",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5ab9c12f1000aa6ab90"
                    },
                    {
                        "annual_clients": 29000,
                        "date": "Fri, 28 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b5ab9c12f1000aa6ab8f",
                        "entity_name": "WellnessWorks",
                        "id": "66d4b5ac08d6264adba03d31",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5ab9c12f1000aa6ab90"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5ac08d6264adba03d32",
            "name": "SmartTech",
            "risk": {
                "entity": "66d4b5ac08d6264adba03d32",
                "entity_name": "SmartTech",
                "id": "66d4b5ac08d6264adba03d33",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 36000,
                    "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5ac08d6264adba03d32",
                    "entity_name": "SmartTech",
                    "id": "66d4b5ac185cf25309412bfe",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5ac08d6264adba03d33"
                },
                "reviews": [
                    {
                        "annual_clients": 36000,
                        "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5ac08d6264adba03d32",
                        "entity_name": "SmartTech",
                        "id": "66d4b5ac185cf25309412bfe",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5ac08d6264adba03d33"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5c108d6264adba03d34",
            "name": "Tech Innovators Inc.-366",
            "risk": {
                "entity": "66d4b5c108d6264adba03d34",
                "entity_name": "Tech Innovators Inc.-366",
                "id": "66d4b5c108d6264adba03d35",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 5200,
                    "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b5c108d6264adba03d34",
                    "entity_name": "Tech Innovators Inc.-366",
                    "id": "66d4b5c208d6264adba03d36",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5c108d6264adba03d35"
                },
                "reviews": [
                    {
                        "annual_clients": 5000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5c108d6264adba03d34",
                        "entity_name": "Tech Innovators Inc.-366",
                        "id": "66d4b5c19c12f1000aa6ab92",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5c108d6264adba03d35"
                    },
                    {
                        "annual_clients": 5200,
                        "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b5c108d6264adba03d34",
                        "entity_name": "Tech Innovators Inc.-366",
                        "id": "66d4b5c208d6264adba03d36",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5c108d6264adba03d35"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5c308d6264adba03d37",
            "name": "Green Energy Solutions-645",
            "risk": {
                "entity": "66d4b5c308d6264adba03d37",
                "entity_name": "Green Energy Solutions-645",
                "id": "66d4b5c308d6264adba03d38",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 3000,
                    "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5c308d6264adba03d37",
                    "entity_name": "Green Energy Solutions-645",
                    "id": "66d4b5c3185cf25309412bff",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b5c308d6264adba03d38"
                },
                "reviews": [
                    {
                        "annual_clients": 3000,
                        "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5c308d6264adba03d37",
                        "entity_name": "Green Energy Solutions-645",
                        "id": "66d4b5c3185cf25309412bff",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5c308d6264adba03d38"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b5c49c12f1000aa6ab93",
            "name": "Urban Mobility Corp.-640",
            "risk": {
                "entity": "66d4b5c49c12f1000aa6ab93",
                "entity_name": "Urban Mobility Corp.-640",
                "id": "66d4b5c49c12f1000aa6ab94",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 15500,
                    "date": "Sun, 18 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b5c49c12f1000aa6ab93",
                    "entity_name": "Urban Mobility Corp.-640",
                    "id": "66d4b5c4185cf25309412c00",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5c49c12f1000aa6ab94"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5c49c12f1000aa6ab93",
                        "entity_name": "Urban Mobility Corp.-640",
                        "id": "66d4b5c49c12f1000aa6ab95",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5c49c12f1000aa6ab94"
                    },
                    {
                        "annual_clients": 15500,
                        "date": "Sun, 18 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b5c49c12f1000aa6ab93",
                        "entity_name": "Urban Mobility Corp.-640",
                        "id": "66d4b5c4185cf25309412c00",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5c49c12f1000aa6ab94"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5c5185cf25309412c01",
            "name": "HealthFirst Medical-548",
            "risk": {
                "entity": "66d4b5c5185cf25309412c01",
                "entity_name": "HealthFirst Medical-548",
                "id": "66d4b5c5185cf25309412c02",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 8200,
                    "date": "Tue, 30 May 2023 00:00:00 GMT",
                    "entity": "66d4b5c5185cf25309412c01",
                    "entity_name": "HealthFirst Medical-548",
                    "id": "66d4b5c6185cf25309412c04",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5c5185cf25309412c02"
                },
                "reviews": [
                    {
                        "annual_clients": 8000,
                        "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5c5185cf25309412c01",
                        "entity_name": "HealthFirst Medical-548",
                        "id": "66d4b5c5185cf25309412c03",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5c5185cf25309412c02"
                    },
                    {
                        "annual_clients": 8200,
                        "date": "Tue, 30 May 2023 00:00:00 GMT",
                        "entity": "66d4b5c5185cf25309412c01",
                        "entity_name": "HealthFirst Medical-548",
                        "id": "66d4b5c6185cf25309412c04",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5c5185cf25309412c02"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b5c7185cf25309412c05",
            "name": "EcoFriendly Packaging-188",
            "risk": {
                "entity": "66d4b5c7185cf25309412c05",
                "entity_name": "EcoFriendly Packaging-188",
                "id": "66d4b5c7185cf25309412c06",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 4000,
                    "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5c7185cf25309412c05",
                    "entity_name": "EcoFriendly Packaging-188",
                    "id": "66d4b5c708d6264adba03d39",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b5c7185cf25309412c06"
                },
                "reviews": [
                    {
                        "annual_clients": 4000,
                        "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5c7185cf25309412c05",
                        "entity_name": "EcoFriendly Packaging-188",
                        "id": "66d4b5c708d6264adba03d39",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5c7185cf25309412c06"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b5c89c12f1000aa6ab96",
            "name": "NextGen Robotics-848",
            "risk": {
                "entity": "66d4b5c89c12f1000aa6ab96",
                "entity_name": "NextGen Robotics-848",
                "id": "66d4b5c89c12f1000aa6ab97",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 12500,
                    "date": "Mon, 10 Jul 2023 00:00:00 GMT",
                    "entity": "66d4b5c89c12f1000aa6ab96",
                    "entity_name": "NextGen Robotics-848",
                    "id": "66d4b5c9a8e598dffe4d825d",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5c89c12f1000aa6ab97"
                },
                "reviews": [
                    {
                        "annual_clients": 12000,
                        "date": "Mon, 20 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5c89c12f1000aa6ab96",
                        "entity_name": "NextGen Robotics-848",
                        "id": "66d4b5c8185cf25309412c07",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5c89c12f1000aa6ab97"
                    },
                    {
                        "annual_clients": 12500,
                        "date": "Mon, 10 Jul 2023 00:00:00 GMT",
                        "entity": "66d4b5c89c12f1000aa6ab96",
                        "entity_name": "NextGen Robotics-848",
                        "id": "66d4b5c9a8e598dffe4d825d",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5c89c12f1000aa6ab97"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5ca185cf25309412c08",
            "name": "Smart Home Solutions-610",
            "risk": {
                "entity": "66d4b5ca185cf25309412c08",
                "entity_name": "Smart Home Solutions-610",
                "id": "66d4b5ca185cf25309412c09",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 6000,
                    "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b5ca185cf25309412c08",
                    "entity_name": "Smart Home Solutions-610",
                    "id": "66d4b5ca185cf25309412c0a",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5ca185cf25309412c09"
                },
                "reviews": [
                    {
                        "annual_clients": 6000,
                        "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5ca185cf25309412c08",
                        "entity_name": "Smart Home Solutions-610",
                        "id": "66d4b5ca185cf25309412c0a",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5ca185cf25309412c09"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5cba8e598dffe4d825e",
            "name": "CleanTech Innovations-583",
            "risk": {
                "entity": "66d4b5cba8e598dffe4d825e",
                "entity_name": "CleanTech Innovations-583",
                "id": "66d4b5cba8e598dffe4d825f",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 7000,
                    "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5cba8e598dffe4d825e",
                    "entity_name": "CleanTech Innovations-583",
                    "id": "66d4b5cba8e598dffe4d8260",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b5cba8e598dffe4d825f"
                },
                "reviews": [
                    {
                        "annual_clients": 7000,
                        "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5cba8e598dffe4d825e",
                        "entity_name": "CleanTech Innovations-583",
                        "id": "66d4b5cba8e598dffe4d8260",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5cba8e598dffe4d825f"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b5cc185cf25309412c0b",
            "name": "FinTech Pioneers-708",
            "risk": {
                "entity": "66d4b5cc185cf25309412c0b",
                "entity_name": "FinTech Pioneers-708",
                "id": "66d4b5cc185cf25309412c0c",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 10500,
                    "date": "Sun, 25 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b5cc185cf25309412c0b",
                    "entity_name": "FinTech Pioneers-708",
                    "id": "66d4b5cca8e598dffe4d8262",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5cc185cf25309412c0c"
                },
                "reviews": [
                    {
                        "annual_clients": 10000,
                        "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5cc185cf25309412c0b",
                        "entity_name": "FinTech Pioneers-708",
                        "id": "66d4b5cca8e598dffe4d8261",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5cc185cf25309412c0c"
                    },
                    {
                        "annual_clients": 10500,
                        "date": "Sun, 25 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b5cc185cf25309412c0b",
                        "entity_name": "FinTech Pioneers-708",
                        "id": "66d4b5cca8e598dffe4d8262",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5cc185cf25309412c0c"
                    }
                ],
                "score": 10
            }
        },
        {
            "id": "66d4b5ce9c12f1000aa6ab98",
            "name": "BioTech Advances-289",
            "risk": {
                "entity": "66d4b5ce9c12f1000aa6ab98",
                "entity_name": "BioTech Advances-289",
                "id": "66d4b5ce9c12f1000aa6ab99",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 9000,
                    "date": "Tue, 10 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b5ce9c12f1000aa6ab98",
                    "entity_name": "BioTech Advances-289",
                    "id": "66d4b5cea8e598dffe4d8263",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5ce9c12f1000aa6ab99"
                },
                "reviews": [
                    {
                        "annual_clients": 9000,
                        "date": "Tue, 10 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5ce9c12f1000aa6ab98",
                        "entity_name": "BioTech Advances-289",
                        "id": "66d4b5cea8e598dffe4d8263",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5ce9c12f1000aa6ab99"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5cfa8e598dffe4d8264",
            "name": "Digital Marketing Pros",
            "risk": {
                "entity": "66d4b5cfa8e598dffe4d8264",
                "entity_name": "Digital Marketing Pros",
                "id": "66d4b5cfa8e598dffe4d8265",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 11500,
                    "date": "Mon, 15 May 2023 00:00:00 GMT",
                    "entity": "66d4b5cfa8e598dffe4d8264",
                    "entity_name": "Digital Marketing Pros",
                    "id": "66d4b5cf9c12f1000aa6ab9a",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5cfa8e598dffe4d8265"
                },
                "reviews": [
                    {
                        "annual_clients": 11000,
                        "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5cfa8e598dffe4d8264",
                        "entity_name": "Digital Marketing Pros",
                        "id": "66d4b5cfa8e598dffe4d8266",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5cfa8e598dffe4d8265"
                    },
                    {
                        "annual_clients": 11500,
                        "date": "Mon, 15 May 2023 00:00:00 GMT",
                        "entity": "66d4b5cfa8e598dffe4d8264",
                        "entity_name": "Digital Marketing Pros",
                        "id": "66d4b5cf9c12f1000aa6ab9a",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5cfa8e598dffe4d8265"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b5d09c12f1000aa6ab9b",
            "name": "AI Solutions Ltd.-524",
            "risk": {
                "entity": "66d4b5d09c12f1000aa6ab9b",
                "entity_name": "AI Solutions Ltd.-524",
                "id": "66d4b5d09c12f1000aa6ab9c",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 13000,
                    "date": "Wed, 01 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b5d09c12f1000aa6ab9b",
                    "entity_name": "AI Solutions Ltd.-524",
                    "id": "66d4b5d008d6264adba03d3a",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5d09c12f1000aa6ab9c"
                },
                "reviews": [
                    {
                        "annual_clients": 13000,
                        "date": "Wed, 01 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5d09c12f1000aa6ab9b",
                        "entity_name": "AI Solutions Ltd.-524",
                        "id": "66d4b5d008d6264adba03d3a",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5d09c12f1000aa6ab9c"
                    }
                ],
                "score": 8
            }
        },
        {
            "id": "66d4b5d19c12f1000aa6ab9d",
            "name": "Renewable Energy Corp.-426",
            "risk": {
                "entity": "66d4b5d19c12f1000aa6ab9d",
                "entity_name": "Renewable Energy Corp.-426",
                "id": "66d4b5d19c12f1000aa6ab9e",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 14000,
                    "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b5d19c12f1000aa6ab9d",
                    "entity_name": "Renewable Energy Corp.-426",
                    "id": "66d4b5d1a8e598dffe4d8267",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b5d19c12f1000aa6ab9e"
                },
                "reviews": [
                    {
                        "annual_clients": 14000,
                        "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b5d19c12f1000aa6ab9d",
                        "entity_name": "Renewable Energy Corp.-426",
                        "id": "66d4b5d1a8e598dffe4d8267",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5d19c12f1000aa6ab9e"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b5d3185cf25309412c0d",
            "name": "CyberSecurity Experts",
            "risk": {
                "entity": "66d4b5d3185cf25309412c0d",
                "entity_name": "CyberSecurity Experts",
                "id": "66d4b5d3185cf25309412c0e",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 16500,
                    "date": "Sun, 30 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b5d3185cf25309412c0d",
                    "entity_name": "CyberSecurity Experts",
                    "id": "66d4b5d3185cf25309412c0f",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5d3185cf25309412c0e"
                },
                "reviews": [
                    {
                        "annual_clients": 16000,
                        "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5d3185cf25309412c0d",
                        "entity_name": "CyberSecurity Experts",
                        "id": "66d4b5d308d6264adba03d3b",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5d3185cf25309412c0e"
                    },
                    {
                        "annual_clients": 16500,
                        "date": "Sun, 30 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b5d3185cf25309412c0d",
                        "entity_name": "CyberSecurity Experts",
                        "id": "66d4b5d3185cf25309412c0f",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5d3185cf25309412c0e"
                    }
                ],
                "score": 10
            }
        },
        {
            "id": "66d4b5d4185cf25309412c10",
            "name": "E-Commerce Giants",
            "risk": {
                "entity": "66d4b5d4185cf25309412c10",
                "entity_name": "E-Commerce Giants",
                "id": "66d4b5d4185cf25309412c11",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 20000,
                    "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5d4185cf25309412c10",
                    "entity_name": "E-Commerce Giants",
                    "id": "66d4b5d4185cf25309412c12",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5d4185cf25309412c11"
                },
                "reviews": [
                    {
                        "annual_clients": 20000,
                        "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5d4185cf25309412c10",
                        "entity_name": "E-Commerce Giants",
                        "id": "66d4b5d4185cf25309412c12",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5d4185cf25309412c11"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b5d5a8e598dffe4d8268",
            "name": "Logistics Masters",
            "risk": {
                "entity": "66d4b5d5a8e598dffe4d8268",
                "entity_name": "Logistics Masters",
                "id": "66d4b5d5a8e598dffe4d8269",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 18500,
                    "date": "Wed, 05 Jul 2023 00:00:00 GMT",
                    "entity": "66d4b5d5a8e598dffe4d8268",
                    "entity_name": "Logistics Masters",
                    "id": "66d4b5d6a8e598dffe4d826a",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5d5a8e598dffe4d8269"
                },
                "reviews": [
                    {
                        "annual_clients": 18000,
                        "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5d5a8e598dffe4d8268",
                        "entity_name": "Logistics Masters",
                        "id": "66d4b5d5185cf25309412c13",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5d5a8e598dffe4d8269"
                    },
                    {
                        "annual_clients": 18500,
                        "date": "Wed, 05 Jul 2023 00:00:00 GMT",
                        "entity": "66d4b5d5a8e598dffe4d8268",
                        "entity_name": "Logistics Masters",
                        "id": "66d4b5d6a8e598dffe4d826a",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5d5a8e598dffe4d8269"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5d6185cf25309412c14",
            "name": "Cloud Computing Inc.",
            "risk": {
                "entity": "66d4b5d6185cf25309412c14",
                "entity_name": "Cloud Computing Inc.",
                "id": "66d4b5d6185cf25309412c15",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 22000,
                    "date": "Thu, 05 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b5d6185cf25309412c14",
                    "entity_name": "Cloud Computing Inc.",
                    "id": "66d4b5d708d6264adba03d3c",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5d6185cf25309412c15"
                },
                "reviews": [
                    {
                        "annual_clients": 22000,
                        "date": "Thu, 05 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5d6185cf25309412c14",
                        "entity_name": "Cloud Computing Inc.",
                        "id": "66d4b5d708d6264adba03d3c",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5d6185cf25309412c15"
                    }
                ],
                "score": 8
            }
        },
        {
            "id": "66d4b5d7185cf25309412c16",
            "name": "Retail Innovators",
            "risk": {
                "entity": "66d4b5d7185cf25309412c16",
                "entity_name": "Retail Innovators",
                "id": "66d4b5d7185cf25309412c17",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 24000,
                    "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5d7185cf25309412c16",
                    "entity_name": "Retail Innovators",
                    "id": "66d4b5d7a8e598dffe4d826b",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b5d7185cf25309412c17"
                },
                "reviews": [
                    {
                        "annual_clients": 24000,
                        "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5d7185cf25309412c16",
                        "entity_name": "Retail Innovators",
                        "id": "66d4b5d7a8e598dffe4d826b",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5d7185cf25309412c17"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b5d89c12f1000aa6ab9f",
            "name": "TravelTech Solutions",
            "risk": {
                "entity": "66d4b5d89c12f1000aa6ab9f",
                "entity_name": "TravelTech Solutions",
                "id": "66d4b5d89c12f1000aa6aba0",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 26500,
                    "date": "Thu, 15 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b5d89c12f1000aa6ab9f",
                    "entity_name": "TravelTech Solutions",
                    "id": "66d4b5d99c12f1000aa6aba2",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5d89c12f1000aa6aba0"
                },
                "reviews": [
                    {
                        "annual_clients": 26000,
                        "date": "Fri, 10 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5d89c12f1000aa6ab9f",
                        "entity_name": "TravelTech Solutions",
                        "id": "66d4b5d89c12f1000aa6aba1",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5d89c12f1000aa6aba0"
                    },
                    {
                        "annual_clients": 26500,
                        "date": "Thu, 15 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b5d89c12f1000aa6ab9f",
                        "entity_name": "TravelTech Solutions",
                        "id": "66d4b5d99c12f1000aa6aba2",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5d89c12f1000aa6aba0"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b5da9c12f1000aa6aba3",
            "name": "FoodTech Innovations",
            "risk": {
                "entity": "66d4b5da9c12f1000aa6aba3",
                "entity_name": "FoodTech Innovations",
                "id": "66d4b5da9c12f1000aa6aba4",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 28000,
                    "date": "Wed, 18 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b5da9c12f1000aa6aba3",
                    "entity_name": "FoodTech Innovations",
                    "id": "66d4b5da08d6264adba03d3d",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5da9c12f1000aa6aba4"
                },
                "reviews": [
                    {
                        "annual_clients": 28000,
                        "date": "Wed, 18 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5da9c12f1000aa6aba3",
                        "entity_name": "FoodTech Innovations",
                        "id": "66d4b5da08d6264adba03d3d",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5da9c12f1000aa6aba4"
                    }
                ],
                "score": 8
            }
        },
        {
            "id": "66d4b5ec08d6264adba03d3e",
            "name": "TechCorp-736",
            "risk": {
                "entity": "66d4b5ec08d6264adba03d3e",
                "entity_name": "TechCorp-736",
                "id": "66d4b5ec08d6264adba03d3f",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 52000,
                    "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b5ec08d6264adba03d3e",
                    "entity_name": "TechCorp-736",
                    "id": "66d4b5ed9c12f1000aa6aba5",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5ec08d6264adba03d3f"
                },
                "reviews": [
                    {
                        "annual_clients": 50000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5ec08d6264adba03d3e",
                        "entity_name": "TechCorp-736",
                        "id": "66d4b5eca8e598dffe4d826c",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5ec08d6264adba03d3f"
                    },
                    {
                        "annual_clients": 52000,
                        "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b5ec08d6264adba03d3e",
                        "entity_name": "TechCorp-736",
                        "id": "66d4b5ed9c12f1000aa6aba5",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5ec08d6264adba03d3f"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5ee185cf25309412c18",
            "name": "HealthPlus-674",
            "risk": {
                "entity": "66d4b5ee185cf25309412c18",
                "entity_name": "HealthPlus-674",
                "id": "66d4b5ee185cf25309412c19",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 31000,
                    "date": "Thu, 18 May 2023 00:00:00 GMT",
                    "entity": "66d4b5ee185cf25309412c18",
                    "entity_name": "HealthPlus-674",
                    "id": "66d4b5ef185cf25309412c1b",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5ee185cf25309412c19"
                },
                "reviews": [
                    {
                        "annual_clients": 30000,
                        "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5ee185cf25309412c18",
                        "entity_name": "HealthPlus-674",
                        "id": "66d4b5ee185cf25309412c1a",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5ee185cf25309412c19"
                    },
                    {
                        "annual_clients": 31000,
                        "date": "Thu, 18 May 2023 00:00:00 GMT",
                        "entity": "66d4b5ee185cf25309412c18",
                        "entity_name": "HealthPlus-674",
                        "id": "66d4b5ef185cf25309412c1b",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5ee185cf25309412c19"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5f008d6264adba03d40",
            "name": "EcoEnergy-364",
            "risk": {
                "entity": "66d4b5f008d6264adba03d40",
                "entity_name": "EcoEnergy-364",
                "id": "66d4b5f008d6264adba03d41",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 15000,
                    "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b5f008d6264adba03d40",
                    "entity_name": "EcoEnergy-364",
                    "id": "66d4b5f008d6264adba03d42",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5f008d6264adba03d41"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5f008d6264adba03d40",
                        "entity_name": "EcoEnergy-364",
                        "id": "66d4b5f008d6264adba03d42",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5f008d6264adba03d41"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5f1a8e598dffe4d826d",
            "name": "FinServe-913",
            "risk": {
                "entity": "66d4b5f1a8e598dffe4d826d",
                "entity_name": "FinServe-913",
                "id": "66d4b5f1a8e598dffe4d826e",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 46000,
                    "date": "Sun, 30 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b5f1a8e598dffe4d826d",
                    "entity_name": "FinServe-913",
                    "id": "66d4b5f208d6264adba03d43",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5f1a8e598dffe4d826e"
                },
                "reviews": [
                    {
                        "annual_clients": 45000,
                        "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5f1a8e598dffe4d826d",
                        "entity_name": "FinServe-913",
                        "id": "66d4b5f1a8e598dffe4d826f",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5f1a8e598dffe4d826e"
                    },
                    {
                        "annual_clients": 46000,
                        "date": "Sun, 30 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b5f1a8e598dffe4d826d",
                        "entity_name": "FinServe-913",
                        "id": "66d4b5f208d6264adba03d43",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5f1a8e598dffe4d826e"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5f308d6264adba03d44",
            "name": "EduTech-906",
            "risk": {
                "entity": "66d4b5f308d6264adba03d44",
                "entity_name": "EduTech-906",
                "id": "66d4b5f308d6264adba03d45",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 20000,
                    "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5f308d6264adba03d44",
                    "entity_name": "EduTech-906",
                    "id": "66d4b5f3185cf25309412c1c",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b5f308d6264adba03d45"
                },
                "reviews": [
                    {
                        "annual_clients": 20000,
                        "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5f308d6264adba03d44",
                        "entity_name": "EduTech-906",
                        "id": "66d4b5f3185cf25309412c1c",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5f308d6264adba03d45"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b5f4a8e598dffe4d8270",
            "name": "RetailGiant",
            "risk": {
                "entity": "66d4b5f4a8e598dffe4d8270",
                "entity_name": "RetailGiant",
                "id": "66d4b5f4a8e598dffe4d8271",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 102000,
                    "date": "Sun, 25 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b5f4a8e598dffe4d8270",
                    "entity_name": "RetailGiant",
                    "id": "66d4b5f4185cf25309412c1d",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5f4a8e598dffe4d8271"
                },
                "reviews": [
                    {
                        "annual_clients": 100000,
                        "date": "Mon, 20 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5f4a8e598dffe4d8270",
                        "entity_name": "RetailGiant",
                        "id": "66d4b5f408d6264adba03d46",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5f4a8e598dffe4d8271"
                    },
                    {
                        "annual_clients": 102000,
                        "date": "Sun, 25 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b5f4a8e598dffe4d8270",
                        "entity_name": "RetailGiant",
                        "id": "66d4b5f4185cf25309412c1d",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5f4a8e598dffe4d8271"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5f59c12f1000aa6aba6",
            "name": "Foodies-195",
            "risk": {
                "entity": "66d4b5f59c12f1000aa6aba6",
                "entity_name": "Foodies-195",
                "id": "66d4b5f59c12f1000aa6aba7",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 25000,
                    "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b5f59c12f1000aa6aba6",
                    "entity_name": "Foodies-195",
                    "id": "66d4b5f5a8e598dffe4d8272",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5f59c12f1000aa6aba7"
                },
                "reviews": [
                    {
                        "annual_clients": 25000,
                        "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5f59c12f1000aa6aba6",
                        "entity_name": "Foodies-195",
                        "id": "66d4b5f5a8e598dffe4d8272",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5f59c12f1000aa6aba7"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5f6185cf25309412c1e",
            "name": "AutoMakers",
            "risk": {
                "entity": "66d4b5f6185cf25309412c1e",
                "entity_name": "AutoMakers",
                "id": "66d4b5f6185cf25309412c1f",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 41000,
                    "date": "Tue, 30 May 2023 00:00:00 GMT",
                    "entity": "66d4b5f6185cf25309412c1e",
                    "entity_name": "AutoMakers",
                    "id": "66d4b5f7185cf25309412c20",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5f6185cf25309412c1f"
                },
                "reviews": [
                    {
                        "annual_clients": 40000,
                        "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5f6185cf25309412c1e",
                        "entity_name": "AutoMakers",
                        "id": "66d4b5f69c12f1000aa6aba8",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5f6185cf25309412c1f"
                    },
                    {
                        "annual_clients": 41000,
                        "date": "Tue, 30 May 2023 00:00:00 GMT",
                        "entity": "66d4b5f6185cf25309412c1e",
                        "entity_name": "AutoMakers",
                        "id": "66d4b5f7185cf25309412c20",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5f6185cf25309412c1f"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5f89c12f1000aa6aba9",
            "name": "TravelCo",
            "risk": {
                "entity": "66d4b5f89c12f1000aa6aba9",
                "entity_name": "TravelCo",
                "id": "66d4b5f89c12f1000aa6abaa",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 35000,
                    "date": "Fri, 10 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b5f89c12f1000aa6aba9",
                    "entity_name": "TravelCo",
                    "id": "66d4b5f8185cf25309412c21",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5f89c12f1000aa6abaa"
                },
                "reviews": [
                    {
                        "annual_clients": 35000,
                        "date": "Fri, 10 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5f89c12f1000aa6aba9",
                        "entity_name": "TravelCo",
                        "id": "66d4b5f8185cf25309412c21",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5f89c12f1000aa6abaa"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5f9a8e598dffe4d8273",
            "name": "GreenTech-763",
            "risk": {
                "entity": "66d4b5f9a8e598dffe4d8273",
                "entity_name": "GreenTech-763",
                "id": "66d4b5f9a8e598dffe4d8274",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 18500,
                    "date": "Sat, 15 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b5f9a8e598dffe4d8273",
                    "entity_name": "GreenTech-763",
                    "id": "66d4b5fa185cf25309412c23",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5f9a8e598dffe4d8274"
                },
                "reviews": [
                    {
                        "annual_clients": 18000,
                        "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5f9a8e598dffe4d8273",
                        "entity_name": "GreenTech-763",
                        "id": "66d4b5f9185cf25309412c22",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5f9a8e598dffe4d8274"
                    },
                    {
                        "annual_clients": 18500,
                        "date": "Sat, 15 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b5f9a8e598dffe4d8273",
                        "entity_name": "GreenTech-763",
                        "id": "66d4b5fa185cf25309412c23",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5f9a8e598dffe4d8274"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5fa08d6264adba03d47",
            "name": "MediCare-647",
            "risk": {
                "entity": "66d4b5fa08d6264adba03d47",
                "entity_name": "MediCare-647",
                "id": "66d4b5fa08d6264adba03d48",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 22000,
                    "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5fa08d6264adba03d47",
                    "entity_name": "MediCare-647",
                    "id": "66d4b5fb185cf25309412c24",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b5fa08d6264adba03d48"
                },
                "reviews": [
                    {
                        "annual_clients": 22000,
                        "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5fa08d6264adba03d47",
                        "entity_name": "MediCare-647",
                        "id": "66d4b5fb185cf25309412c24",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b5fa08d6264adba03d48"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b5fb185cf25309412c25",
            "name": "LogiTrans-477",
            "risk": {
                "entity": "66d4b5fb185cf25309412c25",
                "entity_name": "LogiTrans-477",
                "id": "66d4b5fb185cf25309412c26",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 31000,
                    "date": "Fri, 30 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b5fb185cf25309412c25",
                    "entity_name": "LogiTrans-477",
                    "id": "66d4b5fc185cf25309412c27",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5fb185cf25309412c26"
                },
                "reviews": [
                    {
                        "annual_clients": 30000,
                        "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5fb185cf25309412c25",
                        "entity_name": "LogiTrans-477",
                        "id": "66d4b5fc08d6264adba03d49",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5fb185cf25309412c26"
                    },
                    {
                        "annual_clients": 31000,
                        "date": "Fri, 30 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b5fb185cf25309412c25",
                        "entity_name": "LogiTrans-477",
                        "id": "66d4b5fc185cf25309412c27",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5fb185cf25309412c26"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5fd185cf25309412c28",
            "name": "BioPharma",
            "risk": {
                "entity": "66d4b5fd185cf25309412c28",
                "entity_name": "BioPharma",
                "id": "66d4b5fd185cf25309412c29",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 27000,
                    "date": "Tue, 10 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b5fd185cf25309412c28",
                    "entity_name": "BioPharma",
                    "id": "66d4b5fd185cf25309412c2a",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5fd185cf25309412c29"
                },
                "reviews": [
                    {
                        "annual_clients": 27000,
                        "date": "Tue, 10 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b5fd185cf25309412c28",
                        "entity_name": "BioPharma",
                        "id": "66d4b5fd185cf25309412c2a",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5fd185cf25309412c29"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b5fe08d6264adba03d4a",
            "name": "CleanWater-646",
            "risk": {
                "entity": "66d4b5fe08d6264adba03d4a",
                "entity_name": "CleanWater-646",
                "id": "66d4b5fe08d6264adba03d4b",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 12000,
                    "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b5fe08d6264adba03d4a",
                    "entity_name": "CleanWater-646",
                    "id": "66d4b5fe185cf25309412c2b",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5fe08d6264adba03d4b"
                },
                "reviews": [
                    {
                        "annual_clients": 12000,
                        "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b5fe08d6264adba03d4a",
                        "entity_name": "CleanWater-646",
                        "id": "66d4b5fe185cf25309412c2b",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5fe08d6264adba03d4b"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b5ff08d6264adba03d4c",
            "name": "AgriGoods",
            "risk": {
                "entity": "66d4b5ff08d6264adba03d4c",
                "entity_name": "AgriGoods",
                "id": "66d4b5ff08d6264adba03d4d",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 16000,
                    "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b5ff08d6264adba03d4c",
                    "entity_name": "AgriGoods",
                    "id": "66d4b5ff185cf25309412c2c",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b5ff08d6264adba03d4d"
                },
                "reviews": [
                    {
                        "annual_clients": 16000,
                        "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b5ff08d6264adba03d4c",
                        "entity_name": "AgriGoods",
                        "id": "66d4b5ff185cf25309412c2c",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b5ff08d6264adba03d4d"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b60008d6264adba03d4e",
            "name": "UrbanDev",
            "risk": {
                "entity": "66d4b60008d6264adba03d4e",
                "entity_name": "UrbanDev",
                "id": "66d4b60008d6264adba03d4f",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 36000,
                    "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b60008d6264adba03d4e",
                    "entity_name": "UrbanDev",
                    "id": "66d4b60108d6264adba03d51",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b60008d6264adba03d4f"
                },
                "reviews": [
                    {
                        "annual_clients": 35000,
                        "date": "Thu, 05 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b60008d6264adba03d4e",
                        "entity_name": "UrbanDev",
                        "id": "66d4b60008d6264adba03d50",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b60008d6264adba03d4f"
                    },
                    {
                        "annual_clients": 36000,
                        "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b60008d6264adba03d4e",
                        "entity_name": "UrbanDev",
                        "id": "66d4b60108d6264adba03d51",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b60008d6264adba03d4f"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b60208d6264adba03d52",
            "name": "TechSolutions-465",
            "risk": {
                "entity": "66d4b60208d6264adba03d52",
                "entity_name": "TechSolutions-465",
                "id": "66d4b60208d6264adba03d53",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 40000,
                    "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b60208d6264adba03d52",
                    "entity_name": "TechSolutions-465",
                    "id": "66d4b602185cf25309412c2d",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b60208d6264adba03d53"
                },
                "reviews": [
                    {
                        "annual_clients": 40000,
                        "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b60208d6264adba03d52",
                        "entity_name": "TechSolutions-465",
                        "id": "66d4b602185cf25309412c2d",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b60208d6264adba03d53"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b6029c12f1000aa6abab",
            "name": "HomeEssentials",
            "risk": {
                "entity": "66d4b6029c12f1000aa6abab",
                "entity_name": "HomeEssentials",
                "id": "66d4b6029c12f1000aa6abac",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 28000,
                    "date": "Thu, 30 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b6029c12f1000aa6abab",
                    "entity_name": "HomeEssentials",
                    "id": "66d4b60308d6264adba03d54",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b6029c12f1000aa6abac"
                },
                "reviews": [
                    {
                        "annual_clients": 28000,
                        "date": "Thu, 30 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b6029c12f1000aa6abab",
                        "entity_name": "HomeEssentials",
                        "id": "66d4b60308d6264adba03d54",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b6029c12f1000aa6abac"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b603a8e598dffe4d8275",
            "name": "AutoParts",
            "risk": {
                "entity": "66d4b603a8e598dffe4d8275",
                "entity_name": "AutoParts",
                "id": "66d4b603a8e598dffe4d8276",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 33000,
                    "date": "Sun, 30 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b603a8e598dffe4d8275",
                    "entity_name": "AutoParts",
                    "id": "66d4b60408d6264adba03d55",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b603a8e598dffe4d8276"
                },
                "reviews": [
                    {
                        "annual_clients": 32000,
                        "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b603a8e598dffe4d8275",
                        "entity_name": "AutoParts",
                        "id": "66d4b603185cf25309412c2e",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b603a8e598dffe4d8276"
                    },
                    {
                        "annual_clients": 33000,
                        "date": "Sun, 30 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b603a8e598dffe4d8275",
                        "entity_name": "AutoParts",
                        "id": "66d4b60408d6264adba03d55",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b603a8e598dffe4d8276"
                    }
                ],
                "score": 10
            }
        },
        {
            "id": "66d4b605185cf25309412c2f",
            "name": "SmartHomes",
            "risk": {
                "entity": "66d4b605185cf25309412c2f",
                "entity_name": "SmartHomes",
                "id": "66d4b605185cf25309412c30",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 21000,
                    "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b605185cf25309412c2f",
                    "entity_name": "SmartHomes",
                    "id": "66d4b605185cf25309412c31",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b605185cf25309412c30"
                },
                "reviews": [
                    {
                        "annual_clients": 21000,
                        "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b605185cf25309412c2f",
                        "entity_name": "SmartHomes",
                        "id": "66d4b605185cf25309412c31",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b605185cf25309412c30"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b6189c12f1000aa6abad",
            "name": "TechCorp-913",
            "risk": {
                "entity": "66d4b6189c12f1000aa6abad",
                "entity_name": "TechCorp-913",
                "id": "66d4b6189c12f1000aa6abae",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 52000,
                    "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b6189c12f1000aa6abad",
                    "entity_name": "TechCorp-913",
                    "id": "66d4b619a8e598dffe4d8277",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b6189c12f1000aa6abae"
                },
                "reviews": [
                    {
                        "annual_clients": 50000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b6189c12f1000aa6abad",
                        "entity_name": "TechCorp-913",
                        "id": "66d4b61908d6264adba03d56",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b6189c12f1000aa6abae"
                    },
                    {
                        "annual_clients": 52000,
                        "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b6189c12f1000aa6abad",
                        "entity_name": "TechCorp-913",
                        "id": "66d4b619a8e598dffe4d8277",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b6189c12f1000aa6abae"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b61a08d6264adba03d57",
            "name": "HealthPlus-785",
            "risk": {
                "entity": "66d4b61a08d6264adba03d57",
                "entity_name": "HealthPlus-785",
                "id": "66d4b61a08d6264adba03d58",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 31000,
                    "date": "Thu, 18 May 2023 00:00:00 GMT",
                    "entity": "66d4b61a08d6264adba03d57",
                    "entity_name": "HealthPlus-785",
                    "id": "66d4b61b185cf25309412c32",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b61a08d6264adba03d58"
                },
                "reviews": [
                    {
                        "annual_clients": 30000,
                        "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b61a08d6264adba03d57",
                        "entity_name": "HealthPlus-785",
                        "id": "66d4b61aa8e598dffe4d8278",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b61a08d6264adba03d58"
                    },
                    {
                        "annual_clients": 31000,
                        "date": "Thu, 18 May 2023 00:00:00 GMT",
                        "entity": "66d4b61a08d6264adba03d57",
                        "entity_name": "HealthPlus-785",
                        "id": "66d4b61b185cf25309412c32",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b61a08d6264adba03d58"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b61c185cf25309412c33",
            "name": "EcoEnergy-822",
            "risk": {
                "entity": "66d4b61c185cf25309412c33",
                "entity_name": "EcoEnergy-822",
                "id": "66d4b61c185cf25309412c34",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 15000,
                    "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b61c185cf25309412c33",
                    "entity_name": "EcoEnergy-822",
                    "id": "66d4b61c185cf25309412c35",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b61c185cf25309412c34"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b61c185cf25309412c33",
                        "entity_name": "EcoEnergy-822",
                        "id": "66d4b61c185cf25309412c35",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b61c185cf25309412c34"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b61da8e598dffe4d8279",
            "name": "FinServe-463",
            "risk": {
                "entity": "66d4b61da8e598dffe4d8279",
                "entity_name": "FinServe-463",
                "id": "66d4b61da8e598dffe4d827a",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 46000,
                    "date": "Sun, 30 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b61da8e598dffe4d8279",
                    "entity_name": "FinServe-463",
                    "id": "66d4b61d185cf25309412c36",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b61da8e598dffe4d827a"
                },
                "reviews": [
                    {
                        "annual_clients": 45000,
                        "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b61da8e598dffe4d8279",
                        "entity_name": "FinServe-463",
                        "id": "66d4b61da8e598dffe4d827b",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b61da8e598dffe4d827a"
                    },
                    {
                        "annual_clients": 46000,
                        "date": "Sun, 30 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b61da8e598dffe4d8279",
                        "entity_name": "FinServe-463",
                        "id": "66d4b61d185cf25309412c36",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b61da8e598dffe4d827a"
                    }
                ],
                "score": 8
            }
        },
        {
            "id": "66d4b61f185cf25309412c37",
            "name": "EduTech-744",
            "risk": {
                "entity": "66d4b61f185cf25309412c37",
                "entity_name": "EduTech-744",
                "id": "66d4b61f185cf25309412c38",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 20000,
                    "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b61f185cf25309412c37",
                    "entity_name": "EduTech-744",
                    "id": "66d4b61f185cf25309412c39",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b61f185cf25309412c38"
                },
                "reviews": [
                    {
                        "annual_clients": 20000,
                        "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b61f185cf25309412c37",
                        "entity_name": "EduTech-744",
                        "id": "66d4b61f185cf25309412c39",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b61f185cf25309412c38"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b6209c12f1000aa6abaf",
            "name": "RetailGiant-524",
            "risk": {
                "entity": "66d4b6209c12f1000aa6abaf",
                "entity_name": "RetailGiant-524",
                "id": "66d4b6209c12f1000aa6abb0",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 102000,
                    "date": "Sun, 25 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b6209c12f1000aa6abaf",
                    "entity_name": "RetailGiant-524",
                    "id": "66d4b620185cf25309412c3b",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b6209c12f1000aa6abb0"
                },
                "reviews": [
                    {
                        "annual_clients": 100000,
                        "date": "Mon, 20 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b6209c12f1000aa6abaf",
                        "entity_name": "RetailGiant-524",
                        "id": "66d4b620185cf25309412c3a",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b6209c12f1000aa6abb0"
                    },
                    {
                        "annual_clients": 102000,
                        "date": "Sun, 25 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b6209c12f1000aa6abaf",
                        "entity_name": "RetailGiant-524",
                        "id": "66d4b620185cf25309412c3b",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b6209c12f1000aa6abb0"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b6219c12f1000aa6abb1",
            "name": "Foodies-381",
            "risk": {
                "entity": "66d4b6219c12f1000aa6abb1",
                "entity_name": "Foodies-381",
                "id": "66d4b6219c12f1000aa6abb2",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 25000,
                    "date": "Tue, 10 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b6219c12f1000aa6abb1",
                    "entity_name": "Foodies-381",
                    "id": "66d4b621a8e598dffe4d827c",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b6219c12f1000aa6abb2"
                },
                "reviews": [
                    {
                        "annual_clients": 25000,
                        "date": "Tue, 10 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b6219c12f1000aa6abb1",
                        "entity_name": "Foodies-381",
                        "id": "66d4b621a8e598dffe4d827c",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b6219c12f1000aa6abb2"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b62208d6264adba03d59",
            "name": "AutoMakers-58",
            "risk": {
                "entity": "66d4b62208d6264adba03d59",
                "entity_name": "AutoMakers-58",
                "id": "66d4b62208d6264adba03d5a",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 41000,
                    "date": "Mon, 15 May 2023 00:00:00 GMT",
                    "entity": "66d4b62208d6264adba03d59",
                    "entity_name": "AutoMakers-58",
                    "id": "66d4b623185cf25309412c3c",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b62208d6264adba03d5a"
                },
                "reviews": [
                    {
                        "annual_clients": 40000,
                        "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b62208d6264adba03d59",
                        "entity_name": "AutoMakers-58",
                        "id": "66d4b622a8e598dffe4d827d",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b62208d6264adba03d5a"
                    },
                    {
                        "annual_clients": 41000,
                        "date": "Mon, 15 May 2023 00:00:00 GMT",
                        "entity": "66d4b62208d6264adba03d59",
                        "entity_name": "AutoMakers-58",
                        "id": "66d4b623185cf25309412c3c",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b62208d6264adba03d5a"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b624a8e598dffe4d827e",
            "name": "TravelCo-595",
            "risk": {
                "entity": "66d4b624a8e598dffe4d827e",
                "entity_name": "TravelCo-595",
                "id": "66d4b624a8e598dffe4d827f",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 35000,
                    "date": "Fri, 10 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b624a8e598dffe4d827e",
                    "entity_name": "TravelCo-595",
                    "id": "66d4b62408d6264adba03d5b",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b624a8e598dffe4d827f"
                },
                "reviews": [
                    {
                        "annual_clients": 35000,
                        "date": "Fri, 10 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b624a8e598dffe4d827e",
                        "entity_name": "TravelCo-595",
                        "id": "66d4b62408d6264adba03d5b",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b624a8e598dffe4d827f"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b625185cf25309412c3d",
            "name": "BioLife-266",
            "risk": {
                "entity": "66d4b625185cf25309412c3d",
                "entity_name": "BioLife-266",
                "id": "66d4b625185cf25309412c3e",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 18000,
                    "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b625185cf25309412c3d",
                    "entity_name": "BioLife-266",
                    "id": "66d4b625185cf25309412c3f",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b625185cf25309412c3e"
                },
                "reviews": [
                    {
                        "annual_clients": 18000,
                        "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b625185cf25309412c3d",
                        "entity_name": "BioLife-266",
                        "id": "66d4b625185cf25309412c3f",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b625185cf25309412c3e"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b626185cf25309412c40",
            "name": "MediaHouse",
            "risk": {
                "entity": "66d4b626185cf25309412c40",
                "entity_name": "MediaHouse",
                "id": "66d4b626185cf25309412c41",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 60000,
                    "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b626185cf25309412c40",
                    "entity_name": "MediaHouse",
                    "id": "66d4b62608d6264adba03d5c",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b626185cf25309412c41"
                },
                "reviews": [
                    {
                        "annual_clients": 60000,
                        "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b626185cf25309412c40",
                        "entity_name": "MediaHouse",
                        "id": "66d4b62608d6264adba03d5c",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b626185cf25309412c41"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b627185cf25309412c42",
            "name": "GreenTech-332",
            "risk": {
                "entity": "66d4b627185cf25309412c42",
                "entity_name": "GreenTech-332",
                "id": "66d4b627185cf25309412c43",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 22000,
                    "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b627185cf25309412c42",
                    "entity_name": "GreenTech-332",
                    "id": "66d4b627185cf25309412c44",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b627185cf25309412c43"
                },
                "reviews": [
                    {
                        "annual_clients": 22000,
                        "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b627185cf25309412c42",
                        "entity_name": "GreenTech-332",
                        "id": "66d4b627185cf25309412c44",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b627185cf25309412c43"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b62808d6264adba03d5d",
            "name": "LogiTrans-651",
            "risk": {
                "entity": "66d4b62808d6264adba03d5d",
                "entity_name": "LogiTrans-651",
                "id": "66d4b62808d6264adba03d5e",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 51000,
                    "date": "Sat, 15 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b62808d6264adba03d5d",
                    "entity_name": "LogiTrans-651",
                    "id": "66d4b6299c12f1000aa6abb3",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b62808d6264adba03d5e"
                },
                "reviews": [
                    {
                        "annual_clients": 50000,
                        "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b62808d6264adba03d5d",
                        "entity_name": "LogiTrans-651",
                        "id": "66d4b628a8e598dffe4d8280",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b62808d6264adba03d5e"
                    },
                    {
                        "annual_clients": 51000,
                        "date": "Sat, 15 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b62808d6264adba03d5d",
                        "entity_name": "LogiTrans-651",
                        "id": "66d4b6299c12f1000aa6abb3",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b62808d6264adba03d5e"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b62a185cf25309412c45",
            "name": "PharmaCare",
            "risk": {
                "entity": "66d4b62a185cf25309412c45",
                "entity_name": "PharmaCare",
                "id": "66d4b62a185cf25309412c46",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 28000,
                    "date": "Wed, 10 May 2023 00:00:00 GMT",
                    "entity": "66d4b62a185cf25309412c45",
                    "entity_name": "PharmaCare",
                    "id": "66d4b62b9c12f1000aa6abb5",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b62a185cf25309412c46"
                },
                "reviews": [
                    {
                        "annual_clients": 27000,
                        "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b62a185cf25309412c45",
                        "entity_name": "PharmaCare",
                        "id": "66d4b62a9c12f1000aa6abb4",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b62a185cf25309412c46"
                    },
                    {
                        "annual_clients": 28000,
                        "date": "Wed, 10 May 2023 00:00:00 GMT",
                        "entity": "66d4b62a185cf25309412c45",
                        "entity_name": "PharmaCare",
                        "id": "66d4b62b9c12f1000aa6abb5",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b62a185cf25309412c46"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b62b185cf25309412c47",
            "name": "AgriGoods-471",
            "risk": {
                "entity": "66d4b62b185cf25309412c47",
                "entity_name": "AgriGoods-471",
                "id": "66d4b62b185cf25309412c48",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 12000,
                    "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b62b185cf25309412c47",
                    "entity_name": "AgriGoods-471",
                    "id": "66d4b62c185cf25309412c49",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b62b185cf25309412c48"
                },
                "reviews": [
                    {
                        "annual_clients": 12000,
                        "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b62b185cf25309412c47",
                        "entity_name": "AgriGoods-471",
                        "id": "66d4b62c185cf25309412c49",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b62b185cf25309412c48"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b62c08d6264adba03d5f",
            "name": "HomeEssentials-145",
            "risk": {
                "entity": "66d4b62c08d6264adba03d5f",
                "entity_name": "HomeEssentials-145",
                "id": "66d4b62c08d6264adba03d60",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 72000,
                    "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b62c08d6264adba03d5f",
                    "entity_name": "HomeEssentials-145",
                    "id": "66d4b62d185cf25309412c4b",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b62c08d6264adba03d60"
                },
                "reviews": [
                    {
                        "annual_clients": 70000,
                        "date": "Thu, 05 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b62c08d6264adba03d5f",
                        "entity_name": "HomeEssentials-145",
                        "id": "66d4b62d185cf25309412c4a",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b62c08d6264adba03d60"
                    },
                    {
                        "annual_clients": 72000,
                        "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b62c08d6264adba03d5f",
                        "entity_name": "HomeEssentials-145",
                        "id": "66d4b62d185cf25309412c4b",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b62c08d6264adba03d60"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b62ea8e598dffe4d8281",
            "name": "FashionHub",
            "risk": {
                "entity": "66d4b62ea8e598dffe4d8281",
                "entity_name": "FashionHub",
                "id": "66d4b62ea8e598dffe4d8282",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 33000,
                    "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b62ea8e598dffe4d8281",
                    "entity_name": "FashionHub",
                    "id": "66d4b62e185cf25309412c4c",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b62ea8e598dffe4d8282"
                },
                "reviews": [
                    {
                        "annual_clients": 33000,
                        "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b62ea8e598dffe4d8281",
                        "entity_name": "FashionHub",
                        "id": "66d4b62e185cf25309412c4c",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b62ea8e598dffe4d8282"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b62f9c12f1000aa6abb6",
            "name": "TechSolutions-339",
            "risk": {
                "entity": "66d4b62f9c12f1000aa6abb6",
                "entity_name": "TechSolutions-339",
                "id": "66d4b62f9c12f1000aa6abb7",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 42000,
                    "date": "Sat, 10 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b62f9c12f1000aa6abb6",
                    "entity_name": "TechSolutions-339",
                    "id": "66d4b63008d6264adba03d61",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b62f9c12f1000aa6abb7"
                },
                "reviews": [
                    {
                        "annual_clients": 40000,
                        "date": "Thu, 30 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b62f9c12f1000aa6abb6",
                        "entity_name": "TechSolutions-339",
                        "id": "66d4b62f185cf25309412c4d",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b62f9c12f1000aa6abb7"
                    },
                    {
                        "annual_clients": 42000,
                        "date": "Sat, 10 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b62f9c12f1000aa6abb6",
                        "entity_name": "TechSolutions-339",
                        "id": "66d4b63008d6264adba03d61",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b62f9c12f1000aa6abb7"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b631a8e598dffe4d8283",
            "name": "WellnessCenter",
            "risk": {
                "entity": "66d4b631a8e598dffe4d8283",
                "entity_name": "WellnessCenter",
                "id": "66d4b631a8e598dffe4d8284",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 15000,
                    "date": "Thu, 12 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b631a8e598dffe4d8283",
                    "entity_name": "WellnessCenter",
                    "id": "66d4b63108d6264adba03d62",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b631a8e598dffe4d8284"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Thu, 12 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b631a8e598dffe4d8283",
                        "entity_name": "WellnessCenter",
                        "id": "66d4b63108d6264adba03d62",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b631a8e598dffe4d8284"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b6329c12f1000aa6abb8",
            "name": "SmartHome-479",
            "risk": {
                "entity": "66d4b6329c12f1000aa6abb8",
                "entity_name": "SmartHome-479",
                "id": "66d4b6329c12f1000aa6abb9",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 26000,
                    "date": "Fri, 05 May 2023 00:00:00 GMT",
                    "entity": "66d4b6329c12f1000aa6abb8",
                    "entity_name": "SmartHome-479",
                    "id": "66d4b633185cf25309412c4f",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b6329c12f1000aa6abb9"
                },
                "reviews": [
                    {
                        "annual_clients": 25000,
                        "date": "Sat, 18 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b6329c12f1000aa6abb8",
                        "entity_name": "SmartHome-479",
                        "id": "66d4b633185cf25309412c4e",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b6329c12f1000aa6abb9"
                    },
                    {
                        "annual_clients": 26000,
                        "date": "Fri, 05 May 2023 00:00:00 GMT",
                        "entity": "66d4b6329c12f1000aa6abb8",
                        "entity_name": "SmartHome-479",
                        "id": "66d4b633185cf25309412c4f",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b6329c12f1000aa6abb9"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b64da8e598dffe4d8285",
            "name": "TechCorp-187",
            "risk": {
                "entity": "66d4b64da8e598dffe4d8285",
                "entity_name": "TechCorp-187",
                "id": "66d4b64da8e598dffe4d8286",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 52000,
                    "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b64da8e598dffe4d8285",
                    "entity_name": "TechCorp-187",
                    "id": "66d4b64e185cf25309412c50",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b64da8e598dffe4d8286"
                },
                "reviews": [
                    {
                        "annual_clients": 50000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b64da8e598dffe4d8285",
                        "entity_name": "TechCorp-187",
                        "id": "66d4b64da8e598dffe4d8287",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b64da8e598dffe4d8286"
                    },
                    {
                        "annual_clients": 52000,
                        "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b64da8e598dffe4d8285",
                        "entity_name": "TechCorp-187",
                        "id": "66d4b64e185cf25309412c50",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b64da8e598dffe4d8286"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b64f08d6264adba03d63",
            "name": "HealthPlus-493",
            "risk": {
                "entity": "66d4b64f08d6264adba03d63",
                "entity_name": "HealthPlus-493",
                "id": "66d4b64f08d6264adba03d64",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 30000,
                    "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b64f08d6264adba03d63",
                    "entity_name": "HealthPlus-493",
                    "id": "66d4b64f185cf25309412c51",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b64f08d6264adba03d64"
                },
                "reviews": [
                    {
                        "annual_clients": 30000,
                        "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b64f08d6264adba03d63",
                        "entity_name": "HealthPlus-493",
                        "id": "66d4b64f185cf25309412c51",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b64f08d6264adba03d64"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b650a8e598dffe4d8288",
            "name": "EcoEnergy-710",
            "risk": {
                "entity": "66d4b650a8e598dffe4d8288",
                "entity_name": "EcoEnergy-710",
                "id": "66d4b650a8e598dffe4d8289",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 16000,
                    "date": "Thu, 15 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b650a8e598dffe4d8288",
                    "entity_name": "EcoEnergy-710",
                    "id": "66d4b6519c12f1000aa6abba",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b650a8e598dffe4d8289"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b650a8e598dffe4d8288",
                        "entity_name": "EcoEnergy-710",
                        "id": "66d4b650185cf25309412c52",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b650a8e598dffe4d8289"
                    },
                    {
                        "annual_clients": 16000,
                        "date": "Thu, 15 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b650a8e598dffe4d8288",
                        "entity_name": "EcoEnergy-710",
                        "id": "66d4b6519c12f1000aa6abba",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b650a8e598dffe4d8289"
                    }
                ],
                "score": 10
            }
        },
        {
            "id": "66d4b652185cf25309412c53",
            "name": "FinServe-444",
            "risk": {
                "entity": "66d4b652185cf25309412c53",
                "entity_name": "FinServe-444",
                "id": "66d4b652185cf25309412c54",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 45000,
                    "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b652185cf25309412c53",
                    "entity_name": "FinServe-444",
                    "id": "66d4b65208d6264adba03d65",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b652185cf25309412c54"
                },
                "reviews": [
                    {
                        "annual_clients": 45000,
                        "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b652185cf25309412c53",
                        "entity_name": "FinServe-444",
                        "id": "66d4b65208d6264adba03d65",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b652185cf25309412c54"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b654a8e598dffe4d828a",
            "name": "GreenGrocers-857",
            "risk": {
                "entity": "66d4b654a8e598dffe4d828a",
                "entity_name": "GreenGrocers-857",
                "id": "66d4b654a8e598dffe4d828b",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 21000,
                    "date": "Mon, 22 May 2023 00:00:00 GMT",
                    "entity": "66d4b654a8e598dffe4d828a",
                    "entity_name": "GreenGrocers-857",
                    "id": "66d4b655a8e598dffe4d828d",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b654a8e598dffe4d828b"
                },
                "reviews": [
                    {
                        "annual_clients": 20000,
                        "date": "Sat, 18 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b654a8e598dffe4d828a",
                        "entity_name": "GreenGrocers-857",
                        "id": "66d4b655a8e598dffe4d828c",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b654a8e598dffe4d828b"
                    },
                    {
                        "annual_clients": 21000,
                        "date": "Mon, 22 May 2023 00:00:00 GMT",
                        "entity": "66d4b654a8e598dffe4d828a",
                        "entity_name": "GreenGrocers-857",
                        "id": "66d4b655a8e598dffe4d828d",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b654a8e598dffe4d828b"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b65608d6264adba03d66",
            "name": "AutoMotive-631",
            "risk": {
                "entity": "66d4b65608d6264adba03d66",
                "entity_name": "AutoMotive-631",
                "id": "66d4b65608d6264adba03d67",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 35000,
                    "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b65608d6264adba03d66",
                    "entity_name": "AutoMotive-631",
                    "id": "66d4b657a8e598dffe4d828e",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b65608d6264adba03d67"
                },
                "reviews": [
                    {
                        "annual_clients": 35000,
                        "date": "Sun, 12 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b65608d6264adba03d66",
                        "entity_name": "AutoMotive-631",
                        "id": "66d4b657a8e598dffe4d828e",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b65608d6264adba03d67"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b657185cf25309412c55",
            "name": "EduTech-493",
            "risk": {
                "entity": "66d4b657185cf25309412c55",
                "entity_name": "EduTech-493",
                "id": "66d4b657185cf25309412c56",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 26000,
                    "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b657185cf25309412c55",
                    "entity_name": "EduTech-493",
                    "id": "66d4b658185cf25309412c57",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b657185cf25309412c56"
                },
                "reviews": [
                    {
                        "annual_clients": 25000,
                        "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b657185cf25309412c55",
                        "entity_name": "EduTech-493",
                        "id": "66d4b65808d6264adba03d68",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b657185cf25309412c56"
                    },
                    {
                        "annual_clients": 26000,
                        "date": "Mon, 10 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b657185cf25309412c55",
                        "entity_name": "EduTech-493",
                        "id": "66d4b658185cf25309412c57",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b657185cf25309412c56"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b659185cf25309412c58",
            "name": "Foodies-989",
            "risk": {
                "entity": "66d4b659185cf25309412c58",
                "entity_name": "Foodies-989",
                "id": "66d4b659185cf25309412c59",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 18000,
                    "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b659185cf25309412c58",
                    "entity_name": "Foodies-989",
                    "id": "66d4b65908d6264adba03d69",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b659185cf25309412c59"
                },
                "reviews": [
                    {
                        "annual_clients": 18000,
                        "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b659185cf25309412c58",
                        "entity_name": "Foodies-989",
                        "id": "66d4b65908d6264adba03d69",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b659185cf25309412c59"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b65a9c12f1000aa6abbb",
            "name": "TravelEase-181",
            "risk": {
                "entity": "66d4b65a9c12f1000aa6abbb",
                "entity_name": "TravelEase-181",
                "id": "66d4b65a9c12f1000aa6abbc",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 42000,
                    "date": "Sun, 25 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b65a9c12f1000aa6abbb",
                    "entity_name": "TravelEase-181",
                    "id": "66d4b65ba8e598dffe4d8290",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b65a9c12f1000aa6abbc"
                },
                "reviews": [
                    {
                        "annual_clients": 40000,
                        "date": "Mon, 20 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b65a9c12f1000aa6abbb",
                        "entity_name": "TravelEase-181",
                        "id": "66d4b65aa8e598dffe4d828f",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b65a9c12f1000aa6abbc"
                    },
                    {
                        "annual_clients": 42000,
                        "date": "Sun, 25 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b65a9c12f1000aa6abbb",
                        "entity_name": "TravelEase-181",
                        "id": "66d4b65ba8e598dffe4d8290",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b65a9c12f1000aa6abbc"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b65c08d6264adba03d6a",
            "name": "MediCare-136",
            "risk": {
                "entity": "66d4b65c08d6264adba03d6a",
                "entity_name": "MediCare-136",
                "id": "66d4b65c08d6264adba03d6b",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 32000,
                    "date": "Tue, 10 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b65c08d6264adba03d6a",
                    "entity_name": "MediCare-136",
                    "id": "66d4b65c08d6264adba03d6c",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b65c08d6264adba03d6b"
                },
                "reviews": [
                    {
                        "annual_clients": 32000,
                        "date": "Tue, 10 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b65c08d6264adba03d6a",
                        "entity_name": "MediCare-136",
                        "id": "66d4b65c08d6264adba03d6c",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b65c08d6264adba03d6b"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b65da8e598dffe4d8291",
            "name": "RetailHub-291",
            "risk": {
                "entity": "66d4b65da8e598dffe4d8291",
                "entity_name": "RetailHub-291",
                "id": "66d4b65da8e598dffe4d8292",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 28000,
                    "date": "Wed, 10 May 2023 00:00:00 GMT",
                    "entity": "66d4b65da8e598dffe4d8291",
                    "entity_name": "RetailHub-291",
                    "id": "66d4b65d08d6264adba03d6d",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b65da8e598dffe4d8292"
                },
                "reviews": [
                    {
                        "annual_clients": 27000,
                        "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b65da8e598dffe4d8291",
                        "entity_name": "RetailHub-291",
                        "id": "66d4b65d185cf25309412c5a",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b65da8e598dffe4d8292"
                    },
                    {
                        "annual_clients": 28000,
                        "date": "Wed, 10 May 2023 00:00:00 GMT",
                        "entity": "66d4b65da8e598dffe4d8291",
                        "entity_name": "RetailHub-291",
                        "id": "66d4b65d08d6264adba03d6d",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b65da8e598dffe4d8292"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b65ea8e598dffe4d8293",
            "name": "LogiTrans-899",
            "risk": {
                "entity": "66d4b65ea8e598dffe4d8293",
                "entity_name": "LogiTrans-899",
                "id": "66d4b65ea8e598dffe4d8294",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 22000,
                    "date": "Wed, 01 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b65ea8e598dffe4d8293",
                    "entity_name": "LogiTrans-899",
                    "id": "66d4b65f9c12f1000aa6abbd",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b65ea8e598dffe4d8294"
                },
                "reviews": [
                    {
                        "annual_clients": 22000,
                        "date": "Wed, 01 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b65ea8e598dffe4d8293",
                        "entity_name": "LogiTrans-899",
                        "id": "66d4b65f9c12f1000aa6abbd",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b65ea8e598dffe4d8294"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b65f185cf25309412c5b",
            "name": "HomeComfort-4",
            "risk": {
                "entity": "66d4b65f185cf25309412c5b",
                "entity_name": "HomeComfort-4",
                "id": "66d4b65f185cf25309412c5c",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 19000,
                    "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b65f185cf25309412c5b",
                    "entity_name": "HomeComfort-4",
                    "id": "66d4b6609c12f1000aa6abbe",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b65f185cf25309412c5c"
                },
                "reviews": [
                    {
                        "annual_clients": 19000,
                        "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b65f185cf25309412c5b",
                        "entity_name": "HomeComfort-4",
                        "id": "66d4b6609c12f1000aa6abbe",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b65f185cf25309412c5c"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b660185cf25309412c5d",
            "name": "FashionFiesta",
            "risk": {
                "entity": "66d4b660185cf25309412c5d",
                "entity_name": "FashionFiesta",
                "id": "66d4b660185cf25309412c5e",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 24000,
                    "date": "Mon, 15 May 2023 00:00:00 GMT",
                    "entity": "66d4b660185cf25309412c5d",
                    "entity_name": "FashionFiesta",
                    "id": "66d4b661185cf25309412c60",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b660185cf25309412c5e"
                },
                "reviews": [
                    {
                        "annual_clients": 23000,
                        "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b660185cf25309412c5d",
                        "entity_name": "FashionFiesta",
                        "id": "66d4b661185cf25309412c5f",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b660185cf25309412c5e"
                    },
                    {
                        "annual_clients": 24000,
                        "date": "Mon, 15 May 2023 00:00:00 GMT",
                        "entity": "66d4b660185cf25309412c5d",
                        "entity_name": "FashionFiesta",
                        "id": "66d4b661185cf25309412c60",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b660185cf25309412c5e"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b66208d6264adba03d6e",
            "name": "TechSolutions-301",
            "risk": {
                "entity": "66d4b66208d6264adba03d6e",
                "entity_name": "TechSolutions-301",
                "id": "66d4b66208d6264adba03d6f",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 37000,
                    "date": "Sat, 18 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b66208d6264adba03d6e",
                    "entity_name": "TechSolutions-301",
                    "id": "66d4b6629c12f1000aa6abbf",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b66208d6264adba03d6f"
                },
                "reviews": [
                    {
                        "annual_clients": 37000,
                        "date": "Sat, 18 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b66208d6264adba03d6e",
                        "entity_name": "TechSolutions-301",
                        "id": "66d4b6629c12f1000aa6abbf",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b66208d6264adba03d6f"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b6639c12f1000aa6abc0",
            "name": "WellnessWorks-446",
            "risk": {
                "entity": "66d4b6639c12f1000aa6abc0",
                "entity_name": "WellnessWorks-446",
                "id": "66d4b6639c12f1000aa6abc1",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 21000,
                    "date": "Thu, 05 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b6639c12f1000aa6abc0",
                    "entity_name": "WellnessWorks-446",
                    "id": "66d4b66308d6264adba03d70",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b6639c12f1000aa6abc1"
                },
                "reviews": [
                    {
                        "annual_clients": 21000,
                        "date": "Thu, 05 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b6639c12f1000aa6abc0",
                        "entity_name": "WellnessWorks-446",
                        "id": "66d4b66308d6264adba03d70",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b6639c12f1000aa6abc1"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b66408d6264adba03d71",
            "name": "BuildIt-870",
            "risk": {
                "entity": "66d4b66408d6264adba03d71",
                "entity_name": "BuildIt-870",
                "id": "66d4b66408d6264adba03d72",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 29000,
                    "date": "Sun, 12 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b66408d6264adba03d71",
                    "entity_name": "BuildIt-870",
                    "id": "66d4b664a8e598dffe4d8295",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b66408d6264adba03d72"
                },
                "reviews": [
                    {
                        "annual_clients": 29000,
                        "date": "Sun, 12 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b66408d6264adba03d71",
                        "entity_name": "BuildIt-870",
                        "id": "66d4b664a8e598dffe4d8295",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b66408d6264adba03d72"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b665185cf25309412c61",
            "name": "SmartHome-591",
            "risk": {
                "entity": "66d4b665185cf25309412c61",
                "entity_name": "SmartHome-591",
                "id": "66d4b665185cf25309412c62",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 34000,
                    "date": "Wed, 22 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b665185cf25309412c61",
                    "entity_name": "SmartHome-591",
                    "id": "66d4b665185cf25309412c63",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b665185cf25309412c62"
                },
                "reviews": [
                    {
                        "annual_clients": 34000,
                        "date": "Wed, 22 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b665185cf25309412c61",
                        "entity_name": "SmartHome-591",
                        "id": "66d4b665185cf25309412c63",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b665185cf25309412c62"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b666a8e598dffe4d8296",
            "name": "EduLearn-403",
            "risk": {
                "entity": "66d4b666a8e598dffe4d8296",
                "entity_name": "EduLearn-403",
                "id": "66d4b666a8e598dffe4d8297",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 27000,
                    "date": "Tue, 18 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b666a8e598dffe4d8296",
                    "entity_name": "EduLearn-403",
                    "id": "66d4b667185cf25309412c64",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b666a8e598dffe4d8297"
                },
                "reviews": [
                    {
                        "annual_clients": 26000,
                        "date": "Sat, 28 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b666a8e598dffe4d8296",
                        "entity_name": "EduLearn-403",
                        "id": "66d4b66608d6264adba03d73",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b666a8e598dffe4d8297"
                    },
                    {
                        "annual_clients": 27000,
                        "date": "Tue, 18 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b666a8e598dffe4d8296",
                        "entity_name": "EduLearn-403",
                        "id": "66d4b667185cf25309412c64",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b666a8e598dffe4d8297"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b682185cf25309412c65",
            "name": "Tech Innovators Inc.-256",
            "risk": {
                "entity": "66d4b682185cf25309412c65",
                "entity_name": "Tech Innovators Inc.-256",
                "id": "66d4b682185cf25309412c66",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 52000,
                    "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b682185cf25309412c65",
                    "entity_name": "Tech Innovators Inc.-256",
                    "id": "66d4b683185cf25309412c68",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b682185cf25309412c66"
                },
                "reviews": [
                    {
                        "annual_clients": 50000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b682185cf25309412c65",
                        "entity_name": "Tech Innovators Inc.-256",
                        "id": "66d4b682185cf25309412c67",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b682185cf25309412c66"
                    },
                    {
                        "annual_clients": 52000,
                        "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b682185cf25309412c65",
                        "entity_name": "Tech Innovators Inc.-256",
                        "id": "66d4b683185cf25309412c68",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b682185cf25309412c66"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b683185cf25309412c69",
            "name": "Green Energy Solutions-833",
            "risk": {
                "entity": "66d4b683185cf25309412c69",
                "entity_name": "Green Energy Solutions-833",
                "id": "66d4b683185cf25309412c6a",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 31000,
                    "date": "Thu, 18 May 2023 00:00:00 GMT",
                    "entity": "66d4b683185cf25309412c69",
                    "entity_name": "Green Energy Solutions-833",
                    "id": "66d4b684185cf25309412c6b",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b683185cf25309412c6a"
                },
                "reviews": [
                    {
                        "annual_clients": 30000,
                        "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b683185cf25309412c69",
                        "entity_name": "Green Energy Solutions-833",
                        "id": "66d4b68408d6264adba03d74",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b683185cf25309412c6a"
                    },
                    {
                        "annual_clients": 31000,
                        "date": "Thu, 18 May 2023 00:00:00 GMT",
                        "entity": "66d4b683185cf25309412c69",
                        "entity_name": "Green Energy Solutions-833",
                        "id": "66d4b684185cf25309412c6b",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b683185cf25309412c6a"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b685a8e598dffe4d8298",
            "name": "Urban Outfitters",
            "risk": {
                "entity": "66d4b685a8e598dffe4d8298",
                "entity_name": "Urban Outfitters",
                "id": "66d4b685a8e598dffe4d8299",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 45000,
                    "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b685a8e598dffe4d8298",
                    "entity_name": "Urban Outfitters",
                    "id": "66d4b68508d6264adba03d75",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b685a8e598dffe4d8299"
                },
                "reviews": [
                    {
                        "annual_clients": 45000,
                        "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b685a8e598dffe4d8298",
                        "entity_name": "Urban Outfitters",
                        "id": "66d4b68508d6264adba03d75",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b685a8e598dffe4d8299"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b686a8e598dffe4d829a",
            "name": "HealthFirst Medical-174",
            "risk": {
                "entity": "66d4b686a8e598dffe4d829a",
                "entity_name": "HealthFirst Medical-174",
                "id": "66d4b686a8e598dffe4d829b",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 21000,
                    "date": "Sun, 30 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b686a8e598dffe4d829a",
                    "entity_name": "HealthFirst Medical-174",
                    "id": "66d4b687185cf25309412c6c",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b686a8e598dffe4d829b"
                },
                "reviews": [
                    {
                        "annual_clients": 20000,
                        "date": "Wed, 25 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b686a8e598dffe4d829a",
                        "entity_name": "HealthFirst Medical-174",
                        "id": "66d4b686a8e598dffe4d829c",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b686a8e598dffe4d829b"
                    },
                    {
                        "annual_clients": 21000,
                        "date": "Sun, 30 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b686a8e598dffe4d829a",
                        "entity_name": "HealthFirst Medical-174",
                        "id": "66d4b687185cf25309412c6c",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b686a8e598dffe4d829b"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b688185cf25309412c6d",
            "name": "EcoFriendly Products-685",
            "risk": {
                "entity": "66d4b688185cf25309412c6d",
                "entity_name": "EcoFriendly Products-685",
                "id": "66d4b688185cf25309412c6e",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 15000,
                    "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b688185cf25309412c6d",
                    "entity_name": "EcoFriendly Products-685",
                    "id": "66d4b68808d6264adba03d76",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b688185cf25309412c6e"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Wed, 15 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b688185cf25309412c6d",
                        "entity_name": "EcoFriendly Products-685",
                        "id": "66d4b68808d6264adba03d76",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b688185cf25309412c6e"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b689a8e598dffe4d829d",
            "name": "NextGen Tech",
            "risk": {
                "entity": "66d4b689a8e598dffe4d829d",
                "entity_name": "NextGen Tech",
                "id": "66d4b689a8e598dffe4d829e",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 62000,
                    "date": "Sun, 25 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b689a8e598dffe4d829d",
                    "entity_name": "NextGen Tech",
                    "id": "66d4b68a185cf25309412c70",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b689a8e598dffe4d829e"
                },
                "reviews": [
                    {
                        "annual_clients": 60000,
                        "date": "Mon, 20 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b689a8e598dffe4d829d",
                        "entity_name": "NextGen Tech",
                        "id": "66d4b689185cf25309412c6f",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b689a8e598dffe4d829e"
                    },
                    {
                        "annual_clients": 62000,
                        "date": "Sun, 25 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b689a8e598dffe4d829d",
                        "entity_name": "NextGen Tech",
                        "id": "66d4b68a185cf25309412c70",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b689a8e598dffe4d829e"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b68ba8e598dffe4d829f",
            "name": "Smart Home Solutions-806",
            "risk": {
                "entity": "66d4b68ba8e598dffe4d829f",
                "entity_name": "Smart Home Solutions-806",
                "id": "66d4b68ba8e598dffe4d82a0",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 35000,
                    "date": "Tue, 10 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b68ba8e598dffe4d829f",
                    "entity_name": "Smart Home Solutions-806",
                    "id": "66d4b68b08d6264adba03d77",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b68ba8e598dffe4d82a0"
                },
                "reviews": [
                    {
                        "annual_clients": 35000,
                        "date": "Tue, 10 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b68ba8e598dffe4d829f",
                        "entity_name": "Smart Home Solutions-806",
                        "id": "66d4b68b08d6264adba03d77",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b68ba8e598dffe4d82a0"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b68b9c12f1000aa6abc2",
            "name": "Global Logistics",
            "risk": {
                "entity": "66d4b68b9c12f1000aa6abc2",
                "entity_name": "Global Logistics",
                "id": "66d4b68b9c12f1000aa6abc3",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 72000,
                    "date": "Mon, 15 May 2023 00:00:00 GMT",
                    "entity": "66d4b68b9c12f1000aa6abc2",
                    "entity_name": "Global Logistics",
                    "id": "66d4b68c185cf25309412c72",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b68b9c12f1000aa6abc3"
                },
                "reviews": [
                    {
                        "annual_clients": 70000,
                        "date": "Tue, 28 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b68b9c12f1000aa6abc2",
                        "entity_name": "Global Logistics",
                        "id": "66d4b68b185cf25309412c71",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b68b9c12f1000aa6abc3"
                    },
                    {
                        "annual_clients": 72000,
                        "date": "Mon, 15 May 2023 00:00:00 GMT",
                        "entity": "66d4b68b9c12f1000aa6abc2",
                        "entity_name": "Global Logistics",
                        "id": "66d4b68c185cf25309412c72",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b68b9c12f1000aa6abc3"
                    }
                ],
                "score": 9
            }
        },
        {
            "id": "66d4b68d185cf25309412c73",
            "name": "FinTech Innovations-711",
            "risk": {
                "entity": "66d4b68d185cf25309412c73",
                "entity_name": "FinTech Innovations-711",
                "id": "66d4b68d185cf25309412c74",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 40000,
                    "date": "Fri, 10 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b68d185cf25309412c73",
                    "entity_name": "FinTech Innovations-711",
                    "id": "66d4b68d185cf25309412c75",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b68d185cf25309412c74"
                },
                "reviews": [
                    {
                        "annual_clients": 40000,
                        "date": "Fri, 10 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b68d185cf25309412c73",
                        "entity_name": "FinTech Innovations-711",
                        "id": "66d4b68d185cf25309412c75",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b68d185cf25309412c74"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b68e185cf25309412c76",
            "name": "Healthy Living Foods",
            "risk": {
                "entity": "66d4b68e185cf25309412c76",
                "entity_name": "Healthy Living Foods",
                "id": "66d4b68e185cf25309412c77",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 26000,
                    "date": "Sat, 22 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b68e185cf25309412c76",
                    "entity_name": "Healthy Living Foods",
                    "id": "66d4b68e9c12f1000aa6abc4",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b68e185cf25309412c77"
                },
                "reviews": [
                    {
                        "annual_clients": 25000,
                        "date": "Fri, 20 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b68e185cf25309412c76",
                        "entity_name": "Healthy Living Foods",
                        "id": "66d4b68e08d6264adba03d78",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b68e185cf25309412c77"
                    },
                    {
                        "annual_clients": 26000,
                        "date": "Sat, 22 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b68e185cf25309412c76",
                        "entity_name": "Healthy Living Foods",
                        "id": "66d4b68e9c12f1000aa6abc4",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b68e185cf25309412c77"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b68f9c12f1000aa6abc5",
            "name": "TechWave",
            "risk": {
                "entity": "66d4b68f9c12f1000aa6abc5",
                "entity_name": "TechWave",
                "id": "66d4b68f9c12f1000aa6abc6",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 55000,
                    "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b68f9c12f1000aa6abc5",
                    "entity_name": "TechWave",
                    "id": "66d4b68f185cf25309412c78",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b68f9c12f1000aa6abc6"
                },
                "reviews": [
                    {
                        "annual_clients": 55000,
                        "date": "Sun, 05 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b68f9c12f1000aa6abc5",
                        "entity_name": "TechWave",
                        "id": "66d4b68f185cf25309412c78",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b68f9c12f1000aa6abc6"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b690a8e598dffe4d82a1",
            "name": "Urban Mobility",
            "risk": {
                "entity": "66d4b690a8e598dffe4d82a1",
                "entity_name": "Urban Mobility",
                "id": "66d4b690a8e598dffe4d82a2",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 31000,
                    "date": "Sat, 10 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b690a8e598dffe4d82a1",
                    "entity_name": "Urban Mobility",
                    "id": "66d4b693185cf25309412c79",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b690a8e598dffe4d82a2"
                },
                "reviews": [
                    {
                        "annual_clients": 30000,
                        "date": "Wed, 15 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b690a8e598dffe4d82a1",
                        "entity_name": "Urban Mobility",
                        "id": "66d4b690a8e598dffe4d82a3",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b690a8e598dffe4d82a2"
                    },
                    {
                        "annual_clients": 31000,
                        "date": "Sat, 10 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b690a8e598dffe4d82a1",
                        "entity_name": "Urban Mobility",
                        "id": "66d4b693185cf25309412c79",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b690a8e598dffe4d82a2"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b694185cf25309412c7a",
            "name": "GreenTech Innovations-360",
            "risk": {
                "entity": "66d4b694185cf25309412c7a",
                "entity_name": "GreenTech Innovations-360",
                "id": "66d4b694185cf25309412c7b",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 45000,
                    "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b694185cf25309412c7a",
                    "entity_name": "GreenTech Innovations-360",
                    "id": "66d4b694185cf25309412c7c",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b694185cf25309412c7b"
                },
                "reviews": [
                    {
                        "annual_clients": 45000,
                        "date": "Mon, 30 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b694185cf25309412c7a",
                        "entity_name": "GreenTech Innovations-360",
                        "id": "66d4b694185cf25309412c7c",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b694185cf25309412c7b"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b6959c12f1000aa6abc7",
            "name": "Smart Health Solutions",
            "risk": {
                "entity": "66d4b6959c12f1000aa6abc7",
                "entity_name": "Smart Health Solutions",
                "id": "66d4b6959c12f1000aa6abc8",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 21000,
                    "date": "Thu, 25 May 2023 00:00:00 GMT",
                    "entity": "66d4b6959c12f1000aa6abc7",
                    "entity_name": "Smart Health Solutions",
                    "id": "66d4b695185cf25309412c7d",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b6959c12f1000aa6abc8"
                },
                "reviews": [
                    {
                        "annual_clients": 20000,
                        "date": "Mon, 20 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b6959c12f1000aa6abc7",
                        "entity_name": "Smart Health Solutions",
                        "id": "66d4b695a8e598dffe4d82a4",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b6959c12f1000aa6abc8"
                    },
                    {
                        "annual_clients": 21000,
                        "date": "Thu, 25 May 2023 00:00:00 GMT",
                        "entity": "66d4b6959c12f1000aa6abc7",
                        "entity_name": "Smart Health Solutions",
                        "id": "66d4b695185cf25309412c7d",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b6959c12f1000aa6abc8"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b696a8e598dffe4d82a5",
            "name": "EcoTech Solutions",
            "risk": {
                "entity": "66d4b696a8e598dffe4d82a5",
                "entity_name": "EcoTech Solutions",
                "id": "66d4b696a8e598dffe4d82a6",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 15000,
                    "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b696a8e598dffe4d82a5",
                    "entity_name": "EcoTech Solutions",
                    "id": "66d4b69608d6264adba03d79",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b696a8e598dffe4d82a6"
                },
                "reviews": [
                    {
                        "annual_clients": 15000,
                        "date": "Sat, 25 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b696a8e598dffe4d82a5",
                        "entity_name": "EcoTech Solutions",
                        "id": "66d4b69608d6264adba03d79",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b696a8e598dffe4d82a6"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b6979c12f1000aa6abc9",
            "name": "NextGen Health",
            "risk": {
                "entity": "66d4b6979c12f1000aa6abc9",
                "entity_name": "NextGen Health",
                "id": "66d4b6979c12f1000aa6abca",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 62000,
                    "date": "Sat, 15 Apr 2023 00:00:00 GMT",
                    "entity": "66d4b6979c12f1000aa6abc9",
                    "entity_name": "NextGen Health",
                    "id": "66d4b698185cf25309412c7e",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b6979c12f1000aa6abca"
                },
                "reviews": [
                    {
                        "annual_clients": 60000,
                        "date": "Thu, 05 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b6979c12f1000aa6abc9",
                        "entity_name": "NextGen Health",
                        "id": "66d4b6989c12f1000aa6abcb",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b6979c12f1000aa6abca"
                    },
                    {
                        "annual_clients": 62000,
                        "date": "Sat, 15 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b6979c12f1000aa6abc9",
                        "entity_name": "NextGen Health",
                        "id": "66d4b698185cf25309412c7e",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b6979c12f1000aa6abca"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b6999c12f1000aa6abcc",
            "name": "Urban Green",
            "risk": {
                "entity": "66d4b6999c12f1000aa6abcc",
                "entity_name": "Urban Green",
                "id": "66d4b6999c12f1000aa6abcd",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 35000,
                    "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                    "entity": "66d4b6999c12f1000aa6abcc",
                    "entity_name": "Urban Green",
                    "id": "66d4b6999c12f1000aa6abce",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b6999c12f1000aa6abcd"
                },
                "reviews": [
                    {
                        "annual_clients": 35000,
                        "date": "Sat, 25 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b6999c12f1000aa6abcc",
                        "entity_name": "Urban Green",
                        "id": "66d4b6999c12f1000aa6abce",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b6999c12f1000aa6abcd"
                    }
                ],
                "score": 18
            }
        },
        {
            "id": "66d4b69a9c12f1000aa6abcf",
            "name": "TechSavvy",
            "risk": {
                "entity": "66d4b69a9c12f1000aa6abcf",
                "entity_name": "TechSavvy",
                "id": "66d4b69a9c12f1000aa6abd0",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 72000,
                    "date": "Tue, 20 Jun 2023 00:00:00 GMT",
                    "entity": "66d4b69a9c12f1000aa6abcf",
                    "entity_name": "TechSavvy",
                    "id": "66d4b69ba8e598dffe4d82a8",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b69a9c12f1000aa6abd0"
                },
                "reviews": [
                    {
                        "annual_clients": 70000,
                        "date": "Thu, 30 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b69a9c12f1000aa6abcf",
                        "entity_name": "TechSavvy",
                        "id": "66d4b69aa8e598dffe4d82a7",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b69a9c12f1000aa6abd0"
                    },
                    {
                        "annual_clients": 72000,
                        "date": "Tue, 20 Jun 2023 00:00:00 GMT",
                        "entity": "66d4b69a9c12f1000aa6abcf",
                        "entity_name": "TechSavvy",
                        "id": "66d4b69ba8e598dffe4d82a8",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b69a9c12f1000aa6abd0"
                    }
                ],
                "score": 12
            }
        },
        {
            "id": "66d4b69b08d6264adba03d7a",
            "name": "Global Health",
            "risk": {
                "entity": "66d4b69b08d6264adba03d7a",
                "entity_name": "Global Health",
                "id": "66d4b69b08d6264adba03d7b",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 40000,
                    "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                    "entity": "66d4b69b08d6264adba03d7a",
                    "entity_name": "Global Health",
                    "id": "66d4b69c185cf25309412c7f",
                    "infraction_severity": "minor",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "minor",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b69b08d6264adba03d7b"
                },
                "reviews": [
                    {
                        "annual_clients": 40000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b69b08d6264adba03d7a",
                        "entity_name": "Global Health",
                        "id": "66d4b69c185cf25309412c7f",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b69b08d6264adba03d7b"
                    }
                ],
                "score": 10
            }
        },
        {
            "id": "66d4b69ca8e598dffe4d82a9",
            "name": "EcoWave",
            "risk": {
                "entity": "66d4b69ca8e598dffe4d82a9",
                "entity_name": "EcoWave",
                "id": "66d4b69ca8e598dffe4d82aa",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 26000,
                    "date": "Thu, 18 May 2023 00:00:00 GMT",
                    "entity": "66d4b69ca8e598dffe4d82a9",
                    "entity_name": "EcoWave",
                    "id": "66d4b69d185cf25309412c81",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b69ca8e598dffe4d82aa"
                },
                "reviews": [
                    {
                        "annual_clients": 25000,
                        "date": "Fri, 10 Feb 2023 00:00:00 GMT",
                        "entity": "66d4b69ca8e598dffe4d82a9",
                        "entity_name": "EcoWave",
                        "id": "66d4b69c185cf25309412c80",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b69ca8e598dffe4d82aa"
                    },
                    {
                        "annual_clients": 26000,
                        "date": "Thu, 18 May 2023 00:00:00 GMT",
                        "entity": "66d4b69ca8e598dffe4d82a9",
                        "entity_name": "EcoWave",
                        "id": "66d4b69d185cf25309412c81",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b69ca8e598dffe4d82aa"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b69ea8e598dffe4d82ab",
            "name": "SmartTech-756",
            "risk": {
                "entity": "66d4b69ea8e598dffe4d82ab",
                "entity_name": "SmartTech-756",
                "id": "66d4b69ea8e598dffe4d82ac",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 50000,
                    "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                    "entity": "66d4b69ea8e598dffe4d82ab",
                    "entity_name": "SmartTech-756",
                    "id": "66d4b69e08d6264adba03d7c",
                    "infraction_severity": "none",
                    "inspection_result": "pass",
                    "quarter_complaint_severity": "none",
                    "quarter_sentiment_analysis": "none",
                    "risk": "66d4b69ea8e598dffe4d82ac"
                },
                "reviews": [
                    {
                        "annual_clients": 50000,
                        "date": "Sun, 05 Mar 2023 00:00:00 GMT",
                        "entity": "66d4b69ea8e598dffe4d82ab",
                        "entity_name": "SmartTech-756",
                        "id": "66d4b69e08d6264adba03d7c",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b69ea8e598dffe4d82ac"
                    }
                ],
                "score": 6
            }
        },
        {
            "id": "66d4b8199c12f1000aa6abd1",
            "name": "Tech Innovators Inc.-132",
            "risk": {
                "entity": "66d4b8199c12f1000aa6abd1",
                "entity_name": "Tech Innovators Inc.-132",
                "id": "66d4b8199c12f1000aa6abd2",
                "infraction_history": "1to3yr",
                "recent_review": {
                    "annual_clients": 54000,
                    "date": "Mon, 10 Jul 2023 00:00:00 GMT",
                    "entity": "66d4b8199c12f1000aa6abd1",
                    "entity_name": "Tech Innovators Inc.-132",
                    "id": "66d4b81a185cf25309412c83",
                    "infraction_severity": "major",
                    "inspection_result": "fail",
                    "quarter_complaint_severity": "major",
                    "quarter_sentiment_analysis": "flagged",
                    "risk": "66d4b8199c12f1000aa6abd2"
                },
                "reviews": [
                    {
                        "annual_clients": 50000,
                        "date": "Sun, 15 Jan 2023 00:00:00 GMT",
                        "entity": "66d4b8199c12f1000aa6abd1",
                        "entity_name": "Tech Innovators Inc.-132",
                        "id": "66d4b81aa8e598dffe4d82ad",
                        "infraction_severity": "minor",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "minor",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b8199c12f1000aa6abd2"
                    },
                    {
                        "annual_clients": 52000,
                        "date": "Thu, 20 Apr 2023 00:00:00 GMT",
                        "entity": "66d4b8199c12f1000aa6abd1",
                        "entity_name": "Tech Innovators Inc.-132",
                        "id": "66d4b81a08d6264adba03d7d",
                        "infraction_severity": "none",
                        "inspection_result": "pass",
                        "quarter_complaint_severity": "none",
                        "quarter_sentiment_analysis": "none",
                        "risk": "66d4b8199c12f1000aa6abd2"
                    },
                    {
                        "annual_clients": 54000,
                        "date": "Mon, 10 Jul 2023 00:00:00 GMT",
                        "entity": "66d4b8199c12f1000aa6abd1",
                        "entity_name": "Tech Innovators Inc.-132",
                        "id": "66d4b81a185cf25309412c83",
                        "infraction_severity": "major",
                        "inspection_result": "fail",
                        "quarter_complaint_severity": "major",
                        "quarter_sentiment_analysis": "flagged",
                        "risk": "66d4b8199c12f1000aa6abd2"
                    }
                ],
                "score": 18
            }
        }
    ],
			size: 200
    }
	},
  
  getProductsMini() {
    return Promise.resolve(this.getProductsData().slice(0, 5));
  },

  getProductsSmall() {
    return Promise.resolve(this.getProductsData().slice(0, 10));
  },

  getProducts() {
    return Promise.resolve(this.getProductsData());
  },
}